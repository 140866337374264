@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?5agm3p');
  src:  url('fonts/icomoon.eot?5agm3p#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?5agm3p') format('woff2'),
    url('fonts/icomoon.ttf?5agm3p') format('truetype'),
    url('fonts/icomoon.woff?5agm3p') format('woff'),
    url('fonts/icomoon.svg?5agm3p#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accessible-icon:before {
  content: "\e900";
}
.icon-amazon:before {
  content: "\e901";
}
.icon-app-store-ios:before {
  content: "\e902";
}
.icon-apple-pay:before {
  content: "\e903";
}
.icon-apple:before {
  content: "\e904";
}
.icon-cc-amazon-pay:before {
  content: "\e905";
}
.icon-cc-amex:before {
  content: "\e906";
}
.icon-cc-apple-pay:before {
  content: "\e907";
}
.icon-cc-diners-club:before {
  content: "\e908";
}
.icon-cc-discover:before {
  content: "\e909";
}
.icon-cc-jcb:before {
  content: "\e90a";
}
.icon-cc-mastercard:before {
  content: "\e90b";
}
.icon-cc-paypal:before {
  content: "\e90c";
}
.icon-cc-stripe:before {
  content: "\e90d";
}
.icon-cc-visa:before {
  content: "\e90e";
}
.icon-facebook-f:before {
  content: "\e90f";
}
.icon-facebook-square:before {
  content: "\e910";
}
.icon-facebook:before {
  content: "\e911";
}
.icon-fedex:before {
  content: "\e912";
}
.icon-git-square:before {
  content: "\e916";
}
.icon-git:before {
  content: "\e918";
}
.icon-github:before {
  content: "\e91a";
}
.icon-google-plus-square:before {
  content: "\e91b";
}
.icon-google-plus:before {
  content: "\e91d";
}
.icon-google:before {
  content: "\e91e";
}
.icon-html5:before {
  content: "\e91f";
}
.icon-instagram-square:before {
  content: "\e920";
}
.icon-instagram:before {
  content: "\e921";
}
.icon-invision:before {
  content: "\e922";
}
.icon-js-square:before {
  content: "\e923";
}
.icon-js:before {
  content: "\e924";
}
.icon-linkedin-in:before {
  content: "\e925";
}
.icon-linkedin:before {
  content: "\e926";
}
.icon-mailchimp:before {
  content: "\e927";
}
.icon-microsoft:before {
  content: "\e928";
}
.icon-paypal:before {
  content: "\e929";
}
.icon-pinterest-p:before {
  content: "\e92a";
}
.icon-pinterest-square:before {
  content: "\e92b";
}
.icon-pinterest:before {
  content: "\e92c";
}
.icon-salesforce:before {
  content: "\e92d";
}
.icon-slack:before {
  content: "\e92e";
}
.icon-soundcloud:before {
  content: "\e92f";
}
.icon-spotify:before {
  content: "\e930";
}
.icon-tiktok:before {
  content: "\e931";
}
.icon-twitch:before {
  content: "\e932";
}
.icon-twitter-square:before {
  content: "\e933";
}
.icon-twitter:before {
  content: "\e934";
}
.icon-ups:before {
  content: "\e935";
}
.icon-usb:before {
  content: "\e936";
}
.icon-usps:before {
  content: "\e937";
}
.icon-vimeo-square:before {
  content: "\e938";
}
.icon-vimeo-v:before {
  content: "\e939";
}
.icon-vimeo:before {
  content: "\e93a";
}
.icon-windows:before {
  content: "\e93b";
}
.icon-wordpress-simple:before {
  content: "\f793";
}
.icon-wordpress:before {
  content: "\f79a";
}
.icon-yelp:before {
  content: "\f79b";
}
.icon-yoast:before {
  content: "\f79c";
}
.icon-youtube-square:before {
  content: "\f79d";
}
.icon-youtube:before {
  content: "\f79e";
}
.icon-address-card .path1:before {
  content: "\e93c";
  color: rgb(0, 0, 0);
}
.icon-address-card .path2:before {
  content: "\e93d";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-air-conditioner .path1:before {
  content: "\e93e";
  color: rgb(0, 0, 0);
}
.icon-air-conditioner .path2:before {
  content: "\e93f";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-alarm-clock .path1:before {
  content: "\e940";
  color: rgb(0, 0, 0);
}
.icon-alarm-clock .path2:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-alarm-exclamation .path1:before {
  content: "\e942";
  color: rgb(0, 0, 0);
}
.icon-alarm-exclamation .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-album-collection-circle-plus .path1:before {
  content: "\f59f";
  color: rgb(0, 0, 0);
}
.icon-album-collection-circle-plus .path2:before {
  content: "\f5a0";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-angle-down:before {
  content: "\e944";
}
.icon-angle-left:before {
  content: "\e945";
}
.icon-angle-right:before {
  content: "\e946";
}
.icon-angle-up:before {
  content: "\e947";
}
.icon-angles-down .path1:before {
  content: "\e948";
  color: rgb(0, 0, 0);
}
.icon-angles-down .path2:before {
  content: "\e949";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-angles-left .path1:before {
  content: "\e94a";
  color: rgb(0, 0, 0);
}
.icon-angles-left .path2:before {
  content: "\e94b";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-angles-right .path1:before {
  content: "\e94c";
  color: rgb(0, 0, 0);
}
.icon-angles-right .path2:before {
  content: "\e94d";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-angles-up .path1:before {
  content: "\e94e";
  color: rgb(0, 0, 0);
}
.icon-angles-up .path2:before {
  content: "\e94f";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-apple-whole .path1:before {
  content: "\e950";
  color: rgb(0, 0, 0);
}
.icon-apple-whole .path2:before {
  content: "\e951";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-archway .path1:before {
  content: "\e952";
  color: rgb(0, 0, 0);
}
.icon-archway .path2:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-down-arrow-up .path1:before {
  content: "\e954";
  color: rgb(0, 0, 0);
}
.icon-arrow-down-arrow-up .path2:before {
  content: "\e955";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-down-from-line .path1:before {
  content: "\e956";
  color: rgb(0, 0, 0);
}
.icon-arrow-down-from-line .path2:before {
  content: "\e957";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-down-left-and-arrow-up-right-to-center .path1:before {
  content: "\e958";
  color: rgb(0, 0, 0);
}
.icon-arrow-down-left-and-arrow-up-right-to-center .path2:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-down-left .path1:before {
  content: "\e95a";
  color: rgb(0, 0, 0);
}
.icon-arrow-down-left .path2:before {
  content: "\e95b";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-down-long .path1:before {
  content: "\e95c";
  color: rgb(0, 0, 0);
}
.icon-arrow-down-long .path2:before {
  content: "\e95d";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-down-right .path1:before {
  content: "\e95e";
  color: rgb(0, 0, 0);
}
.icon-arrow-down-right .path2:before {
  content: "\e95f";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-down-to-bracket .path1:before {
  content: "\e960";
  color: rgb(0, 0, 0);
}
.icon-arrow-down-to-bracket .path2:before {
  content: "\e961";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-down-to-line .path1:before {
  content: "\e962";
  color: rgb(0, 0, 0);
}
.icon-arrow-down-to-line .path2:before {
  content: "\e963";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-down-to-square .path1:before {
  content: "\e964";
  color: rgb(0, 0, 0);
}
.icon-arrow-down-to-square .path2:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-down .path1:before {
  content: "\e966";
  color: rgb(0, 0, 0);
}
.icon-arrow-down .path2:before {
  content: "\e967";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-left-from-line .path1:before {
  content: "\e968";
  color: rgb(0, 0, 0);
}
.icon-arrow-left-from-line .path2:before {
  content: "\e969";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-left-long-to-line:before {
  content: "\e96a";
}
.icon-arrow-left-long .path1:before {
  content: "\e96b";
  color: rgb(0, 0, 0);
}
.icon-arrow-left-long .path2:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-left-to-line .path1:before {
  content: "\e96d";
  color: rgb(0, 0, 0);
}
.icon-arrow-left-to-line .path2:before {
  content: "\e96e";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-left .path1:before {
  content: "\e96f";
  color: rgb(0, 0, 0);
}
.icon-arrow-left .path2:before {
  content: "\e970";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-right-arrow-left .path1:before {
  content: "\e971";
  color: rgb(0, 0, 0);
}
.icon-arrow-right-arrow-left .path2:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-right-from-line .path1:before {
  content: "\e973";
  color: rgb(0, 0, 0);
}
.icon-arrow-right-from-line .path2:before {
  content: "\e974";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-right-long-to-line:before {
  content: "\e975";
}
.icon-arrow-right-long .path1:before {
  content: "\e976";
  color: rgb(0, 0, 0);
}
.icon-arrow-right-long .path2:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-right-to-line .path1:before {
  content: "\e978";
  color: rgb(0, 0, 0);
}
.icon-arrow-right-to-line .path2:before {
  content: "\e979";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-right .path1:before {
  content: "\e97a";
  color: rgb(0, 0, 0);
}
.icon-arrow-right .path2:before {
  content: "\e97b";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-turn-down-left .path1:before {
  content: "\e97c";
  color: rgb(0, 0, 0);
}
.icon-arrow-turn-down-left .path2:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-turn-down-right .path1:before {
  content: "\e97e";
  color: rgb(0, 0, 0);
}
.icon-arrow-turn-down-right .path2:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-turn-down .path1:before {
  content: "\e980";
  color: rgb(0, 0, 0);
}
.icon-arrow-turn-down .path2:before {
  content: "\e981";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-turn-up .path1:before {
  content: "\e982";
  color: rgb(0, 0, 0);
}
.icon-arrow-turn-up .path2:before {
  content: "\e983";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-up-from-line .path1:before {
  content: "\e984";
  color: rgb(0, 0, 0);
}
.icon-arrow-up-from-line .path2:before {
  content: "\e985";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-up-left .path1:before {
  content: "\e986";
  color: rgb(0, 0, 0);
}
.icon-arrow-up-left .path2:before {
  content: "\e987";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-up-long .path1:before {
  content: "\e988";
  color: rgb(0, 0, 0);
}
.icon-arrow-up-long .path2:before {
  content: "\e989";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-up-right .path1:before {
  content: "\e98a";
  color: rgb(0, 0, 0);
}
.icon-arrow-up-right .path2:before {
  content: "\e98b";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-up-to-line .path1:before {
  content: "\e98c";
  color: rgb(0, 0, 0);
}
.icon-arrow-up-to-line .path2:before {
  content: "\e98d";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrow-up .path1:before {
  content: "\e98e";
  color: rgb(0, 0, 0);
}
.icon-arrow-up .path2:before {
  content: "\e98f";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrows-left-right .path1:before {
  content: "\e990";
  color: rgb(0, 0, 0);
}
.icon-arrows-left-right .path2:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrows-maximize .path1:before {
  content: "\e992";
  color: rgb(0, 0, 0);
}
.icon-arrows-maximize .path2:before {
  content: "\e993";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrows-repeat .path1:before {
  content: "\e994";
  color: rgb(0, 0, 0);
}
.icon-arrows-repeat .path2:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-arrows-retweet .path1:before {
  content: "\e996";
  color: rgb(0, 0, 0);
}
.icon-arrows-retweet .path2:before {
  content: "\e997";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-audio-description .path1:before {
  content: "\e998";
  color: rgb(0, 0, 0);
}
.icon-audio-description .path2:before {
  content: "\e999";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-award-simple .path1:before {
  content: "\e99a";
  color: rgb(0, 0, 0);
}
.icon-award-simple .path2:before {
  content: "\e99b";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-award .path1:before {
  content: "\e99c";
  color: rgb(0, 0, 0);
}
.icon-award .path2:before {
  content: "\e99d";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-badge-check .path1:before {
  content: "\e99e";
  color: rgb(0, 0, 0);
}
.icon-badge-check .path2:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-badge-dollar .path1:before {
  content: "\e9a0";
  color: rgb(0, 0, 0);
}
.icon-badge-dollar .path2:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-badge-percent .path1:before {
  content: "\e9a2";
  color: rgb(0, 0, 0);
}
.icon-badge-percent .path2:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-badge:before {
  content: "\e9a4";
}
.icon-ballot-check .path1:before {
  content: "\e9a5";
  color: rgb(0, 0, 0);
}
.icon-ballot-check .path2:before {
  content: "\e9a6";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-ballot .path1:before {
  content: "\e9a7";
  color: rgb(0, 0, 0);
}
.icon-ballot .path2:before {
  content: "\e9a8";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-bars .path1:before {
  content: "\e9a9";
  color: rgb(0, 0, 0);
}
.icon-bars .path2:before {
  content: "\e9aa";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-bed-pulse .path1:before {
  content: "\f683";
  color: rgb(0, 0, 0);
}
.icon-bed-pulse .path2:before {
  content: "\f684";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-bell-on .path1:before {
  content: "\e9ab";
  color: rgb(0, 0, 0);
}
.icon-bell-on .path2:before {
  content: "\e9ac";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-bell .path1:before {
  content: "\e9ad";
  color: rgb(0, 0, 0);
}
.icon-bell .path2:before {
  content: "\e9ae";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-blanket .path1:before {
  content: "\e9af";
  color: rgb(0, 0, 0);
}
.icon-blanket .path2:before {
  content: "\e9b0";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-bolt-lightning:before {
  content: "\f70e";
}
.icon-bolt:before {
  content: "\f70f";
}
.icon-book-blank .path1:before {
  content: "\e9b1";
  color: rgb(0, 0, 0);
}
.icon-book-blank .path2:before {
  content: "\e9b2";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-book-open-cover .path1:before {
  content: "\e9b3";
  color: rgb(0, 0, 0);
}
.icon-book-open-cover .path2:before {
  content: "\e9b4";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-book-open-reader .path1:before {
  content: "\e9b5";
  color: rgb(0, 0, 0);
}
.icon-book-open-reader .path2:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-books .path1:before {
  content: "\e9b7";
  color: rgb(0, 0, 0);
}
.icon-books .path2:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-box-check .path1:before {
  content: "\e9b9";
  color: rgb(0, 0, 0);
}
.icon-box-check .path2:before {
  content: "\e9ba";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-box-dollar .path1:before {
  content: "\e9bb";
  color: rgb(0, 0, 0);
}
.icon-box-dollar .path2:before {
  content: "\e9bc";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-box-heart .path1:before {
  content: "\e9bd";
  color: rgb(0, 0, 0);
}
.icon-box-heart .path2:before {
  content: "\e9be";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-box-open .path1:before {
  content: "\e9bf";
  color: rgb(0, 0, 0);
}
.icon-box-open .path2:before {
  content: "\e9c0";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-box-taped .path1:before {
  content: "\e9c1";
  color: rgb(0, 0, 0);
}
.icon-box-taped .path2:before {
  content: "\e9c2";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-box .path1:before {
  content: "\e9c3";
  color: rgb(0, 0, 0);
}
.icon-box .path2:before {
  content: "\e9c4";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-brain .path1:before {
  content: "\e9c5";
  color: rgb(0, 0, 0);
}
.icon-brain .path2:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-brake-warning .path1:before {
  content: "\e9c7";
  color: rgb(0, 0, 0);
}
.icon-brake-warning .path2:before {
  content: "\e9c8";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-briefcase-blank .path1:before {
  content: "\e9c9";
  color: rgb(0, 0, 0);
}
.icon-briefcase-blank .path2:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-briefcase-medical .path1:before {
  content: "\e9cb";
  color: rgb(0, 0, 0);
}
.icon-briefcase-medical .path2:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-briefcase .path1:before {
  content: "\e9cd";
  color: rgb(0, 0, 0);
}
.icon-briefcase .path2:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-brightness .path1:before {
  content: "\e9cf";
  color: rgb(0, 0, 0);
}
.icon-brightness .path2:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-broom-ball .path1:before {
  content: "\e9d1";
  color: rgb(0, 0, 0);
}
.icon-broom-ball .path2:before {
  content: "\e9d2";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-broom .path1:before {
  content: "\e9d3";
  color: rgb(0, 0, 0);
}
.icon-broom .path2:before {
  content: "\e9d4";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-browser .path1:before {
  content: "\e9d5";
  color: rgb(0, 0, 0);
}
.icon-browser .path2:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-browsers .path1:before {
  content: "\e9d7";
  color: rgb(0, 0, 0);
}
.icon-browsers .path2:before {
  content: "\e9d8";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-building-circle-check .path1:before {
  content: "\e9d9";
  color: rgb(0, 0, 0);
}
.icon-building-circle-check .path2:before {
  content: "\e9da";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-building-columns .path1:before {
  content: "\f6e6";
  color: rgb(0, 0, 0);
}
.icon-building-columns .path2:before {
  content: "\f6e7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-building-shield .path1:before {
  content: "\e9db";
  color: rgb(0, 0, 0);
}
.icon-building-shield .path2:before {
  content: "\e9dc";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-building-user .path1:before {
  content: "\e9dd";
  color: rgb(0, 0, 0);
}
.icon-building-user .path2:before {
  content: "\e9de";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-building .path1:before {
  content: "\e9df";
  color: rgb(0, 0, 0);
}
.icon-building .path2:before {
  content: "\e9e0";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-buildings .path1:before {
  content: "\e9e1";
  color: rgb(0, 0, 0);
}
.icon-buildings .path2:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-bullhorn .path1:before {
  content: "\f75d";
  color: rgb(0, 0, 0);
}
.icon-bullhorn .path2:before {
  content: "\f75e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-business-time .path1:before {
  content: "\e9e3";
  color: rgb(0, 0, 0);
}
.icon-business-time .path2:before {
  content: "\e9e4";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cabinet-filing .path1:before {
  content: "\e9e5";
  color: rgb(0, 0, 0);
}
.icon-cabinet-filing .path2:before {
  content: "\e9e6";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calculator-simple .path1:before {
  content: "\e9e7";
  color: rgb(0, 0, 0);
}
.icon-calculator-simple .path2:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar-check .path1:before {
  content: "\e9e9";
  color: rgb(0, 0, 0);
}
.icon-calendar-check .path2:before {
  content: "\e9ea";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar-circle-exclamation .path1:before {
  content: "\e9eb";
  color: rgb(0, 0, 0);
}
.icon-calendar-circle-exclamation .path2:before {
  content: "\e9ec";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar-circle-user .path1:before {
  content: "\e9ed";
  color: rgb(0, 0, 0);
}
.icon-calendar-circle-user .path2:before {
  content: "\e9ee";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar-clock .path1:before {
  content: "\e9ef";
  color: rgb(0, 0, 0);
}
.icon-calendar-clock .path2:before {
  content: "\e9f0";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar-days .path1:before {
  content: "\e9f1";
  color: rgb(0, 0, 0);
}
.icon-calendar-days .path2:before {
  content: "\e9f2";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar-lines-pen .path1:before {
  content: "\e9f3";
  color: rgb(0, 0, 0);
}
.icon-calendar-lines-pen .path2:before {
  content: "\e9f4";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar-pen .path1:before {
  content: "\e9f5";
  color: rgb(0, 0, 0);
}
.icon-calendar-pen .path2:before {
  content: "\e9f6";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar-plus .path1:before {
  content: "\f702";
  color: rgb(0, 0, 0);
}
.icon-calendar-plus .path2:before {
  content: "\f703";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar-star .path1:before {
  content: "\e9f7";
  color: rgb(0, 0, 0);
}
.icon-calendar-star .path2:before {
  content: "\e9f8";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar-xmark .path1:before {
  content: "\e9f9";
  color: rgb(0, 0, 0);
}
.icon-calendar-xmark .path2:before {
  content: "\e9fa";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-calendar .path1:before {
  content: "\e9fb";
  color: rgb(0, 0, 0);
}
.icon-calendar .path2:before {
  content: "\e9fc";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-caret-down:before {
  content: "\e9fd";
}
.icon-caret-left:before {
  content: "\e9fe";
}
.icon-caret-right:before {
  content: "\e9ff";
}
.icon-caret-up:before {
  content: "\ea00";
}
.icon-cart-arrow-down .path1:before {
  content: "\ea01";
  color: rgb(0, 0, 0);
}
.icon-cart-arrow-down .path2:before {
  content: "\ea02";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cart-arrow-up .path1:before {
  content: "\ea03";
  color: rgb(0, 0, 0);
}
.icon-cart-arrow-up .path2:before {
  content: "\ea04";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cart-circle-arrow-down .path1:before {
  content: "\ea05";
  color: rgb(0, 0, 0);
}
.icon-cart-circle-arrow-down .path2:before {
  content: "\ea06";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cart-circle-check .path1:before {
  content: "\ea07";
  color: rgb(0, 0, 0);
}
.icon-cart-circle-check .path2:before {
  content: "\ea08";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cart-circle-plus .path1:before {
  content: "\ea09";
  color: rgb(0, 0, 0);
}
.icon-cart-circle-plus .path2:before {
  content: "\ea0a";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cart-flatbed-boxes .path1:before {
  content: "\ea0b";
  color: rgb(0, 0, 0);
}
.icon-cart-flatbed-boxes .path2:before {
  content: "\ea0c";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cart-flatbed-empty .path1:before {
  content: "\ea0d";
  color: rgb(0, 0, 0);
}
.icon-cart-flatbed-empty .path2:before {
  content: "\ea0e";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cart-flatbed-suitcase .path1:before {
  content: "\ea0f";
  color: rgb(0, 0, 0);
}
.icon-cart-flatbed-suitcase .path2:before {
  content: "\ea10";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cart-flatbed .path1:before {
  content: "\ea11";
  color: rgb(0, 0, 0);
}
.icon-cart-flatbed .path2:before {
  content: "\ea12";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cart-shopping-fast .path1:before {
  content: "\ea13";
  color: rgb(0, 0, 0);
}
.icon-cart-shopping-fast .path2:before {
  content: "\ea14";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cart-shopping .path1:before {
  content: "\ea15";
  color: rgb(0, 0, 0);
}
.icon-cart-shopping .path2:before {
  content: "\ea16";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-chalkboard-user .path1:before {
  content: "\ea17";
  color: rgb(0, 0, 0);
}
.icon-chalkboard-user .path2:before {
  content: "\ea18";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-chart-column .path1:before {
  content: "\ea19";
  color: rgb(0, 0, 0);
}
.icon-chart-column .path2:before {
  content: "\ea1a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-chart-line-down .path1:before {
  content: "\ea1b";
  color: rgb(0, 0, 0);
}
.icon-chart-line-down .path2:before {
  content: "\ea1c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-chart-line-up .path1:before {
  content: "\ea1d";
  color: rgb(0, 0, 0);
}
.icon-chart-line-up .path2:before {
  content: "\ea1e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-chart-line .path1:before {
  content: "\ea1f";
  color: rgb(0, 0, 0);
}
.icon-chart-line .path2:before {
  content: "\ea20";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-check1:before {
  content: "\ea21";
}
.icon-chevron-down:before {
  content: "\ea22";
}
.icon-chevron-left:before {
  content: "\ea23";
}
.icon-chevron-right:before {
  content: "\ea24";
}
.icon-chevron-up:before {
  content: "\ea25";
}
.icon-chevrons-down .path1:before {
  content: "\ea26";
  color: rgb(0, 0, 0);
}
.icon-chevrons-down .path2:before {
  content: "\ea27";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-chevrons-left .path1:before {
  content: "\ea28";
  color: rgb(0, 0, 0);
}
.icon-chevrons-left .path2:before {
  content: "\ea29";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-chevrons-right .path1:before {
  content: "\ea2a";
  color: rgb(0, 0, 0);
}
.icon-chevrons-right .path2:before {
  content: "\ea2b";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-chevrons-up .path1:before {
  content: "\ea2c";
  color: rgb(0, 0, 0);
}
.icon-chevrons-up .path2:before {
  content: "\ea2d";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-arrow-down-left .path1:before {
  content: "\ea2e";
  color: rgb(0, 0, 0);
}
.icon-circle-arrow-down-left .path2:before {
  content: "\ea2f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-arrow-down-right .path1:before {
  content: "\ea30";
  color: rgb(0, 0, 0);
}
.icon-circle-arrow-down-right .path2:before {
  content: "\ea31";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-arrow-down .path1:before {
  content: "\ea32";
  color: rgb(0, 0, 0);
}
.icon-circle-arrow-down .path2:before {
  content: "\ea33";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-arrow-left .path1:before {
  content: "\ea34";
  color: rgb(0, 0, 0);
}
.icon-circle-arrow-left .path2:before {
  content: "\ea35";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-arrow-right .path1:before {
  content: "\ea36";
  color: rgb(0, 0, 0);
}
.icon-circle-arrow-right .path2:before {
  content: "\ea37";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-arrow-up-left .path1:before {
  content: "\ea38";
  color: rgb(0, 0, 0);
}
.icon-circle-arrow-up-left .path2:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-arrow-up-right .path1:before {
  content: "\ea3a";
  color: rgb(0, 0, 0);
}
.icon-circle-arrow-up-right .path2:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-arrow-up .path1:before {
  content: "\ea3c";
  color: rgb(0, 0, 0);
}
.icon-circle-arrow-up .path2:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-caret-down .path1:before {
  content: "\ea3e";
  color: rgb(0, 0, 0);
}
.icon-circle-caret-down .path2:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-caret-left .path1:before {
  content: "\ea40";
  color: rgb(0, 0, 0);
}
.icon-circle-caret-left .path2:before {
  content: "\ea41";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-caret-right .path1:before {
  content: "\ea42";
  color: rgb(0, 0, 0);
}
.icon-circle-caret-right .path2:before {
  content: "\ea43";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-caret-up .path1:before {
  content: "\ea44";
  color: rgb(0, 0, 0);
}
.icon-circle-caret-up .path2:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-check .path1:before {
  content: "\ea46";
  color: rgb(0, 0, 0);
}
.icon-circle-check .path2:before {
  content: "\ea47";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-chevron-down .path1:before {
  content: "\ea48";
  color: rgb(0, 0, 0);
}
.icon-circle-chevron-down .path2:before {
  content: "\ea49";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-chevron-left .path1:before {
  content: "\ea4a";
  color: rgb(0, 0, 0);
}
.icon-circle-chevron-left .path2:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-chevron-right .path1:before {
  content: "\ea4c";
  color: rgb(0, 0, 0);
}
.icon-circle-chevron-right .path2:before {
  content: "\ea4d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-chevron-up .path1:before {
  content: "\ea4e";
  color: rgb(0, 0, 0);
}
.icon-circle-chevron-up .path2:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-dollar .path1:before {
  content: "\ea50";
  color: rgb(0, 0, 0);
}
.icon-circle-dollar .path2:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-down-left .path1:before {
  content: "\ea52";
  color: rgb(0, 0, 0);
}
.icon-circle-down-left .path2:before {
  content: "\ea53";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-down-right .path1:before {
  content: "\ea54";
  color: rgb(0, 0, 0);
}
.icon-circle-down-right .path2:before {
  content: "\ea55";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-down .path1:before {
  content: "\ea56";
  color: rgb(0, 0, 0);
}
.icon-circle-down .path2:before {
  content: "\ea57";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-envelope .path1:before {
  content: "\ea58";
  color: rgb(0, 0, 0);
}
.icon-circle-envelope .path2:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-exclamation .path1:before {
  content: "\ea5a";
  color: rgb(0, 0, 0);
}
.icon-circle-exclamation .path2:before {
  content: "\ea5b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-left .path1:before {
  content: "\ea5c";
  color: rgb(0, 0, 0);
}
.icon-circle-left .path2:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-location-arrow .path1:before {
  content: "\ea5e";
  color: rgb(0, 0, 0);
}
.icon-circle-location-arrow .path2:before {
  content: "\ea5f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-minus .path1:before {
  content: "\f6e8";
  color: rgb(0, 0, 0);
}
.icon-circle-minus .path2:before {
  content: "\f6e9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-pause .path1:before {
  content: "\ea60";
  color: rgb(0, 0, 0);
}
.icon-circle-pause .path2:before {
  content: "\ea61";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-phone .path1:before {
  content: "\ea62";
  color: rgb(0, 0, 0);
}
.icon-circle-phone .path2:before {
  content: "\ea63";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-plus .path1:before {
  content: "\ea64";
  color: rgb(0, 0, 0);
}
.icon-circle-plus .path2:before {
  content: "\ea65";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-right .path1:before {
  content: "\ea66";
  color: rgb(0, 0, 0);
}
.icon-circle-right .path2:before {
  content: "\ea67";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-star .path1:before {
  content: "\ea68";
  color: rgb(0, 0, 0);
}
.icon-circle-star .path2:before {
  content: "\ea69";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-up-left .path1:before {
  content: "\ea6a";
  color: rgb(0, 0, 0);
}
.icon-circle-up-left .path2:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-up-right .path1:before {
  content: "\ea6c";
  color: rgb(0, 0, 0);
}
.icon-circle-up-right .path2:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-up .path1:before {
  content: "\ea6e";
  color: rgb(0, 0, 0);
}
.icon-circle-up .path2:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-user .path1:before {
  content: "\f7a1";
  color: rgb(0, 0, 0);
}
.icon-circle-user .path2:before {
  content: "\f7a2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-circle-video .path1:before {
  content: "\ea70";
  color: rgb(0, 0, 0);
}
.icon-circle-video .path2:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-clapperboard-play .path1:before {
  content: "\ea72";
  color: rgb(0, 0, 0);
}
.icon-clapperboard-play .path2:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-clipboard-check .path1:before {
  content: "\ea74";
  color: rgb(0, 0, 0);
}
.icon-clipboard-check .path2:before {
  content: "\ea75";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-clipboard-list-check .path1:before {
  content: "\ea76";
  color: rgb(0, 0, 0);
}
.icon-clipboard-list-check .path2:before {
  content: "\ea77";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-clipboard-list .path1:before {
  content: "\ea78";
  color: rgb(0, 0, 0);
}
.icon-clipboard-list .path2:before {
  content: "\ea79";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-clipboard-medical .path1:before {
  content: "\ea7a";
  color: rgb(0, 0, 0);
}
.icon-clipboard-medical .path2:before {
  content: "\ea7b";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-clipboard-prescription .path1:before {
  content: "\f685";
  color: rgb(0, 0, 0);
}
.icon-clipboard-prescription .path2:before {
  content: "\f686";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-clipboard-user .path1:before {
  content: "\ea7c";
  color: rgb(0, 0, 0);
}
.icon-clipboard-user .path2:before {
  content: "\ea7d";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-clock-desk .path1:before {
  content: "\ea7e";
  color: rgb(0, 0, 0);
}
.icon-clock-desk .path2:before {
  content: "\ea7f";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-clock-rotate-left .path1:before {
  content: "\ea80";
  color: rgb(0, 0, 0);
}
.icon-clock-rotate-left .path2:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-clock .path1:before {
  content: "\ea82";
  color: rgb(0, 0, 0);
}
.icon-clock .path2:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cloud-arrow-down .path1:before {
  content: "\ea84";
  color: rgb(0, 0, 0);
}
.icon-cloud-arrow-down .path2:before {
  content: "\ea85";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cloud-bolt .path1:before {
  content: "\ea86";
  color: rgb(0, 0, 0);
}
.icon-cloud-bolt .path2:before {
  content: "\ea87";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cloud-check .path1:before {
  content: "\ea88";
  color: rgb(0, 0, 0);
}
.icon-cloud-check .path2:before {
  content: "\ea89";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-cloud-plus .path1:before {
  content: "\f687";
  color: rgb(0, 0, 0);
}
.icon-cloud-plus .path2:before {
  content: "\f688";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-coins .path1:before {
  content: "\ea8a";
  color: rgb(0, 0, 0);
}
.icon-coins .path2:before {
  content: "\ea8b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-comment-check .path1:before {
  content: "\ea8c";
  color: rgb(0, 0, 0);
}
.icon-comment-check .path2:before {
  content: "\ea8d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-comment-dollar .path1:before {
  content: "\ea8e";
  color: rgb(0, 0, 0);
}
.icon-comment-dollar .path2:before {
  content: "\ea8f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-comment-quote .path1:before {
  content: "\ea90";
  color: rgb(0, 0, 0);
}
.icon-comment-quote .path2:before {
  content: "\ea91";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-comment-smile .path1:before {
  content: "\ea92";
  color: rgb(0, 0, 0);
}
.icon-comment-smile .path2:before {
  content: "\ea93";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-comment-sms .path1:before {
  content: "\ea94";
  color: rgb(0, 0, 0);
}
.icon-comment-sms .path2:before {
  content: "\ea95";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-comment:before {
  content: "\ea96";
}
.icon-comments-dollar .path1:before {
  content: "\ea97";
  color: rgb(0, 0, 0);
}
.icon-comments-dollar .path2:before {
  content: "\ea98";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-comments-question .path1:before {
  content: "\f775";
  color: rgb(0, 0, 0);
}
.icon-comments-question .path2:before {
  content: "\f776";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-comments .path1:before {
  content: "\ea99";
  color: rgb(0, 0, 0);
}
.icon-comments .path2:before {
  content: "\ea9a";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-conveyor-belt .path1:before {
  content: "\ea9b";
  color: rgb(0, 0, 0);
}
.icon-conveyor-belt .path2:before {
  content: "\ea9c";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-copyright .path1:before {
  content: "\ea9d";
  color: rgb(0, 0, 0);
}
.icon-copyright .path2:before {
  content: "\ea9e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-credit-card-blank .path1:before {
  content: "\ea9f";
  color: rgb(0, 0, 0);
}
.icon-credit-card-blank .path2:before {
  content: "\eaa0";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-credit-card-front .path1:before {
  content: "\eaa1";
  color: rgb(0, 0, 0);
}
.icon-credit-card-front .path2:before {
  content: "\eaa2";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-credit-card .path1:before {
  content: "\eaa3";
  color: rgb(0, 0, 0);
}
.icon-credit-card .path2:before {
  content: "\eaa4";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-dash4:before {
  content: "\f657";
}
.icon-diploma .path1:before {
  content: "\eaa5";
  color: rgb(0, 0, 0);
}
.icon-diploma .path2:before {
  content: "\eaa6";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-dollar-sign .path1:before {
  content: "\f757";
  color: rgb(0, 0, 0);
}
.icon-dollar-sign .path2:before {
  content: "\f758";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-dolly-empty .path1:before {
  content: "\eaa7";
  color: rgb(0, 0, 0);
}
.icon-dolly-empty .path2:before {
  content: "\eaa8";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-dolly .path1:before {
  content: "\eaa9";
  color: rgb(0, 0, 0);
}
.icon-dolly .path2:before {
  content: "\eaaa";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-door-open .path1:before {
  content: "\eaab";
  color: rgb(0, 0, 0);
}
.icon-door-open .path2:before {
  content: "\eaac";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-down-from-line .path1:before {
  content: "\eaad";
  color: rgb(0, 0, 0);
}
.icon-down-from-line .path2:before {
  content: "\eaae";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-down-left-and-up-right-to-center .path1:before {
  content: "\eaaf";
  color: rgb(0, 0, 0);
}
.icon-down-left-and-up-right-to-center .path2:before {
  content: "\eab0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-down-left .path1:before {
  content: "\eab1";
  color: rgb(0, 0, 0);
}
.icon-down-left .path2:before {
  content: "\eab2";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-down-long .path1:before {
  content: "\eab3";
  color: rgb(0, 0, 0);
}
.icon-down-long .path2:before {
  content: "\eab4";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-down-right .path1:before {
  content: "\eab5";
  color: rgb(0, 0, 0);
}
.icon-down-right .path2:before {
  content: "\eab6";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-down-to-bracket .path1:before {
  content: "\eab7";
  color: rgb(0, 0, 0);
}
.icon-down-to-bracket .path2:before {
  content: "\eab8";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-down-to-line .path1:before {
  content: "\eab9";
  color: rgb(0, 0, 0);
}
.icon-down-to-line .path2:before {
  content: "\eaba";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-down .path1:before {
  content: "\eabb";
  color: rgb(0, 0, 0);
}
.icon-down .path2:before {
  content: "\eabc";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-droplet-degree .path1:before {
  content: "\f727";
  color: rgb(0, 0, 0);
}
.icon-droplet-degree .path2:before {
  content: "\f728";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-droplet .path1:before {
  content: "\f729";
  color: rgb(0, 0, 0);
}
.icon-droplet .path2:before {
  content: "\f72a";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-earth-americas .path1:before {
  content: "\eabd";
  color: rgb(0, 0, 0);
}
.icon-earth-americas .path2:before {
  content: "\eabe";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-envelope-circle-check .path1:before {
  content: "\eabf";
  color: rgb(0, 0, 0);
}
.icon-envelope-circle-check .path2:before {
  content: "\eac0";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-envelope-open-dollar .path1:before {
  content: "\eac1";
  color: rgb(0, 0, 0);
}
.icon-envelope-open-dollar .path2:before {
  content: "\eac2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-envelope-open-text .path1:before {
  content: "\eac3";
  color: rgb(0, 0, 0);
}
.icon-envelope-open-text .path2:before {
  content: "\eac4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-envelope .path1:before {
  content: "\eac5";
  color: rgb(0, 0, 0);
}
.icon-envelope .path2:before {
  content: "\eac6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-eye .path1:before {
  content: "\eac7";
  color: rgb(0, 0, 0);
}
.icon-eye .path2:before {
  content: "\eac8";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-face-grin-wide .path1:before {
  content: "\eac9";
  color: rgb(0, 0, 0);
}
.icon-face-grin-wide .path2:before {
  content: "\eaca";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-face-relieved .path1:before {
  content: "\eacb";
  color: rgb(0, 0, 0);
}
.icon-face-relieved .path2:before {
  content: "\eacc";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-face-smile-plus .path1:before {
  content: "\eacd";
  color: rgb(0, 0, 0);
}
.icon-face-smile-plus .path2:before {
  content: "\eace";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-face-smile .path1:before {
  content: "\eacf";
  color: rgb(0, 0, 0);
}
.icon-face-smile .path2:before {
  content: "\ead0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-family-dress .path1:before {
  content: "\ead1";
  color: rgb(0, 0, 0);
}
.icon-family-dress .path2:before {
  content: "\ead2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-family-pants .path1:before {
  content: "\ead3";
  color: rgb(0, 0, 0);
}
.icon-family-pants .path2:before {
  content: "\ead4";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-family .path1:before {
  content: "\ead5";
  color: rgb(0, 0, 0);
}
.icon-family .path2:before {
  content: "\ead6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fan-table .path1:before {
  content: "\ead7";
  color: rgb(0, 0, 0);
}
.icon-fan-table .path2:before {
  content: "\ead8";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fan .path1:before {
  content: "\ead9";
  color: rgb(0, 0, 0);
}
.icon-fan .path2:before {
  content: "\eada";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-faucet-drip .path1:before {
  content: "\eadb";
  color: rgb(0, 0, 0);
}
.icon-faucet-drip .path2:before {
  content: "\eadc";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-faucet .path1:before {
  content: "\eadd";
  color: rgb(0, 0, 0);
}
.icon-faucet .path2:before {
  content: "\eade";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fax .path1:before {
  content: "\eadf";
  color: rgb(0, 0, 0);
}
.icon-fax .path2:before {
  content: "\eae0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-feather-pointed .path1:before {
  content: "\eae1";
  color: rgb(0, 0, 0);
}
.icon-feather-pointed .path2:before {
  content: "\eae2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-file-arrow-down .path1:before {
  content: "\eae3";
  color: rgb(0, 0, 0);
}
.icon-file-arrow-down .path2:before {
  content: "\eae4";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-file-certificate .path1:before {
  content: "\eae5";
  color: rgb(0, 0, 0);
}
.icon-file-certificate .path2:before {
  content: "\eae6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-file-check .path1:before {
  content: "\eae7";
  color: rgb(0, 0, 0);
}
.icon-file-check .path2:before {
  content: "\eae8";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-file-circle-check .path1:before {
  content: "\eae9";
  color: rgb(0, 0, 0);
}
.icon-file-circle-check .path2:before {
  content: "\eaea";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-file-circle-plus .path1:before {
  content: "\eaeb";
  color: rgb(0, 0, 0);
}
.icon-file-circle-plus .path2:before {
  content: "\eaec";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-file-exclamation .path1:before {
  content: "\eaed";
  color: rgb(0, 0, 0);
}
.icon-file-exclamation .path2:before {
  content: "\eaee";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-file-invoice-dollar .path1:before {
  content: "\eaef";
  color: rgb(0, 0, 0);
}
.icon-file-invoice-dollar .path2:before {
  content: "\eaf0";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-file-pdf .path1:before {
  content: "\eaf1";
  color: rgb(0, 0, 0);
}
.icon-file-pdf .path2:before {
  content: "\eaf2";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-file-user .path1:before {
  content: "\eaf3";
  color: rgb(0, 0, 0);
}
.icon-file-user .path2:before {
  content: "\eaf4";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-files-medical .path1:before {
  content: "\eaf5";
  color: rgb(0, 0, 0);
}
.icon-files-medical .path2:before {
  content: "\eaf6";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-filter-circle-dollar .path1:before {
  content: "\eaf7";
  color: rgb(0, 0, 0);
}
.icon-filter-circle-dollar .path2:before {
  content: "\eaf8";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fire-extinguisher .path1:before {
  content: "\eaf9";
  color: rgb(0, 0, 0);
}
.icon-fire-extinguisher .path2:before {
  content: "\eafa";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fire-flame-curved .path1:before {
  content: "\eafb";
  color: rgb(0, 0, 0);
}
.icon-fire-flame-curved .path2:before {
  content: "\eafc";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fire-flame-simple .path1:before {
  content: "\eafd";
  color: rgb(0, 0, 0);
}
.icon-fire-flame-simple .path2:before {
  content: "\eafe";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fire-flame .path1:before {
  content: "\eaff";
  color: rgb(0, 0, 0);
}
.icon-fire-flame .path2:before {
  content: "\eb00";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fire-hydrant .path1:before {
  content: "\eb01";
  color: rgb(0, 0, 0);
}
.icon-fire-hydrant .path2:before {
  content: "\eb02";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fire-smoke .path1:before {
  content: "\eb03";
  color: rgb(0, 0, 0);
}
.icon-fire-smoke .path2:before {
  content: "\eb04";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fire .path1:before {
  content: "\eb05";
  color: rgb(0, 0, 0);
}
.icon-fire .path2:before {
  content: "\eb06";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-fireplace .path1:before {
  content: "\eb07";
  color: rgb(0, 0, 0);
}
.icon-fireplace .path2:before {
  content: "\eb08";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-flag-usa .path1:before {
  content: "\eb09";
  color: rgb(0, 0, 0);
}
.icon-flag-usa .path2:before {
  content: "\eb0a";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-floppy-disk .path1:before {
  content: "\eb0b";
  color: rgb(0, 0, 0);
}
.icon-floppy-disk .path2:before {
  content: "\eb0c";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-folder-arrow-down .path1:before {
  content: "\eb0d";
  color: rgb(0, 0, 0);
}
.icon-folder-arrow-down .path2:before {
  content: "\eb0e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-folder-medical .path1:before {
  content: "\eb0f";
  color: rgb(0, 0, 0);
}
.icon-folder-medical .path2:before {
  content: "\eb10";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-folder-plus .path1:before {
  content: "\eb11";
  color: rgb(0, 0, 0);
}
.icon-folder-plus .path2:before {
  content: "\eb12";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-font-awesome .path1:before {
  content: "\eb13";
  color: rgb(0, 0, 0);
}
.icon-font-awesome .path2:before {
  content: "\eb14";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-forward-fast .path1:before {
  content: "\eb15";
  color: rgb(0, 0, 0);
}
.icon-forward-fast .path2:before {
  content: "\eb16";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-forward-step .path1:before {
  content: "\eb17";
  color: rgb(0, 0, 0);
}
.icon-forward-step .path2:before {
  content: "\eb18";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-forward .path1:before {
  content: "\eb19";
  color: rgb(0, 0, 0);
}
.icon-forward .path2:before {
  content: "\eb1a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-gear .path1:before {
  content: "\f777";
  color: rgb(0, 0, 0);
}
.icon-gear .path2:before {
  content: "\f778";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-gift-card .path1:before {
  content: "\eb1b";
  color: rgb(0, 0, 0);
}
.icon-gift-card .path2:before {
  content: "\eb1c";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-gift .path1:before {
  content: "\eb1d";
  color: rgb(0, 0, 0);
}
.icon-gift .path2:before {
  content: "\eb1e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-globe .path1:before {
  content: "\eb1f";
  color: rgb(0, 0, 0);
}
.icon-globe .path2:before {
  content: "\eb20";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-graduation-cap .path1:before {
  content: "\e913";
  color: rgb(0, 0, 0);
}
.icon-graduation-cap .path2:before {
  content: "\e914";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-grate-droplet .path1:before {
  content: "\eb21";
  color: rgb(0, 0, 0);
}
.icon-grate-droplet .path2:before {
  content: "\eb22";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-grate .path1:before {
  content: "\eb23";
  color: rgb(0, 0, 0);
}
.icon-grate .path2:before {
  content: "\eb24";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hammer-crash .path1:before {
  content: "\eb25";
  color: rgb(0, 0, 0);
}
.icon-hammer-crash .path2:before {
  content: "\eb26";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hand-back-fist:before {
  content: "\eb27";
}
.icon-hand-fingers-crossed .path1:before {
  content: "\eb28";
  color: rgb(0, 0, 0);
}
.icon-hand-fingers-crossed .path2:before {
  content: "\eb29";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hand-fist .path1:before {
  content: "\eb2a";
  color: rgb(0, 0, 0);
}
.icon-hand-fist .path2:before {
  content: "\eb2b";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hand-holding-box .path1:before {
  content: "\eb2c";
  color: rgb(0, 0, 0);
}
.icon-hand-holding-box .path2:before {
  content: "\eb2d";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hand-holding-dollar .path1:before {
  content: "\eb2e";
  color: rgb(0, 0, 0);
}
.icon-hand-holding-dollar .path2:before {
  content: "\eb2f";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hand-holding-droplet .path1:before {
  content: "\eb30";
  color: rgb(0, 0, 0);
}
.icon-hand-holding-droplet .path2:before {
  content: "\eb31";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hand-holding-hand .path1:before {
  content: "\eb32";
  color: rgb(0, 0, 0);
}
.icon-hand-holding-hand .path2:before {
  content: "\eb33";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hand-holding-heart .path1:before {
  content: "\eb34";
  color: rgb(0, 0, 0);
}
.icon-hand-holding-heart .path2:before {
  content: "\eb35";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hand-holding-medical .path1:before {
  content: "\eb36";
  color: rgb(0, 0, 0);
}
.icon-hand-holding-medical .path2:before {
  content: "\eb37";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hand-sparkles .path1:before {
  content: "\eb38";
  color: rgb(0, 0, 0);
}
.icon-hand-sparkles .path2:before {
  content: "\eb39";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hands-bubbles .path1:before {
  content: "\eb3a";
  color: rgb(0, 0, 0);
}
.icon-hands-bubbles .path2:before {
  content: "\eb3b";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hands-holding-dollar .path1:before {
  content: "\eb3c";
  color: rgb(0, 0, 0);
}
.icon-hands-holding-dollar .path2:before {
  content: "\eb3d";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hands-holding-heart .path1:before {
  content: "\eb3e";
  color: rgb(0, 0, 0);
}
.icon-hands-holding-heart .path2:before {
  content: "\eb3f";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hands-holding:before {
  content: "\eb40";
}
.icon-hands-praying .path1:before {
  content: "\eb41";
  color: rgb(0, 0, 0);
}
.icon-hands-praying .path2:before {
  content: "\eb42";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hands .path1:before {
  content: "\eb43";
  color: rgb(0, 0, 0);
}
.icon-hands .path2:before {
  content: "\eb44";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-handshake-angle .path1:before {
  content: "\eb45";
  color: rgb(0, 0, 0);
}
.icon-handshake-angle .path2:before {
  content: "\eb46";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-handshake-simple .path1:before {
  content: "\eb47";
  color: rgb(0, 0, 0);
}
.icon-handshake-simple .path2:before {
  content: "\eb48";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-handshake .path1:before {
  content: "\eb49";
  color: rgb(0, 0, 0);
}
.icon-handshake .path2:before {
  content: "\eb4a";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-head-side-brain .path1:before {
  content: "\eb4b";
  color: rgb(0, 0, 0);
}
.icon-head-side-brain .path2:before {
  content: "\eb4c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-head-side-cough .path1:before {
  content: "\eb4d";
  color: rgb(0, 0, 0);
}
.icon-head-side-cough .path2:before {
  content: "\eb4e";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-head-side-medical .path1:before {
  content: "\eb4f";
  color: rgb(0, 0, 0);
}
.icon-head-side-medical .path2:before {
  content: "\eb50";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-head-side-virus .path1:before {
  content: "\eb51";
  color: rgb(0, 0, 0);
}
.icon-head-side-virus .path2:before {
  content: "\eb52";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-head-side .path1:before {
  content: "\eb53";
  color: rgb(0, 0, 0);
}
.icon-head-side .path2:before {
  content: "\eb54";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-heart-pulse .path1:before {
  content: "\f6ea";
  color: rgb(0, 0, 0);
}
.icon-heart-pulse .path2:before {
  content: "\f6eb";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-heart:before {
  content: "\eb55";
}
.icon-helmet-safety .path1:before {
  content: "\eb56";
  color: rgb(0, 0, 0);
}
.icon-helmet-safety .path2:before {
  content: "\eb57";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hexagon-plus .path1:before {
  content: "\eb58";
  color: rgb(0, 0, 0);
}
.icon-hexagon-plus .path2:before {
  content: "\eb59";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-high-definition .path1:before {
  content: "\eb5a";
  color: rgb(0, 0, 0);
}
.icon-high-definition .path2:before {
  content: "\eb5b";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hospital .path1:before {
  content: "\eb5c";
  color: rgb(0, 0, 0);
}
.icon-hospital .path2:before {
  content: "\eb5d";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hospitals .path1:before {
  content: "\eb5e";
  color: rgb(0, 0, 0);
}
.icon-hospitals .path2:before {
  content: "\eb5f";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hourglass-clock .path1:before {
  content: "\eb60";
  color: rgb(0, 0, 0);
}
.icon-hourglass-clock .path2:before {
  content: "\eb61";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house-building2 .path1:before {
  content: "\eb62";
  color: rgb(0, 0, 0);
}
.icon-house-building2 .path2:before {
  content: "\eb63";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house-chimney-blank .path1:before {
  content: "\eb64";
  color: rgb(0, 0, 0);
}
.icon-house-chimney-blank .path2:before {
  content: "\eb65";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house-chimney-heart .path1:before {
  content: "\eb66";
  color: rgb(0, 0, 0);
}
.icon-house-chimney-heart .path2:before {
  content: "\eb67";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house-chimney-user .path1:before {
  content: "\eb68";
  color: rgb(0, 0, 0);
}
.icon-house-chimney-user .path2:before {
  content: "\eb69";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house-chimney-window .path1:before {
  content: "\eb6a";
  color: rgb(0, 0, 0);
}
.icon-house-chimney-window .path2:before {
  content: "\eb6b";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house-chimney .path1:before {
  content: "\eb6c";
  color: rgb(0, 0, 0);
}
.icon-house-chimney .path2:before {
  content: "\eb6d";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house-circle-check .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}
.icon-house-circle-check .path2:before {
  content: "\e917";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house-fire .path1:before {
  content: "\eb6e";
  color: rgb(0, 0, 0);
}
.icon-house-fire .path2:before {
  content: "\eb6f";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house-heart .path1:before {
  content: "\eb70";
  color: rgb(0, 0, 0);
}
.icon-house-heart .path2:before {
  content: "\eb71";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house-window .path1:before {
  content: "\eb72";
  color: rgb(0, 0, 0);
}
.icon-house-window .path2:before {
  content: "\eb73";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-house .path1:before {
  content: "\eb74";
  color: rgb(0, 0, 0);
}
.icon-house .path2:before {
  content: "\eb75";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-hundred-points .path1:before {
  content: "\f710";
  color: rgb(0, 0, 0);
}
.icon-hundred-points .path2:before {
  content: "\f711";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-id-card .path1:before {
  content: "\eb76";
  color: rgb(0, 0, 0);
}
.icon-id-card .path2:before {
  content: "\eb77";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-image-portrait .path1:before {
  content: "\eb78";
  color: rgb(0, 0, 0);
}
.icon-image-portrait .path2:before {
  content: "\eb79";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-images .path1:before {
  content: "\eb7a";
  color: rgb(0, 0, 0);
}
.icon-images .path2:before {
  content: "\eb7b";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-inbox-in .path1:before {
  content: "\eb7c";
  color: rgb(0, 0, 0);
}
.icon-inbox-in .path2:before {
  content: "\eb7d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-infinity:before {
  content: "\e919";
}
.icon-key-skeleton .path1:before {
  content: "\eb7e";
  color: rgb(0, 0, 0);
}
.icon-key-skeleton .path2:before {
  content: "\eb7f";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-key .path1:before {
  content: "\eb80";
  color: rgb(0, 0, 0);
}
.icon-key .path2:before {
  content: "\eb81";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-kit-medical .path1:before {
  content: "\eb82";
  color: rgb(0, 0, 0);
}
.icon-kit-medical .path2:before {
  content: "\eb83";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-laptop-arrow-down .path1:before {
  content: "\eb84";
  color: rgb(0, 0, 0);
}
.icon-laptop-arrow-down .path2:before {
  content: "\eb85";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-leaf-heart .path1:before {
  content: "\eb86";
  color: rgb(0, 0, 0);
}
.icon-leaf-heart .path2:before {
  content: "\eb87";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-leaf-maple .path1:before {
  content: "\eb88";
  color: rgb(0, 0, 0);
}
.icon-leaf-maple .path2:before {
  content: "\eb89";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-leaf-oak .path1:before {
  content: "\eb8a";
  color: rgb(0, 0, 0);
}
.icon-leaf-oak .path2:before {
  content: "\eb8b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-leaf .path1:before {
  content: "\eb8c";
  color: rgb(0, 0, 0);
}
.icon-leaf .path2:before {
  content: "\eb8d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-left-from-line .path1:before {
  content: "\eb8e";
  color: rgb(0, 0, 0);
}
.icon-left-from-line .path2:before {
  content: "\eb8f";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-left-long-to-line:before {
  content: "\eb90";
}
.icon-left-long .path1:before {
  content: "\eb91";
  color: rgb(0, 0, 0);
}
.icon-left-long .path2:before {
  content: "\eb92";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-left-to-line .path1:before {
  content: "\eb93";
  color: rgb(0, 0, 0);
}
.icon-left-to-line .path2:before {
  content: "\eb94";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-left .path1:before {
  content: "\eb95";
  color: rgb(0, 0, 0);
}
.icon-left .path2:before {
  content: "\eb96";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-lightbulb-dollar .path1:before {
  content: "\eb97";
  color: rgb(0, 0, 0);
}
.icon-lightbulb-dollar .path2:before {
  content: "\eb98";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-lightbulb-exclamation-on .path1:before {
  content: "\eb99";
  color: rgb(0, 0, 0);
}
.icon-lightbulb-exclamation-on .path2:before {
  content: "\eb9a";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-lightbulb-exclamation .path1:before {
  content: "\eb9b";
  color: rgb(0, 0, 0);
}
.icon-lightbulb-exclamation .path2:before {
  content: "\eb9c";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-lightbulb-on .path1:before {
  content: "\eb9d";
  color: rgb(0, 0, 0);
}
.icon-lightbulb-on .path2:before {
  content: "\eb9e";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-lightbulb .path1:before {
  content: "\eb9f";
  color: rgb(0, 0, 0);
}
.icon-lightbulb .path2:before {
  content: "\eba0";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-list-ul .path1:before {
  content: "\eba1";
  color: rgb(0, 0, 0);
}
.icon-list-ul .path2:before {
  content: "\eba2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-location-arrow:before {
  content: "\eba3";
}
.icon-location-check .path1:before {
  content: "\eba4";
  color: rgb(0, 0, 0);
}
.icon-location-check .path2:before {
  content: "\eba5";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-location-dot .path1:before {
  content: "\f658";
  color: rgb(0, 0, 0);
}
.icon-location-dot .path2:before {
  content: "\f659";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-location-exclamation .path1:before {
  content: "\f65a";
  color: rgb(0, 0, 0);
}
.icon-location-exclamation .path2:before {
  content: "\f65b";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-location-pin:before {
  content: "\f65c";
}
.icon-magnifying-glass-dollar .path1:before {
  content: "\eba6";
  color: rgb(0, 0, 0);
}
.icon-magnifying-glass-dollar .path2:before {
  content: "\eba7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-magnifying-glass-location .path1:before {
  content: "\eba8";
  color: rgb(0, 0, 0);
}
.icon-magnifying-glass-location .path2:before {
  content: "\eba9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-magnifying-glass-minus2 .path1:before {
  content: "\f673";
  color: rgb(0, 0, 0);
}
.icon-magnifying-glass-minus2 .path2:before {
  content: "\f674";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-magnifying-glass-plus3 .path1:before {
  content: "\f675";
  color: rgb(0, 0, 0);
}
.icon-magnifying-glass-plus3 .path2:before {
  content: "\f676";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-magnifying-glass .path1:before {
  content: "\ebaa";
  color: rgb(0, 0, 0);
}
.icon-magnifying-glass .path2:before {
  content: "\ebab";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-map-location-dot .path1:before {
  content: "\ebac";
  color: rgb(0, 0, 0);
}
.icon-map-location-dot .path2:before {
  content: "\ebad";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-map-location .path1:before {
  content: "\ebae";
  color: rgb(0, 0, 0);
}
.icon-map-location .path2:before {
  content: "\ebaf";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-map-pin .path1:before {
  content: "\ebb0";
  color: rgb(0, 0, 0);
}
.icon-map-pin .path2:before {
  content: "\ebb1";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-map .path1:before {
  content: "\ebb2";
  color: rgb(0, 0, 0);
}
.icon-map .path2:before {
  content: "\ebb3";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-medal .path1:before {
  content: "\f769";
  color: rgb(0, 0, 0);
}
.icon-medal .path2:before {
  content: "\f76a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-megaphone .path1:before {
  content: "\f75f";
  color: rgb(0, 0, 0);
}
.icon-megaphone .path2:before {
  content: "\f760";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-arrow-down .path1:before {
  content: "\ebb4";
  color: rgb(0, 0, 0);
}
.icon-message-arrow-down .path2:before {
  content: "\ebb5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-arrow-up-right .path1:before {
  content: "\ebb6";
  color: rgb(0, 0, 0);
}
.icon-message-arrow-up-right .path2:before {
  content: "\ebb7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-arrow-up .path1:before {
  content: "\ebb8";
  color: rgb(0, 0, 0);
}
.icon-message-arrow-up .path2:before {
  content: "\ebb9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-check .path1:before {
  content: "\ebba";
  color: rgb(0, 0, 0);
}
.icon-message-check .path2:before {
  content: "\ebbb";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-dollar .path1:before {
  content: "\ebbc";
  color: rgb(0, 0, 0);
}
.icon-message-dollar .path2:before {
  content: "\ebbd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-dots .path1:before {
  content: "\ebbe";
  color: rgb(0, 0, 0);
}
.icon-message-dots .path2:before {
  content: "\ebbf";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-lines .path1:before {
  content: "\f689";
  color: rgb(0, 0, 0);
}
.icon-message-lines .path2:before {
  content: "\f68a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-medical .path1:before {
  content: "\ebc0";
  color: rgb(0, 0, 0);
}
.icon-message-medical .path2:before {
  content: "\ebc1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-plus .path1:before {
  content: "\ebc2";
  color: rgb(0, 0, 0);
}
.icon-message-plus .path2:before {
  content: "\ebc3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-quote .path1:before {
  content: "\ebc4";
  color: rgb(0, 0, 0);
}
.icon-message-quote .path2:before {
  content: "\ebc5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-message-smile .path1:before {
  content: "\ebc6";
  color: rgb(0, 0, 0);
}
.icon-message-smile .path2:before {
  content: "\ebc7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-messages-dollar .path1:before {
  content: "\ebc8";
  color: rgb(0, 0, 0);
}
.icon-messages-dollar .path2:before {
  content: "\ebc9";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-messages .path1:before {
  content: "\ebca";
  color: rgb(0, 0, 0);
}
.icon-messages .path2:before {
  content: "\ebcb";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-meter-bolt .path1:before {
  content: "\ebcc";
  color: rgb(0, 0, 0);
}
.icon-meter-bolt .path2:before {
  content: "\ebcd";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-meter-droplet .path1:before {
  content: "\ebce";
  color: rgb(0, 0, 0);
}
.icon-meter-droplet .path2:before {
  content: "\ebcf";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-meter-fire .path1:before {
  content: "\ebd0";
  color: rgb(0, 0, 0);
}
.icon-meter-fire .path2:before {
  content: "\ebd1";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-meter .path1:before {
  content: "\ebd2";
  color: rgb(0, 0, 0);
}
.icon-meter .path2:before {
  content: "\ebd3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-microphone .path1:before {
  content: "\ebd4";
  color: rgb(0, 0, 0);
}
.icon-microphone .path2:before {
  content: "\ebd5";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-minus:before {
  content: "\f68b";
}
.icon-mobile-button .path1:before {
  content: "\f739";
  color: rgb(0, 0, 0);
}
.icon-mobile-button .path2:before {
  content: "\f73a";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-mobile-screen-button .path1:before {
  content: "\f73b";
  color: rgb(0, 0, 0);
}
.icon-mobile-screen-button .path2:before {
  content: "\f73c";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-mobile-screen .path1:before {
  content: "\f73d";
  color: rgb(0, 0, 0);
}
.icon-mobile-screen .path2:before {
  content: "\f73e";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-mobile .path1:before {
  content: "\ebd6";
  color: rgb(0, 0, 0);
}
.icon-mobile .path2:before {
  content: "\ebd7";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-money-bill-1-wave .path1:before {
  content: "\ebd8";
  color: rgb(0, 0, 0);
}
.icon-money-bill-1-wave .path2:before {
  content: "\ebd9";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-money-bill-1 .path1:before {
  content: "\ebda";
  color: rgb(0, 0, 0);
}
.icon-money-bill-1 .path2:before {
  content: "\ebdb";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-money-bill-simple-wave .path1:before {
  content: "\ebdc";
  color: rgb(0, 0, 0);
}
.icon-money-bill-simple-wave .path2:before {
  content: "\ebdd";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-money-bill-simple .path1:before {
  content: "\ebde";
  color: rgb(0, 0, 0);
}
.icon-money-bill-simple .path2:before {
  content: "\ebdf";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-money-bill-wave .path1:before {
  content: "\ebe0";
  color: rgb(0, 0, 0);
}
.icon-money-bill-wave .path2:before {
  content: "\ebe1";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-money-bill .path1:before {
  content: "\ebe2";
  color: rgb(0, 0, 0);
}
.icon-money-bill .path2:before {
  content: "\ebe3";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-money-check-dollar-pen .path1:before {
  content: "\ebe4";
  color: rgb(0, 0, 0);
}
.icon-money-check-dollar-pen .path2:before {
  content: "\ebe5";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-money-check-dollar .path1:before {
  content: "\ebe6";
  color: rgb(0, 0, 0);
}
.icon-money-check-dollar .path2:before {
  content: "\ebe7";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-money-check-pen .path1:before {
  content: "\ebe8";
  color: rgb(0, 0, 0);
}
.icon-money-check-pen .path2:before {
  content: "\ebe9";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-money-check .path1:before {
  content: "\ebea";
  color: rgb(0, 0, 0);
}
.icon-money-check .path2:before {
  content: "\ebeb";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-octagon-check .path1:before {
  content: "\ebec";
  color: rgb(0, 0, 0);
}
.icon-octagon-check .path2:before {
  content: "\ebed";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-octagon-plus .path1:before {
  content: "\ebee";
  color: rgb(0, 0, 0);
}
.icon-octagon-plus .path2:before {
  content: "\ebef";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-page-caret-down .path1:before {
  content: "\ebf0";
  color: rgb(0, 0, 0);
}
.icon-page-caret-down .path2:before {
  content: "\ebf1";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-page-caret-up .path1:before {
  content: "\ebf2";
  color: rgb(0, 0, 0);
}
.icon-page-caret-up .path2:before {
  content: "\ebf3";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pallet-box .path1:before {
  content: "\ebf4";
  color: rgb(0, 0, 0);
}
.icon-pallet-box .path2:before {
  content: "\ebf5";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pallet-boxes .path1:before {
  content: "\ebf6";
  color: rgb(0, 0, 0);
}
.icon-pallet-boxes .path2:before {
  content: "\ebf7";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-panel-ews .path1:before {
  content: "\ebf8";
  color: rgb(0, 0, 0);
}
.icon-panel-ews .path2:before {
  content: "\ebf9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-panel-fire .path1:before {
  content: "\ebfa";
  color: rgb(0, 0, 0);
}
.icon-panel-fire .path2:before {
  content: "\ebfb";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-paper-plane .path1:before {
  content: "\ebfc";
  color: rgb(0, 0, 0);
}
.icon-paper-plane .path2:before {
  content: "\ebfd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pen-circle .path1:before {
  content: "\ebfe";
  color: rgb(0, 0, 0);
}
.icon-pen-circle .path2:before {
  content: "\ebff";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pen-field .path1:before {
  content: "\f7a0";
  color: rgb(0, 0, 0);
}
.icon-pen-field .path2:before {
  content: "\f7a7";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pen-line .path1:before {
  content: "\ec00";
  color: rgb(0, 0, 0);
}
.icon-pen-line .path2:before {
  content: "\ec01";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pen-nib .path1:before {
  content: "\ec02";
  color: rgb(0, 0, 0);
}
.icon-pen-nib .path2:before {
  content: "\ec03";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pen-paintbrush .path1:before {
  content: "\ec04";
  color: rgb(0, 0, 0);
}
.icon-pen-paintbrush .path2:before {
  content: "\ec05";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pen-ruler .path1:before {
  content: "\ec06";
  color: rgb(0, 0, 0);
}
.icon-pen-ruler .path2:before {
  content: "\ec07";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pen-to-square .path1:before {
  content: "\ec08";
  color: rgb(0, 0, 0);
}
.icon-pen-to-square .path2:before {
  content: "\ec09";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pen .path1:before {
  content: "\ec0a";
  color: rgb(0, 0, 0);
}
.icon-pen .path2:before {
  content: "\ec0b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pencil .path1:before {
  content: "\ec0c";
  color: rgb(0, 0, 0);
}
.icon-pencil .path2:before {
  content: "\ec0d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-people-arrows-left-right .path1:before {
  content: "\ec0e";
  color: rgb(0, 0, 0);
}
.icon-people-arrows-left-right .path2:before {
  content: "\ec0f";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-people-carry-box .path1:before {
  content: "\ec10";
  color: rgb(0, 0, 0);
}
.icon-people-carry-box .path2:before {
  content: "\ec11";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-people-group .path1:before {
  content: "\ec12";
  color: rgb(0, 0, 0);
}
.icon-people-group .path2:before {
  content: "\ec13";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-people-roof2 .path1:before {
  content: "\f76f";
  color: rgb(0, 0, 0);
}
.icon-people-roof2 .path2:before {
  content: "\f770";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-people-simple .path1:before {
  content: "\ec14";
  color: rgb(0, 0, 0);
}
.icon-people-simple .path2:before {
  content: "\ec15";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-person-carry-box .path1:before {
  content: "\ec16";
  color: rgb(0, 0, 0);
}
.icon-person-carry-box .path2:before {
  content: "\ec17";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-person-circle-check .path1:before {
  content: "\ec18";
  color: rgb(0, 0, 0);
}
.icon-person-circle-check .path2:before {
  content: "\ec19";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-person-digging .path1:before {
  content: "\ec1a";
  color: rgb(0, 0, 0);
}
.icon-person-digging .path2:before {
  content: "\ec1b";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-person-dolly-empty .path1:before {
  content: "\ec1c";
  color: rgb(0, 0, 0);
}
.icon-person-dolly-empty .path2:before {
  content: "\ec1d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-person-dolly .path1:before {
  content: "\ec1e";
  color: rgb(0, 0, 0);
}
.icon-person-dolly .path2:before {
  content: "\ec1f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-person-dress-simple .path1:before {
  content: "\ec20";
  color: rgb(0, 0, 0);
}
.icon-person-dress-simple .path2:before {
  content: "\ec21";
  margin-left: -0.5em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-person-running .path1:before {
  content: "\ec22";
  color: rgb(0, 0, 0);
}
.icon-person-running .path2:before {
  content: "\ec23";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-person-to-door .path1:before {
  content: "\ec24";
  color: rgb(0, 0, 0);
}
.icon-person-to-door .path2:before {
  content: "\ec25";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-person-walking .path1:before {
  content: "\ec26";
  color: rgb(0, 0, 0);
}
.icon-person-walking .path2:before {
  content: "\ec27";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-phone-arrow-up-right .path1:before {
  content: "\ec28";
  color: rgb(0, 0, 0);
}
.icon-phone-arrow-up-right .path2:before {
  content: "\ec29";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-phone-flip3 .path1:before {
  content: "\f68c";
  color: rgb(0, 0, 0);
}
.icon-phone-flip3 .path2:before {
  content: "\f68d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-phone-plus .path1:before {
  content: "\ec2a";
  color: rgb(0, 0, 0);
}
.icon-phone-plus .path2:before {
  content: "\ec2b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-phone-rotary .path1:before {
  content: "\f794";
  color: rgb(0, 0, 0);
}
.icon-phone-rotary .path2:before {
  content: "\f795";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-phone-volume .path1:before {
  content: "\f704";
  color: rgb(0, 0, 0);
}
.icon-phone-volume .path2:before {
  content: "\f705";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-phone4 .path1:before {
  content: "\f68e";
  color: rgb(0, 0, 0);
}
.icon-phone4 .path2:before {
  content: "\f68f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-piggy-bank .path1:before {
  content: "\f690";
  color: rgb(0, 0, 0);
}
.icon-piggy-bank .path2:before {
  content: "\f691";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pipe-circle-check .path1:before {
  content: "\ec2c";
  color: rgb(0, 0, 0);
}
.icon-pipe-circle-check .path2:before {
  content: "\ec2d";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pipe-collar .path1:before {
  content: "\ec2e";
  color: rgb(0, 0, 0);
}
.icon-pipe-collar .path2:before {
  content: "\ec2f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pipe-section .path1:before {
  content: "\ec30";
  color: rgb(0, 0, 0);
}
.icon-pipe-section .path2:before {
  content: "\ec31";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-pipe-valve .path1:before {
  content: "\ec32";
  color: rgb(0, 0, 0);
}
.icon-pipe-valve .path2:before {
  content: "\ec33";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-plug-circle-bolt .path1:before {
  content: "\ec34";
  color: rgb(0, 0, 0);
}
.icon-plug-circle-bolt .path2:before {
  content: "\ec35";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-plug-circle-xmark .path1:before {
  content: "\ec36";
  color: rgb(0, 0, 0);
}
.icon-plug-circle-xmark .path2:before {
  content: "\ec37";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-plug .path1:before {
  content: "\ec38";
  color: rgb(0, 0, 0);
}
.icon-plug .path2:before {
  content: "\ec39";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-plus-large1:before {
  content: "\f692";
}
.icon-plus-minus .path1:before {
  content: "\ec3a";
  color: rgb(0, 0, 0);
}
.icon-plus-minus .path2:before {
  content: "\ec3b";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-plus:before {
  content: "\f693";
}
.icon-pump .path1:before {
  content: "\ec3c";
  color: rgb(0, 0, 0);
}
.icon-pump .path2:before {
  content: "\ec3d";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-question .path1:before {
  content: "\ec3e";
  color: rgb(0, 0, 0);
}
.icon-question .path2:before {
  content: "\ec3f";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-quote-left .path1:before {
  content: "\ec40";
  color: rgb(0, 0, 0);
}
.icon-quote-left .path2:before {
  content: "\ec41";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-quote-right .path1:before {
  content: "\ec42";
  color: rgb(0, 0, 0);
}
.icon-quote-right .path2:before {
  content: "\ec43";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-quotes .path1:before {
  content: "\ec44";
  color: rgb(0, 0, 0);
}
.icon-quotes .path2:before {
  content: "\ec45";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-raindrops4 .path1:before {
  content: "\e91c";
  color: rgb(0, 0, 0);
}
.icon-raindrops4 .path2:before {
  content: "\f781";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-rectangle-ad .path1:before {
  content: "\ec46";
  color: rgb(0, 0, 0);
}
.icon-rectangle-ad .path2:before {
  content: "\ec47";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-rectangle-list .path1:before {
  content: "\f74b";
  color: rgb(0, 0, 0);
}
.icon-rectangle-list .path2:before {
  content: "\f74c";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-right-from-line .path1:before {
  content: "\ec48";
  color: rgb(0, 0, 0);
}
.icon-right-from-line .path2:before {
  content: "\ec49";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-right-long-to-line:before {
  content: "\ec4a";
}
.icon-right-to-bracket .path1:before {
  content: "\ec4b";
  color: rgb(0, 0, 0);
}
.icon-right-to-bracket .path2:before {
  content: "\ec4c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-right-to-line .path1:before {
  content: "\ec4d";
  color: rgb(0, 0, 0);
}
.icon-right-to-line .path2:before {
  content: "\ec4e";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-right1 .path1:before {
  content: "\f712";
  color: rgb(0, 0, 0);
}
.icon-right1 .path2:before {
  content: "\f713";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-route .path1:before {
  content: "\ec4f";
  color: rgb(0, 0, 0);
}
.icon-route .path2:before {
  content: "\ec50";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-sack-dollar .path1:before {
  content: "\ec51";
  color: rgb(0, 0, 0);
}
.icon-sack-dollar .path2:before {
  content: "\ec52";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-sack .path1:before {
  content: "\ec53";
  color: rgb(0, 0, 0);
}
.icon-sack .path2:before {
  content: "\ec54";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-scale-balanced .path1:before {
  content: "\ec55";
  color: rgb(0, 0, 0);
}
.icon-scale-balanced .path2:before {
  content: "\ec56";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-scale-unbalanced-flip .path1:before {
  content: "\ec57";
  color: rgb(0, 0, 0);
}
.icon-scale-unbalanced-flip .path2:before {
  content: "\ec58";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-scale-unbalanced .path1:before {
  content: "\ec59";
  color: rgb(0, 0, 0);
}
.icon-scale-unbalanced .path2:before {
  content: "\ec5a";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-school-flag .path1:before {
  content: "\ec5b";
  color: rgb(0, 0, 0);
}
.icon-school-flag .path2:before {
  content: "\ec5c";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-school .path1:before {
  content: "\ec5d";
  color: rgb(0, 0, 0);
}
.icon-school .path2:before {
  content: "\ec5e";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-screwdriver-wrench .path1:before {
  content: "\ec5f";
  color: rgb(0, 0, 0);
}
.icon-screwdriver-wrench .path2:before {
  content: "\ec60";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-screwdriver .path1:before {
  content: "\ec61";
  color: rgb(0, 0, 0);
}
.icon-screwdriver .path2:before {
  content: "\ec62";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-seal-exclamation .path1:before {
  content: "\ec63";
  color: rgb(0, 0, 0);
}
.icon-seal-exclamation .path2:before {
  content: "\ec64";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-sensor-cloud .path1:before {
  content: "\ec65";
  color: rgb(0, 0, 0);
}
.icon-sensor-cloud .path2:before {
  content: "\ec66";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-sensor-fire .path1:before {
  content: "\ec67";
  color: rgb(0, 0, 0);
}
.icon-sensor-fire .path2:before {
  content: "\ec68";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-sensor-on .path1:before {
  content: "\ec69";
  color: rgb(0, 0, 0);
}
.icon-sensor-on .path2:before {
  content: "\ec6a";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-sensor-triangle-exclamation .path1:before {
  content: "\ec6b";
  color: rgb(0, 0, 0);
}
.icon-sensor-triangle-exclamation .path2:before {
  content: "\ec6c";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-sensor .path1:before {
  content: "\ec6d";
  color: rgb(0, 0, 0);
}
.icon-sensor .path2:before {
  content: "\ec6e";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-share-from-square .path1:before {
  content: "\ec6f";
  color: rgb(0, 0, 0);
}
.icon-share-from-square .path2:before {
  content: "\ec70";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-shield-check .path1:before {
  content: "\ec71";
  color: rgb(0, 0, 0);
}
.icon-shield-check .path2:before {
  content: "\ec72";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-shield-halved .path1:before {
  content: "\ec73";
  color: rgb(0, 0, 0);
}
.icon-shield-halved .path2:before {
  content: "\ec74";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-shield-plus .path1:before {
  content: "\ec75";
  color: rgb(0, 0, 0);
}
.icon-shield-plus .path2:before {
  content: "\ec76";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-shield-quartered .path1:before {
  content: "\ec77";
  color: rgb(0, 0, 0);
}
.icon-shield-quartered .path2:before {
  content: "\ec78";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-shield-virus .path1:before {
  content: "\ec79";
  color: rgb(0, 0, 0);
}
.icon-shield-virus .path2:before {
  content: "\ec7a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-shield-xmark .path1:before {
  content: "\ec7b";
  color: rgb(0, 0, 0);
}
.icon-shield-xmark .path2:before {
  content: "\ec7c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-shield:before {
  content: "\ec7d";
}
.icon-shovel .path1:before {
  content: "\ec7e";
  color: rgb(0, 0, 0);
}
.icon-shovel .path2:before {
  content: "\ec7f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-shower-down .path1:before {
  content: "\ec80";
  color: rgb(0, 0, 0);
}
.icon-shower-down .path2:before {
  content: "\ec81";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-shower .path1:before {
  content: "\ec82";
  color: rgb(0, 0, 0);
}
.icon-shower .path2:before {
  content: "\ec83";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-sink .path1:before {
  content: "\ec84";
  color: rgb(0, 0, 0);
}
.icon-sink .path2:before {
  content: "\ec85";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-snow-blowing .path1:before {
  content: "\ec86";
  color: rgb(0, 0, 0);
}
.icon-snow-blowing .path2:before {
  content: "\ec87";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-snowflake .path1:before {
  content: "\ec88";
  color: rgb(0, 0, 0);
}
.icon-snowflake .path2:before {
  content: "\ec89";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-snowflakes .path1:before {
  content: "\ec8a";
  color: rgb(0, 0, 0);
}
.icon-snowflakes .path2:before {
  content: "\ec8b";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-snowplow .path1:before {
  content: "\ec8c";
  color: rgb(0, 0, 0);
}
.icon-snowplow .path2:before {
  content: "\ec8d";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-soap .path1:before {
  content: "\ec8e";
  color: rgb(0, 0, 0);
}
.icon-soap .path2:before {
  content: "\ec8f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-sparkles1 .path1:before {
  content: "\f65d";
  color: rgb(0, 0, 0);
}
.icon-sparkles1 .path2:before {
  content: "\f65e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-splotch:before {
  content: "\ec90";
}
.icon-square-arrow-down-left .path1:before {
  content: "\ec91";
  color: rgb(0, 0, 0);
}
.icon-square-arrow-down-left .path2:before {
  content: "\ec92";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-arrow-down-right .path1:before {
  content: "\ec93";
  color: rgb(0, 0, 0);
}
.icon-square-arrow-down-right .path2:before {
  content: "\ec94";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-arrow-down .path1:before {
  content: "\ec95";
  color: rgb(0, 0, 0);
}
.icon-square-arrow-down .path2:before {
  content: "\ec96";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-arrow-left .path1:before {
  content: "\ec97";
  color: rgb(0, 0, 0);
}
.icon-square-arrow-left .path2:before {
  content: "\ec98";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-arrow-right .path1:before {
  content: "\ec99";
  color: rgb(0, 0, 0);
}
.icon-square-arrow-right .path2:before {
  content: "\ec9a";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-arrow-up-left .path1:before {
  content: "\ec9b";
  color: rgb(0, 0, 0);
}
.icon-square-arrow-up-left .path2:before {
  content: "\ec9c";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-arrow-up-right .path1:before {
  content: "\ec9d";
  color: rgb(0, 0, 0);
}
.icon-square-arrow-up-right .path2:before {
  content: "\ec9e";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-arrow-up .path1:before {
  content: "\ec9f";
  color: rgb(0, 0, 0);
}
.icon-square-arrow-up .path2:before {
  content: "\eca0";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-caret-down .path1:before {
  content: "\eca1";
  color: rgb(0, 0, 0);
}
.icon-square-caret-down .path2:before {
  content: "\eca2";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-caret-left .path1:before {
  content: "\eca3";
  color: rgb(0, 0, 0);
}
.icon-square-caret-left .path2:before {
  content: "\eca4";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-caret-right .path1:before {
  content: "\eca5";
  color: rgb(0, 0, 0);
}
.icon-square-caret-right .path2:before {
  content: "\eca6";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-caret-up .path1:before {
  content: "\eca7";
  color: rgb(0, 0, 0);
}
.icon-square-caret-up .path2:before {
  content: "\eca8";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-check .path1:before {
  content: "\eca9";
  color: rgb(0, 0, 0);
}
.icon-square-check .path2:before {
  content: "\ecaa";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-chevron-down .path1:before {
  content: "\ecab";
  color: rgb(0, 0, 0);
}
.icon-square-chevron-down .path2:before {
  content: "\ecac";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-chevron-left .path1:before {
  content: "\ecad";
  color: rgb(0, 0, 0);
}
.icon-square-chevron-left .path2:before {
  content: "\ecae";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-chevron-right .path1:before {
  content: "\ecaf";
  color: rgb(0, 0, 0);
}
.icon-square-chevron-right .path2:before {
  content: "\ecb0";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-chevron-up .path1:before {
  content: "\ecb1";
  color: rgb(0, 0, 0);
}
.icon-square-chevron-up .path2:before {
  content: "\ecb2";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-dollar .path1:before {
  content: "\ecb3";
  color: rgb(0, 0, 0);
}
.icon-square-dollar .path2:before {
  content: "\ecb4";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-down-left .path1:before {
  content: "\ecb5";
  color: rgb(0, 0, 0);
}
.icon-square-down-left .path2:before {
  content: "\ecb6";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-down-right .path1:before {
  content: "\ecb7";
  color: rgb(0, 0, 0);
}
.icon-square-down-right .path2:before {
  content: "\ecb8";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-down .path1:before {
  content: "\ecb9";
  color: rgb(0, 0, 0);
}
.icon-square-down .path2:before {
  content: "\ecba";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-envelope .path1:before {
  content: "\ecbb";
  color: rgb(0, 0, 0);
}
.icon-square-envelope .path2:before {
  content: "\ecbc";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-exclamation .path1:before {
  content: "\ecbd";
  color: rgb(0, 0, 0);
}
.icon-square-exclamation .path2:before {
  content: "\ecbe";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-left .path1:before {
  content: "\ecbf";
  color: rgb(0, 0, 0);
}
.icon-square-left .path2:before {
  content: "\ecc0";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-minus .path1:before {
  content: "\f694";
  color: rgb(0, 0, 0);
}
.icon-square-minus .path2:before {
  content: "\f695";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-pen .path1:before {
  content: "\ecc1";
  color: rgb(0, 0, 0);
}
.icon-square-pen .path2:before {
  content: "\ecc2";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-phone .path1:before {
  content: "\ecc3";
  color: rgb(0, 0, 0);
}
.icon-square-phone .path2:before {
  content: "\ecc4";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-plus2 .path1:before {
  content: "\f696";
  color: rgb(0, 0, 0);
}
.icon-square-plus2 .path2:before {
  content: "\f697";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-quote .path1:before {
  content: "\ecc5";
  color: rgb(0, 0, 0);
}
.icon-square-quote .path2:before {
  content: "\ecc6";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-up-left .path1:before {
  content: "\ecc7";
  color: rgb(0, 0, 0);
}
.icon-square-up-left .path2:before {
  content: "\ecc8";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-square-up-right .path1:before {
  content: "\ecc9";
  color: rgb(0, 0, 0);
}
.icon-square-up-right .path2:before {
  content: "\ecca";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-star-christmas .path1:before {
  content: "\eccb";
  color: rgb(0, 0, 0);
}
.icon-star-christmas .path2:before {
  content: "\eccc";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-star-half .path1:before {
  content: "\eccd";
  color: rgb(0, 0, 0);
}
.icon-star-half .path2:before {
  content: "\ecce";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-star-sharp:before {
  content: "\f698";
}
.icon-star-shooting .path1:before {
  content: "\eccf";
  color: rgb(0, 0, 0);
}
.icon-star-shooting .path2:before {
  content: "\ecd0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-star:before {
  content: "\f699";
}
.icon-stars .path1:before {
  content: "\ecd1";
  color: rgb(0, 0, 0);
}
.icon-stars .path2:before {
  content: "\ecd2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-stopwatch .path1:before {
  content: "\ecd3";
  color: rgb(0, 0, 0);
}
.icon-stopwatch .path2:before {
  content: "\ecd4";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-suitcase-medical .path1:before {
  content: "\ecd5";
  color: rgb(0, 0, 0);
}
.icon-suitcase-medical .path2:before {
  content: "\ecd6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-suitcase-rolling .path1:before {
  content: "\ecd7";
  color: rgb(0, 0, 0);
}
.icon-suitcase-rolling .path2:before {
  content: "\ecd8";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-suitcase .path1:before {
  content: "\ecd9";
  color: rgb(0, 0, 0);
}
.icon-suitcase .path2:before {
  content: "\ecda";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-tag:before {
  content: "\ecdb";
}
.icon-tags .path1:before {
  content: "\ecdc";
  color: rgb(0, 0, 0);
}
.icon-tags .path2:before {
  content: "\ecdd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-tank-water .path1:before {
  content: "\ecde";
  color: rgb(0, 0, 0);
}
.icon-tank-water .path2:before {
  content: "\ecdf";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-taxi .path1:before {
  content: "\ece0";
  color: rgb(0, 0, 0);
}
.icon-taxi .path2:before {
  content: "\ece1";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-arrow-down .path1:before {
  content: "\ece2";
  color: rgb(0, 0, 0);
}
.icon-temperature-arrow-down .path2:before {
  content: "\ece3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-arrow-up .path1:before {
  content: "\ece4";
  color: rgb(0, 0, 0);
}
.icon-temperature-arrow-up .path2:before {
  content: "\ece5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-empty .path1:before {
  content: "\ece6";
  color: rgb(0, 0, 0);
}
.icon-temperature-empty .path2:before {
  content: "\ece7";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-full .path1:before {
  content: "\ece8";
  color: rgb(0, 0, 0);
}
.icon-temperature-full .path2:before {
  content: "\ece9";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-half .path1:before {
  content: "\ecea";
  color: rgb(0, 0, 0);
}
.icon-temperature-half .path2:before {
  content: "\eceb";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-high .path1:before {
  content: "\ecec";
  color: rgb(0, 0, 0);
}
.icon-temperature-high .path2:before {
  content: "\eced";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-list .path1:before {
  content: "\ecee";
  color: rgb(0, 0, 0);
}
.icon-temperature-list .path2:before {
  content: "\ecef";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-low .path1:before {
  content: "\ecf0";
  color: rgb(0, 0, 0);
}
.icon-temperature-low .path2:before {
  content: "\ecf1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-quarter .path1:before {
  content: "\ecf2";
  color: rgb(0, 0, 0);
}
.icon-temperature-quarter .path2:before {
  content: "\ecf3";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-snow .path1:before {
  content: "\ecf4";
  color: rgb(0, 0, 0);
}
.icon-temperature-snow .path2:before {
  content: "\ecf5";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-sun .path1:before {
  content: "\ecf6";
  color: rgb(0, 0, 0);
}
.icon-temperature-sun .path2:before {
  content: "\ecf7";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-temperature-three-quarters .path1:before {
  content: "\ecf8";
  color: rgb(0, 0, 0);
}
.icon-temperature-three-quarters .path2:before {
  content: "\ecf9";
  margin-left: -0.625em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-thumbs-down .path1:before {
  content: "\ecfa";
  color: rgb(0, 0, 0);
}
.icon-thumbs-down .path2:before {
  content: "\ecfb";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-thumbs-up .path1:before {
  content: "\ecfc";
  color: rgb(0, 0, 0);
}
.icon-thumbs-up .path2:before {
  content: "\ecfd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-ticket3 .path1:before {
  content: "\f6e0";
  color: rgb(0, 0, 0);
}
.icon-ticket3 .path2:before {
  content: "\f6e1";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-timer .path1:before {
  content: "\ecfe";
  color: rgb(0, 0, 0);
}
.icon-timer .path2:before {
  content: "\ecff";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-toilet .path1:before {
  content: "\ed00";
  color: rgb(0, 0, 0);
}
.icon-toilet .path2:before {
  content: "\ed01";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-toolbox .path1:before {
  content: "\ed02";
  color: rgb(0, 0, 0);
}
.icon-toolbox .path2:before {
  content: "\ed03";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-tooth:before {
  content: "\f69a";
}
.icon-train-track .path1:before {
  content: "\ed04";
  color: rgb(0, 0, 0);
}
.icon-train-track .path2:before {
  content: "\ed05";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-trash-can-check .path1:before {
  content: "\ed06";
  color: rgb(0, 0, 0);
}
.icon-trash-can-check .path2:before {
  content: "\ed07";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-trash-check .path1:before {
  content: "\ed08";
  color: rgb(0, 0, 0);
}
.icon-trash-check .path2:before {
  content: "\ed09";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-trash-plus .path1:before {
  content: "\ed0a";
  color: rgb(0, 0, 0);
}
.icon-trash-plus .path2:before {
  content: "\ed0b";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-treasure-chest .path1:before {
  content: "\ed0c";
  color: rgb(0, 0, 0);
}
.icon-treasure-chest .path2:before {
  content: "\ed0d";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-triangle-exclamation .path1:before {
  content: "\ed0e";
  color: rgb(0, 0, 0);
}
.icon-triangle-exclamation .path2:before {
  content: "\ed0f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-triangle-person-digging .path1:before {
  content: "\ed10";
  color: rgb(0, 0, 0);
}
.icon-triangle-person-digging .path2:before {
  content: "\ed11";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-trophy-star .path1:before {
  content: "\ed12";
  color: rgb(0, 0, 0);
}
.icon-trophy-star .path2:before {
  content: "\ed13";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-trophy .path1:before {
  content: "\ed14";
  color: rgb(0, 0, 0);
}
.icon-trophy .path2:before {
  content: "\ed15";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck-clock .path1:before {
  content: "\ed16";
  color: rgb(0, 0, 0);
}
.icon-truck-clock .path2:before {
  content: "\ed17";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck-fast .path1:before {
  content: "\ed18";
  color: rgb(0, 0, 0);
}
.icon-truck-fast .path2:before {
  content: "\ed19";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck-field .path1:before {
  content: "\ed1a";
  color: rgb(0, 0, 0);
}
.icon-truck-field .path2:before {
  content: "\ed1b";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck-flatbed .path1:before {
  content: "\ed1c";
  color: rgb(0, 0, 0);
}
.icon-truck-flatbed .path2:before {
  content: "\ed1d";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck-front .path1:before {
  content: "\ed1e";
  color: rgb(0, 0, 0);
}
.icon-truck-front .path2:before {
  content: "\ed1f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck-medical .path1:before {
  content: "\ed20";
  color: rgb(0, 0, 0);
}
.icon-truck-medical .path2:before {
  content: "\ed21";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck-moving .path1:before {
  content: "\ed22";
  color: rgb(0, 0, 0);
}
.icon-truck-moving .path2:before {
  content: "\ed23";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck-plow .path1:before {
  content: "\ed24";
  color: rgb(0, 0, 0);
}
.icon-truck-plow .path2:before {
  content: "\ed25";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck-ramp-box .path1:before {
  content: "\ed26";
  color: rgb(0, 0, 0);
}
.icon-truck-ramp-box .path2:before {
  content: "\ed27";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck-ramp .path1:before {
  content: "\ed28";
  color: rgb(0, 0, 0);
}
.icon-truck-ramp .path2:before {
  content: "\ed29";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-truck .path1:before {
  content: "\ed2a";
  color: rgb(0, 0, 0);
}
.icon-truck .path2:before {
  content: "\ed2b";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-tty-answer .path1:before {
  content: "\ed2c";
  color: rgb(0, 0, 0);
}
.icon-tty-answer .path2:before {
  content: "\ed2d";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-turn-down-left .path1:before {
  content: "\ed2e";
  color: rgb(0, 0, 0);
}
.icon-turn-down-left .path2:before {
  content: "\ed2f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-turn-down-right .path1:before {
  content: "\ed30";
  color: rgb(0, 0, 0);
}
.icon-turn-down-right .path2:before {
  content: "\ed31";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-turn-down .path1:before {
  content: "\ed32";
  color: rgb(0, 0, 0);
}
.icon-turn-down .path2:before {
  content: "\ed33";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-turn-up .path1:before {
  content: "\ed34";
  color: rgb(0, 0, 0);
}
.icon-turn-up .path2:before {
  content: "\ed35";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-up-left .path1:before {
  content: "\ed36";
  color: rgb(0, 0, 0);
}
.icon-up-left .path2:before {
  content: "\ed37";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-up-right-from-square .path1:before {
  content: "\ed38";
  color: rgb(0, 0, 0);
}
.icon-up-right-from-square .path2:before {
  content: "\ed39";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-up-right .path1:before {
  content: "\ed3a";
  color: rgb(0, 0, 0);
}
.icon-up-right .path2:before {
  content: "\ed3b";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-up-to-line .path1:before {
  content: "\ed3c";
  color: rgb(0, 0, 0);
}
.icon-up-to-line .path2:before {
  content: "\ed3d";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-check .path1:before {
  content: "\ed3e";
  color: rgb(0, 0, 0);
}
.icon-user-check .path2:before {
  content: "\ed3f";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-clock .path1:before {
  content: "\ed40";
  color: rgb(0, 0, 0);
}
.icon-user-clock .path2:before {
  content: "\ed41";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-doctor-message .path1:before {
  content: "\ed42";
  color: rgb(0, 0, 0);
}
.icon-user-doctor-message .path2:before {
  content: "\ed43";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-doctor .path1:before {
  content: "\ed44";
  color: rgb(0, 0, 0);
}
.icon-user-doctor .path2:before {
  content: "\ed45";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-group .path1:before {
  content: "\ed46";
  color: rgb(0, 0, 0);
}
.icon-user-group .path2:before {
  content: "\ed47";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-hair-long .path1:before {
  content: "\ed48";
  color: rgb(0, 0, 0);
}
.icon-user-hair-long .path2:before {
  content: "\ed49";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-headset .path1:before {
  content: "\f74d";
  color: rgb(0, 0, 0);
}
.icon-user-headset .path2:before {
  content: "\f74e";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-helmet-safety .path1:before {
  content: "\ed4a";
  color: rgb(0, 0, 0);
}
.icon-user-helmet-safety .path2:before {
  content: "\ed4b";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-large .path1:before {
  content: "\ed4c";
  color: rgb(0, 0, 0);
}
.icon-user-large .path2:before {
  content: "\ed4d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-pen .path1:before {
  content: "\ed4e";
  color: rgb(0, 0, 0);
}
.icon-user-pen .path2:before {
  content: "\ed4f";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-plus .path1:before {
  content: "\ed50";
  color: rgb(0, 0, 0);
}
.icon-user-plus .path2:before {
  content: "\ed51";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-robot-xmarks .path1:before {
  content: "\ed52";
  color: rgb(0, 0, 0);
}
.icon-user-robot-xmarks .path2:before {
  content: "\ed53";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-robot .path1:before {
  content: "\ed54";
  color: rgb(0, 0, 0);
}
.icon-user-robot .path2:before {
  content: "\ed55";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-shield .path1:before {
  content: "\ed56";
  color: rgb(0, 0, 0);
}
.icon-user-shield .path2:before {
  content: "\ed57";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-tag .path1:before {
  content: "\ed58";
  color: rgb(0, 0, 0);
}
.icon-user-tag .path2:before {
  content: "\ed59";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user-tie .path1:before {
  content: "\ed5a";
  color: rgb(0, 0, 0);
}
.icon-user-tie .path2:before {
  content: "\ed5b";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-user .path1:before {
  content: "\ed5c";
  color: rgb(0, 0, 0);
}
.icon-user .path2:before {
  content: "\ed5d";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-users-between-lines .path1:before {
  content: "\ed5e";
  color: rgb(0, 0, 0);
}
.icon-users-between-lines .path2:before {
  content: "\ed5f";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-users .path1:before {
  content: "\ed60";
  color: rgb(0, 0, 0);
}
.icon-users .path2:before {
  content: "\ed61";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-van-shuttle .path1:before {
  content: "\ed62";
  color: rgb(0, 0, 0);
}
.icon-van-shuttle .path2:before {
  content: "\ed63";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-video .path1:before {
  content: "\ed64";
  color: rgb(0, 0, 0);
}
.icon-video .path2:before {
  content: "\ed65";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-viruses .path1:before {
  content: "\ed66";
  color: rgb(0, 0, 0);
}
.icon-viruses .path2:before {
  content: "\ed67";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-volume-high .path1:before {
  content: "\ed68";
  color: rgb(0, 0, 0);
}
.icon-volume-high .path2:before {
  content: "\ed69";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-volume-low .path1:before {
  content: "\ed6a";
  color: rgb(0, 0, 0);
}
.icon-volume-low .path2:before {
  content: "\ed6b";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-volume-off:before {
  content: "\ed6c";
}
.icon-volume-slash .path1:before {
  content: "\ed6d";
  color: rgb(0, 0, 0);
}
.icon-volume-slash .path2:before {
  content: "\ed6e";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-volume-xmark .path1:before {
  content: "\ed6f";
  color: rgb(0, 0, 0);
}
.icon-volume-xmark .path2:before {
  content: "\ed70";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-volume .path1:before {
  content: "\ed71";
  color: rgb(0, 0, 0);
}
.icon-volume .path2:before {
  content: "\ed72";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-wallet .path1:before {
  content: "\ed73";
  color: rgb(0, 0, 0);
}
.icon-wallet .path2:before {
  content: "\ed74";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-warehouse-full .path1:before {
  content: "\ed75";
  color: rgb(0, 0, 0);
}
.icon-warehouse-full .path2:before {
  content: "\ed76";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-warehouse .path1:before {
  content: "\ed77";
  color: rgb(0, 0, 0);
}
.icon-warehouse .path2:before {
  content: "\ed78";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-water .path1:before {
  content: "\f72b";
  color: rgb(0, 0, 0);
}
.icon-water .path2:before {
  content: "\f72c";
  margin-left: -1.125em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-wheelchair-move .path1:before {
  content: "\ed79";
  color: rgb(0, 0, 0);
}
.icon-wheelchair-move .path2:before {
  content: "\ed7a";
  margin-left: -0.875em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-wind-warning .path1:before {
  content: "\ed7b";
  color: rgb(0, 0, 0);
}
.icon-wind-warning .path2:before {
  content: "\ed7c";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-wind .path1:before {
  content: "\ed7d";
  color: rgb(0, 0, 0);
}
.icon-wind .path2:before {
  content: "\ed7e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-window-flip .path1:before {
  content: "\ed7f";
  color: rgb(0, 0, 0);
}
.icon-window-flip .path2:before {
  content: "\ed80";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-window-frame-open .path1:before {
  content: "\ed81";
  color: rgb(0, 0, 0);
}
.icon-window-frame-open .path2:before {
  content: "\ed82";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-window-frame .path1:before {
  content: "\ed83";
  color: rgb(0, 0, 0);
}
.icon-window-frame .path2:before {
  content: "\ed84";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-window-minimize:before {
  content: "\f69b";
}
.icon-window .path1:before {
  content: "\ed85";
  color: rgb(0, 0, 0);
}
.icon-window .path2:before {
  content: "\ed86";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-wrench .path1:before {
  content: "\ed87";
  color: rgb(0, 0, 0);
}
.icon-wrench .path2:before {
  content: "\ed88";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-x4:before {
  content: "\f65f";
}
.icon-abacus:before {
  content: "\f79f";
}
.icon-address-card4:before {
  content: "\f424";
}
.icon-air-conditioner4:before {
  content: "\f425";
}
.icon-alarm-clock4:before {
  content: "\f426";
}
.icon-alarm-exclamation4:before {
  content: "\f427";
}
.icon-alt:before {
  content: "\f6cc";
}
.icon-angle-down4:before {
  content: "\f428";
}
.icon-angle-left4:before {
  content: "\f429";
}
.icon-angle-right4:before {
  content: "\f42a";
}
.icon-angle-up4:before {
  content: "\f42b";
}
.icon-angles-down4:before {
  content: "\f42c";
}
.icon-angles-left4:before {
  content: "\f42d";
}
.icon-angles-right4:before {
  content: "\f42e";
}
.icon-angles-up4:before {
  content: "\f42f";
}
.icon-apple-whole4:before {
  content: "\f430";
}
.icon-archway4:before {
  content: "\f431";
}
.icon-arrow-down-arrow-up4:before {
  content: "\f432";
}
.icon-arrow-down-from-line4:before {
  content: "\f433";
}
.icon-arrow-down-left-and-arrow-up-right-to-center4:before {
  content: "\f434";
}
.icon-arrow-down-left4:before {
  content: "\f435";
}
.icon-arrow-down-long4:before {
  content: "\f436";
}
.icon-arrow-down-right4:before {
  content: "\f437";
}
.icon-arrow-down-to-bracket4:before {
  content: "\f438";
}
.icon-arrow-down-to-line4:before {
  content: "\f439";
}
.icon-arrow-down-to-square4:before {
  content: "\f43a";
}
.icon-arrow-down4:before {
  content: "\f43b";
}
.icon-arrow-left-from-line4:before {
  content: "\f43c";
}
.icon-arrow-left-long-to-line4:before {
  content: "\f43d";
}
.icon-arrow-left-long4:before {
  content: "\f43e";
}
.icon-arrow-left-to-line4:before {
  content: "\f43f";
}
.icon-arrow-left4:before {
  content: "\f440";
}
.icon-arrow-right-arrow-left4:before {
  content: "\f441";
}
.icon-arrow-right-from-line4:before {
  content: "\f442";
}
.icon-arrow-right-long-to-line4:before {
  content: "\f443";
}
.icon-arrow-right-long4:before {
  content: "\f444";
}
.icon-arrow-right-to-line4:before {
  content: "\f445";
}
.icon-arrow-right4:before {
  content: "\f446";
}
.icon-arrow-turn-down-left4:before {
  content: "\f447";
}
.icon-arrow-turn-down-right4:before {
  content: "\f448";
}
.icon-arrow-turn-down4:before {
  content: "\f449";
}
.icon-arrow-turn-up4:before {
  content: "\f44a";
}
.icon-arrow-up-from-line4:before {
  content: "\f44b";
}
.icon-arrow-up-left4:before {
  content: "\f44c";
}
.icon-arrow-up-long4:before {
  content: "\f44d";
}
.icon-arrow-up-right4:before {
  content: "\f44e";
}
.icon-arrow-up-to-line4:before {
  content: "\f44f";
}
.icon-arrow-up4:before {
  content: "\f450";
}
.icon-arrows-left-right4:before {
  content: "\f451";
}
.icon-arrows-maximize4:before {
  content: "\f452";
}
.icon-arrows-repeat4:before {
  content: "\f453";
}
.icon-arrows-retweet4:before {
  content: "\f454";
}
.icon-audio-description4:before {
  content: "\f455";
}
.icon-award-simple4:before {
  content: "\f456";
}
.icon-award4:before {
  content: "\f457";
}
.icon-badge-check4:before {
  content: "\f458";
}
.icon-badge-dollar4:before {
  content: "\f459";
}
.icon-badge-percent4:before {
  content: "\f45a";
}
.icon-badge4:before {
  content: "\f45b";
}
.icon-ballot-check4:before {
  content: "\f45c";
}
.icon-ballot4:before {
  content: "\f45d";
}
.icon-bars4:before {
  content: "\f45e";
}
.icon-bed-pulse2:before {
  content: "\f6cd";
}
.icon-bell-on4:before {
  content: "\f45f";
}
.icon-bell4:before {
  content: "\f460";
}
.icon-blanket4:before {
  content: "\f461";
}
.icon-blender-phone1:before {
  content: "\f6ce";
}
.icon-bolt-lightning1:before {
  content: "\f714";
}
.icon-bolt1:before {
  content: "\f715";
}
.icon-book-blank4:before {
  content: "\f462";
}
.icon-book-open-cover4:before {
  content: "\f463";
}
.icon-book-open-reader4:before {
  content: "\f464";
}
.icon-books4:before {
  content: "\f465";
}
.icon-box-check4:before {
  content: "\f466";
}
.icon-box-dollar4:before {
  content: "\f467";
}
.icon-box-heart4:before {
  content: "\f468";
}
.icon-box-open4:before {
  content: "\f469";
}
.icon-box-taped4:before {
  content: "\f46a";
}
.icon-box4:before {
  content: "\f46b";
}
.icon-brain4:before {
  content: "\f46c";
}
.icon-brake-warning4:before {
  content: "\f46d";
}
.icon-briefcase-blank4:before {
  content: "\f46e";
}
.icon-briefcase-medical4:before {
  content: "\f46f";
}
.icon-briefcase4:before {
  content: "\f470";
}
.icon-brightness4:before {
  content: "\f471";
}
.icon-broom-ball4:before {
  content: "\f472";
}
.icon-broom4:before {
  content: "\f473";
}
.icon-browser4:before {
  content: "\f474";
}
.icon-browsers4:before {
  content: "\f475";
}
.icon-building-circle-check4:before {
  content: "\f476";
}
.icon-building-columns4:before {
  content: "\f6f8";
}
.icon-building-shield4:before {
  content: "\f477";
}
.icon-building-user4:before {
  content: "\f478";
}
.icon-building4:before {
  content: "\f479";
}
.icon-buildings4:before {
  content: "\f47a";
}
.icon-bullhorn1:before {
  content: "\f761";
}
.icon-business-time4:before {
  content: "\f47b";
}
.icon-cabinet-filing4:before {
  content: "\f47c";
}
.icon-calculator-simple4:before {
  content: "\f47d";
}
.icon-calendar-check4:before {
  content: "\f47e";
}
.icon-calendar-circle-exclamation4:before {
  content: "\f47f";
}
.icon-calendar-circle-user4:before {
  content: "\f480";
}
.icon-calendar-clock4:before {
  content: "\f481";
}
.icon-calendar-days4:before {
  content: "\f482";
}
.icon-calendar-lines-pen4:before {
  content: "\f483";
}
.icon-calendar-pen4:before {
  content: "\f484";
}
.icon-calendar-plus1:before {
  content: "\f706";
}
.icon-calendar-star4:before {
  content: "\f485";
}
.icon-calendar-xmark4:before {
  content: "\f486";
}
.icon-calendar4:before {
  content: "\f487";
}
.icon-caret-down4:before {
  content: "\f488";
}
.icon-caret-left4:before {
  content: "\f489";
}
.icon-caret-right4:before {
  content: "\f48a";
}
.icon-caret-up4:before {
  content: "\f48b";
}
.icon-cart-arrow-down4:before {
  content: "\f48c";
}
.icon-cart-arrow-up4:before {
  content: "\f48d";
}
.icon-cart-circle-arrow-down4:before {
  content: "\f48e";
}
.icon-cart-circle-check4:before {
  content: "\f48f";
}
.icon-cart-circle-plus4:before {
  content: "\f490";
}
.icon-cart-flatbed-boxes4:before {
  content: "\f491";
}
.icon-cart-flatbed-empty4:before {
  content: "\f492";
}
.icon-cart-flatbed-suitcase4:before {
  content: "\f493";
}
.icon-cart-flatbed4:before {
  content: "\f494";
}
.icon-cart-shopping-fast4:before {
  content: "\f495";
}
.icon-cart-shopping4:before {
  content: "\f496";
}
.icon-chalkboard-user4:before {
  content: "\f497";
}
.icon-chart-column4:before {
  content: "\f498";
}
.icon-chart-line-down4:before {
  content: "\f499";
}
.icon-chart-line-up4:before {
  content: "\f49a";
}
.icon-chart-line4:before {
  content: "\f49b";
}
.icon-check4:before {
  content: "\f49c";
}
.icon-chevron-down4:before {
  content: "\f49d";
}
.icon-chevron-left4:before {
  content: "\f49e";
}
.icon-chevron-right4:before {
  content: "\f49f";
}
.icon-chevron-up4:before {
  content: "\f4a0";
}
.icon-chevrons-down4:before {
  content: "\f4a1";
}
.icon-chevrons-left4:before {
  content: "\f4a2";
}
.icon-chevrons-right4:before {
  content: "\f4a3";
}
.icon-chevrons-up4:before {
  content: "\f4a4";
}
.icon-circle-arrow-down-left4:before {
  content: "\f4a5";
}
.icon-circle-arrow-down-right4:before {
  content: "\f4a6";
}
.icon-circle-arrow-down4:before {
  content: "\f4a7";
}
.icon-circle-arrow-left4:before {
  content: "\f4a8";
}
.icon-circle-arrow-right4:before {
  content: "\f4a9";
}
.icon-circle-arrow-up-left4:before {
  content: "\f4aa";
}
.icon-circle-arrow-up-right4:before {
  content: "\f4ab";
}
.icon-circle-arrow-up4:before {
  content: "\f4ac";
}
.icon-circle-caret-down4:before {
  content: "\f4ad";
}
.icon-circle-caret-left4:before {
  content: "\f4ae";
}
.icon-circle-caret-right4:before {
  content: "\f4af";
}
.icon-circle-caret-up4:before {
  content: "\f4b0";
}
.icon-circle-check4:before {
  content: "\f4b1";
}
.icon-circle-chevron-down4:before {
  content: "\f4b2";
}
.icon-circle-chevron-left4:before {
  content: "\f4b3";
}
.icon-circle-chevron-right4:before {
  content: "\f4b4";
}
.icon-circle-chevron-up4:before {
  content: "\f4b5";
}
.icon-circle-dollar4:before {
  content: "\f4b6";
}
.icon-circle-down-left4:before {
  content: "\f4b7";
}
.icon-circle-down-right4:before {
  content: "\f4b8";
}
.icon-circle-down4:before {
  content: "\f4b9";
}
.icon-circle-envelope4:before {
  content: "\f4ba";
}
.icon-circle-exclamation4:before {
  content: "\f4bb";
}
.icon-circle-location-arrow4:before {
  content: "\f4bc";
}
.icon-circle-minus4:before {
  content: "\f6f9";
}
.icon-circle-pause4:before {
  content: "\f4bd";
}
.icon-circle-phone4:before {
  content: "\f4be";
}
.icon-circle-plus4:before {
  content: "\f4bf";
}
.icon-circle-right4:before {
  content: "\f4c0";
}
.icon-circle-star4:before {
  content: "\f4c1";
}
.icon-circle-up-left4:before {
  content: "\f4c2";
}
.icon-circle-up-right4:before {
  content: "\f4c3";
}
.icon-circle-up4:before {
  content: "\f4c4";
}
.icon-circle-user1:before {
  content: "\f7a3";
}
.icon-circle-video4:before {
  content: "\f4c5";
}
.icon-clipboard-list-check4:before {
  content: "\f4c6";
}
.icon-clipboard-list4:before {
  content: "\f4c7";
}
.icon-clipboard-medical4:before {
  content: "\f4c8";
}
.icon-clipboard-prescription3:before {
  content: "\f6cf";
}
.icon-clipboard-user4:before {
  content: "\f4c9";
}
.icon-clock-desk4:before {
  content: "\f4ca";
}
.icon-clock-rotate-left4:before {
  content: "\f4cb";
}
.icon-clock4:before {
  content: "\f4cc";
}
.icon-cloud-arrow-down4:before {
  content: "\f4cd";
}
.icon-cloud-bolt4:before {
  content: "\f4ce";
}
.icon-cloud-check4:before {
  content: "\f4cf";
}
.icon-coins4:before {
  content: "\f4d0";
}
.icon-comment-check4:before {
  content: "\f4d1";
}
.icon-comment-dollar4:before {
  content: "\f4d2";
}
.icon-comment-quote4:before {
  content: "\f4d3";
}
.icon-comment-smile4:before {
  content: "\f4d4";
}
.icon-comment-sms4:before {
  content: "\f4d5";
}
.icon-comment4:before {
  content: "\f4d6";
}
.icon-comments-dollar4:before {
  content: "\f4d7";
}
.icon-comments-question1:before {
  content: "\f779";
}
.icon-comments4:before {
  content: "\f4d8";
}
.icon-conveyor-belt4:before {
  content: "\f4d9";
}
.icon-copyright4:before {
  content: "\f4da";
}
.icon-credit-card-blank4:before {
  content: "\f4db";
}
.icon-credit-card-front4:before {
  content: "\f4dc";
}
.icon-credit-card4:before {
  content: "\f4dd";
}
.icon-dash3:before {
  content: "\f66e";
}
.icon-diploma4:before {
  content: "\f4de";
}
.icon-dollar-sign1:before {
  content: "\f759";
}
.icon-dolly-empty4:before {
  content: "\f4df";
}
.icon-dolly4:before {
  content: "\f4e0";
}
.icon-door-open4:before {
  content: "\f4e1";
}
.icon-down-from-dotted-line2:before {
  content: "\f4e2";
}
.icon-down-from-line4:before {
  content: "\f4e3";
}
.icon-down-left-and-up-right-to-center4:before {
  content: "\f4e4";
}
.icon-down-left4:before {
  content: "\f4e5";
}
.icon-down-long4:before {
  content: "\f4e6";
}
.icon-down-right4:before {
  content: "\f4e7";
}
.icon-down-to-bracket4:before {
  content: "\f4e8";
}
.icon-down-to-line4:before {
  content: "\f4e9";
}
.icon-down4:before {
  content: "\f4ea";
}
.icon-droplet-degree1:before {
  content: "\f72d";
}
.icon-droplet1:before {
  content: "\f72e";
}
.icon-earth-americas4:before {
  content: "\f4eb";
}
.icon-envelope-circle-check4:before {
  content: "\f4ec";
}
.icon-envelope-open-dollar4:before {
  content: "\f4ed";
}
.icon-envelope-open-text4:before {
  content: "\f4ee";
}
.icon-envelope4:before {
  content: "\f4ef";
}
.icon-eye4:before {
  content: "\f4f0";
}
.icon-face-grin-wide4:before {
  content: "\f4f1";
}
.icon-face-relieved4:before {
  content: "\f4f2";
}
.icon-face-smile-plus4:before {
  content: "\f4f3";
}
.icon-face-smile4:before {
  content: "\f4f4";
}
.icon-family-dress4:before {
  content: "\f4f5";
}
.icon-family-pants4:before {
  content: "\f4f6";
}
.icon-family4:before {
  content: "\f4f7";
}
.icon-fan-table4:before {
  content: "\f4f8";
}
.icon-fan4:before {
  content: "\f4f9";
}
.icon-faucet-drip4:before {
  content: "\f4fa";
}
.icon-faucet4:before {
  content: "\f4fb";
}
.icon-fax4:before {
  content: "\f4fc";
}
.icon-feather-pointed4:before {
  content: "\f4fd";
}
.icon-file-arrow-down4:before {
  content: "\f4fe";
}
.icon-file-certificate4:before {
  content: "\f4ff";
}
.icon-file-check4:before {
  content: "\f500";
}
.icon-file-circle-check4:before {
  content: "\f501";
}
.icon-file-circle-plus4:before {
  content: "\f502";
}
.icon-file-exclamation4:before {
  content: "\f503";
}
.icon-file-invoice-dollar4:before {
  content: "\f504";
}
.icon-file-pdf4:before {
  content: "\f505";
}
.icon-file-user4:before {
  content: "\f506";
}
.icon-files-medical4:before {
  content: "\f507";
}
.icon-filter-circle-dollar4:before {
  content: "\f508";
}
.icon-fire-extinguisher4:before {
  content: "\f509";
}
.icon-fire-flame-curved4:before {
  content: "\f50a";
}
.icon-fire-flame-simple4:before {
  content: "\f50b";
}
.icon-fire-flame4:before {
  content: "\f50c";
}
.icon-fire-hydrant4:before {
  content: "\f50d";
}
.icon-fire-smoke4:before {
  content: "\f50e";
}
.icon-fire4:before {
  content: "\f50f";
}
.icon-fireplace4:before {
  content: "\f510";
}
.icon-flag-usa4:before {
  content: "\f511";
}
.icon-floppy-disk4:before {
  content: "\f512";
}
.icon-folder-arrow-down4:before {
  content: "\f513";
}
.icon-folder-medical4:before {
  content: "\f514";
}
.icon-folder-plus4:before {
  content: "\f515";
}
.icon-font-awesome4:before {
  content: "\f516";
}
.icon-forward-fast4:before {
  content: "\f517";
}
.icon-forward-step4:before {
  content: "\f518";
}
.icon-forward4:before {
  content: "\f519";
}
.icon-gear1:before {
  content: "\f77a";
}
.icon-gift-card1:before {
  content: "\ed89";
}
.icon-gift4:before {
  content: "\f51a";
}
.icon-globe4:before {
  content: "\f51b";
}
.icon-graduation-cap1:before {
  content: "\f782";
}
.icon-grate-droplet4:before {
  content: "\f51c";
}
.icon-grate4:before {
  content: "\f51d";
}
.icon-hammer-crash4:before {
  content: "\f51e";
}
.icon-hand-back-fist4:before {
  content: "\f51f";
}
.icon-hand-fingers-crossed4:before {
  content: "\f520";
}
.icon-hand-fist4:before {
  content: "\f521";
}
.icon-hand-holding-box4:before {
  content: "\f522";
}
.icon-hand-holding-dollar4:before {
  content: "\f523";
}
.icon-hand-holding-droplet4:before {
  content: "\f524";
}
.icon-hand-holding-heart4:before {
  content: "\f525";
}
.icon-hand-holding-medical4:before {
  content: "\f526";
}
.icon-hand-sparkles4:before {
  content: "\f527";
}
.icon-hands-bubbles4:before {
  content: "\f528";
}
.icon-hands-holding-dollar4:before {
  content: "\f529";
}
.icon-hands-holding-heart4:before {
  content: "\f52a";
}
.icon-hands-holding4:before {
  content: "\f52b";
}
.icon-hands-praying4:before {
  content: "\f52c";
}
.icon-hands4:before {
  content: "\f52d";
}
.icon-handshake-angle4:before {
  content: "\f52e";
}
.icon-handshake-simple4:before {
  content: "\f52f";
}
.icon-handshake4:before {
  content: "\f530";
}
.icon-head-side-brain4:before {
  content: "\f531";
}
.icon-head-side-cough4:before {
  content: "\f532";
}
.icon-head-side-medical4:before {
  content: "\f533";
}
.icon-head-side-virus4:before {
  content: "\f534";
}
.icon-head-side4:before {
  content: "\f535";
}
.icon-heart-pulse4:before {
  content: "\f6fa";
}
.icon-heart4:before {
  content: "\f536";
}
.icon-helmet-safety4:before {
  content: "\f537";
}
.icon-high-definition4:before {
  content: "\f538";
}
.icon-hospital4:before {
  content: "\f539";
}
.icon-hospitals4:before {
  content: "\f53a";
}
.icon-hourglass-clock4:before {
  content: "\f53b";
}
.icon-house-building4:before {
  content: "\f53c";
}
.icon-house-chimney-blank4:before {
  content: "\f53d";
}
.icon-house-chimney-heart4:before {
  content: "\f53e";
}
.icon-house-chimney-user4:before {
  content: "\f53f";
}
.icon-house-chimney-window4:before {
  content: "\f540";
}
.icon-house-chimney4:before {
  content: "\f541";
}
.icon-house-circle-check1:before {
  content: "\f783";
}
.icon-house-fire3:before {
  content: "\f542";
}
.icon-house-heart4:before {
  content: "\f543";
}
.icon-house-window4:before {
  content: "\f544";
}
.icon-house4:before {
  content: "\f545";
}
.icon-hundred-points1:before {
  content: "\f716";
}
.icon-id-card4:before {
  content: "\f546";
}
.icon-image-portrait4:before {
  content: "\f547";
}
.icon-images4:before {
  content: "\f548";
}
.icon-inbox-in4:before {
  content: "\f549";
}
.icon-infinity1:before {
  content: "\f784";
}
.icon-key-skeleton-left-right2:before {
  content: "\f54a";
}
.icon-key-skeleton4:before {
  content: "\f54b";
}
.icon-kit-medical4:before {
  content: "\f54c";
}
.icon-laptop-arrow-down4:before {
  content: "\f54d";
}
.icon-leaf-heart4:before {
  content: "\f54e";
}
.icon-leaf-maple4:before {
  content: "\f54f";
}
.icon-leaf-oak4:before {
  content: "\f550";
}
.icon-leaf4:before {
  content: "\f551";
}
.icon-left-from-line4:before {
  content: "\f552";
}
.icon-left-long-to-line4:before {
  content: "\f553";
}
.icon-left-long4:before {
  content: "\f554";
}
.icon-left-to-line4:before {
  content: "\f555";
}
.icon-left4:before {
  content: "\f556";
}
.icon-lightbulb-dollar4:before {
  content: "\f557";
}
.icon-lightbulb-exclamation-on4:before {
  content: "\f558";
}
.icon-lightbulb-exclamation4:before {
  content: "\f559";
}
.icon-lightbulb-on4:before {
  content: "\f55a";
}
.icon-lightbulb4:before {
  content: "\f55b";
}
.icon-list-ul4:before {
  content: "\f55c";
}
.icon-location-arrow4:before {
  content: "\f55d";
}
.icon-location-check4:before {
  content: "\f55e";
}
.icon-location-dot4:before {
  content: "\f66f";
}
.icon-location-exclamation4:before {
  content: "\f670";
}
.icon-location-pin4:before {
  content: "\f671";
}
.icon-magnifying-glass-dollar4:before {
  content: "\f55f";
}
.icon-magnifying-glass-location4:before {
  content: "\f560";
}
.icon-magnifying-glass-minus1:before {
  content: "\f67f";
}
.icon-magnifying-glass-plus2:before {
  content: "\f680";
}
.icon-magnifying-glass4:before {
  content: "\f681";
}
.icon-map-location-dot4:before {
  content: "\f561";
}
.icon-map-location4:before {
  content: "\f562";
}
.icon-map-pin4:before {
  content: "\f563";
}
.icon-map4:before {
  content: "\f564";
}
.icon-medal1:before {
  content: "\f76b";
}
.icon-megaphone1:before {
  content: "\f762";
}
.icon-message-arrow-down4:before {
  content: "\f565";
}
.icon-message-arrow-up-right4:before {
  content: "\f566";
}
.icon-message-arrow-up4:before {
  content: "\f567";
}
.icon-message-check4:before {
  content: "\f568";
}
.icon-message-dollar4:before {
  content: "\f569";
}
.icon-message-dots4:before {
  content: "\f56a";
}
.icon-message-lines4:before {
  content: "\f6d0";
}
.icon-message-medical4:before {
  content: "\f56b";
}
.icon-message-plus4:before {
  content: "\f56c";
}
.icon-message-quote4:before {
  content: "\f56d";
}
.icon-message-smile4:before {
  content: "\f56e";
}
.icon-messages-dollar4:before {
  content: "\f56f";
}
.icon-messages4:before {
  content: "\f570";
}
.icon-meter-bolt4:before {
  content: "\f571";
}
.icon-meter-droplet4:before {
  content: "\f572";
}
.icon-meter-fire4:before {
  content: "\f573";
}
.icon-meter4:before {
  content: "\f574";
}
.icon-microchip-ai:before {
  content: "\f6d1";
}
.icon-microchip2:before {
  content: "\f6d2";
}
.icon-microphone4:before {
  content: "\f575";
}
.icon-minus4:before {
  content: "\f6d3";
}
.icon-mobile-button1:before {
  content: "\f73f";
}
.icon-mobile-screen-button1:before {
  content: "\f740";
}
.icon-mobile-screen1:before {
  content: "\f741";
}
.icon-mobile4:before {
  content: "\f576";
}
.icon-money-bill-1-wave4:before {
  content: "\f577";
}
.icon-money-bill-14:before {
  content: "\f578";
}
.icon-money-bill-simple-wave4:before {
  content: "\f579";
}
.icon-money-bill-simple4:before {
  content: "\f57a";
}
.icon-money-bill-wave4:before {
  content: "\f57b";
}
.icon-money-bill4:before {
  content: "\f57c";
}
.icon-money-check-dollar-pen4:before {
  content: "\f57d";
}
.icon-money-check-dollar4:before {
  content: "\f57e";
}
.icon-money-check-pen4:before {
  content: "\f57f";
}
.icon-money-check4:before {
  content: "\f580";
}
.icon-octagon-check4:before {
  content: "\f581";
}
.icon-octagon-plus4:before {
  content: "\f582";
}
.icon-page-caret-down4:before {
  content: "\f583";
}
.icon-page-caret-up4:before {
  content: "\f584";
}
.icon-pallet-box4:before {
  content: "\f585";
}
.icon-pallet-boxes4:before {
  content: "\f586";
}
.icon-panel-ews4:before {
  content: "\f587";
}
.icon-panel-fire4:before {
  content: "\f588";
}
.icon-paper-plane4:before {
  content: "\f589";
}
.icon-pen-circle4:before {
  content: "\f58a";
}
.icon-pen-field1:before {
  content: "\f7a8";
}
.icon-pen-line4:before {
  content: "\f58b";
}
.icon-pen-nib4:before {
  content: "\f58c";
}
.icon-pen-paintbrush4:before {
  content: "\f58d";
}
.icon-pen-ruler4:before {
  content: "\f58e";
}
.icon-pen-to-square4:before {
  content: "\f58f";
}
.icon-pen4:before {
  content: "\f590";
}
.icon-pencil4:before {
  content: "\f591";
}
.icon-people-arrows-left-right4:before {
  content: "\f592";
}
.icon-people-carry-box4:before {
  content: "\f593";
}
.icon-people-group4:before {
  content: "\f594";
}
.icon-people-roof:before {
  content: "\f771";
}
.icon-people-simple4:before {
  content: "\f595";
}
.icon-person-carry-box4:before {
  content: "\f596";
}
.icon-person-circle-check4:before {
  content: "\f597";
}
.icon-person-digging4:before {
  content: "\f598";
}
.icon-person-dolly-empty4:before {
  content: "\f599";
}
.icon-person-dolly4:before {
  content: "\f59a";
}
.icon-person-dress-simple4:before {
  content: "\f59b";
}
.icon-person-running4:before {
  content: "\f59c";
}
.icon-person-to-door4:before {
  content: "\f59d";
}
.icon-person-walking4:before {
  content: "\f59e";
}
.icon-phone-flip1:before {
  content: "\f6d4";
}
.icon-phone-plus4:before {
  content: "\f5a1";
}
.icon-phone-rotary1:before {
  content: "\f796";
}
.icon-phone-volume1:before {
  content: "\f707";
}
.icon-phone-xmark:before {
  content: "\f6d5";
}
.icon-phone2:before {
  content: "\f6d6";
}
.icon-piggy-bank2:before {
  content: "\f6d7";
}
.icon-pipe-circle-check4:before {
  content: "\f5a2";
}
.icon-pipe-collar4:before {
  content: "\f5a3";
}
.icon-pipe-section4:before {
  content: "\f5a4";
}
.icon-pipe-valve4:before {
  content: "\f5a5";
}
.icon-plug-circle-bolt4:before {
  content: "\f5a6";
}
.icon-plug-circle-xmark4:before {
  content: "\f5a7";
}
.icon-plug4:before {
  content: "\f5a8";
}
.icon-plus-large4:before {
  content: "\f6d8";
}
.icon-plus-minus4:before {
  content: "\f5a9";
}
.icon-plus4:before {
  content: "\f6d9";
}
.icon-pump-soap:before {
  content: "\f5aa";
}
.icon-pump4:before {
  content: "\f5ab";
}
.icon-question4:before {
  content: "\f5ac";
}
.icon-quote-left4:before {
  content: "\f5ad";
}
.icon-quote-right4:before {
  content: "\f5ae";
}
.icon-quotes4:before {
  content: "\f5af";
}
.icon-raindrops3:before {
  content: "\f785";
}
.icon-rectangle-list1:before {
  content: "\f74f";
}
.icon-right-from-line4:before {
  content: "\f5b0";
}
.icon-right-long-to-line4:before {
  content: "\f5b1";
}
.icon-right-to-bracket4:before {
  content: "\f5b2";
}
.icon-right-to-line4:before {
  content: "\f5b3";
}
.icon-right:before {
  content: "\f717";
}
.icon-route4:before {
  content: "\f5b4";
}
.icon-sack-dollar4:before {
  content: "\f5b5";
}
.icon-sack4:before {
  content: "\f5b6";
}
.icon-scale-balanced4:before {
  content: "\f5b7";
}
.icon-scale-unbalanced-flip4:before {
  content: "\f5b8";
}
.icon-scale-unbalanced4:before {
  content: "\f5b9";
}
.icon-school-flag4:before {
  content: "\f5ba";
}
.icon-school4:before {
  content: "\f5bb";
}
.icon-screwdriver-wrench4:before {
  content: "\f5bc";
}
.icon-screwdriver4:before {
  content: "\f5bd";
}
.icon-seal-exclamation4:before {
  content: "\f5be";
}
.icon-sensor-cloud4:before {
  content: "\f5bf";
}
.icon-sensor-fire4:before {
  content: "\f5c0";
}
.icon-sensor-on4:before {
  content: "\f5c1";
}
.icon-sensor-triangle-exclamation4:before {
  content: "\f5c2";
}
.icon-sensor4:before {
  content: "\f5c3";
}
.icon-share-from-square4:before {
  content: "\f5c4";
}
.icon-shield-check4:before {
  content: "\f5c5";
}
.icon-shield-halved4:before {
  content: "\f5c6";
}
.icon-shield-plus4:before {
  content: "\f5c7";
}
.icon-shield-quartered4:before {
  content: "\f5c8";
}
.icon-shield-virus4:before {
  content: "\f5c9";
}
.icon-shield-xmark4:before {
  content: "\f5ca";
}
.icon-shield4:before {
  content: "\f5cb";
}
.icon-shovel4:before {
  content: "\f5cc";
}
.icon-shower-down4:before {
  content: "\f5cd";
}
.icon-shower4:before {
  content: "\f5ce";
}
.icon-sink4:before {
  content: "\f5cf";
}
.icon-snow-blowing4:before {
  content: "\f5d0";
}
.icon-snowflake4:before {
  content: "\f5d1";
}
.icon-snowflakes4:before {
  content: "\f5d2";
}
.icon-snowplow4:before {
  content: "\f5d3";
}
.icon-soap4:before {
  content: "\f5d4";
}
.icon-sort-down:before {
  content: "\f718";
}
.icon-sparkles4:before {
  content: "\f672";
}
.icon-splotch4:before {
  content: "\f5d5";
}
.icon-square-arrow-down-left4:before {
  content: "\f5d6";
}
.icon-square-arrow-down-right4:before {
  content: "\f5d7";
}
.icon-square-arrow-down4:before {
  content: "\f5d8";
}
.icon-square-arrow-left4:before {
  content: "\f5d9";
}
.icon-square-arrow-right4:before {
  content: "\f5da";
}
.icon-square-arrow-up-left4:before {
  content: "\f5db";
}
.icon-square-arrow-up-right4:before {
  content: "\f5dc";
}
.icon-square-arrow-up4:before {
  content: "\f5dd";
}
.icon-square-caret-down4:before {
  content: "\f5de";
}
.icon-square-caret-left4:before {
  content: "\f5df";
}
.icon-square-caret-right4:before {
  content: "\f5e0";
}
.icon-square-caret-up4:before {
  content: "\f5e1";
}
.icon-square-check4:before {
  content: "\f5e2";
}
.icon-square-chevron-down4:before {
  content: "\f5e3";
}
.icon-square-chevron-left4:before {
  content: "\f5e4";
}
.icon-square-chevron-right4:before {
  content: "\f5e5";
}
.icon-square-chevron-up4:before {
  content: "\f5e6";
}
.icon-square-dollar4:before {
  content: "\f5e7";
}
.icon-square-down-left4:before {
  content: "\f5e8";
}
.icon-square-down-right4:before {
  content: "\f5e9";
}
.icon-square-down4:before {
  content: "\f5ea";
}
.icon-square-envelope4:before {
  content: "\f5eb";
}
.icon-square-exclamation4:before {
  content: "\f5ec";
}
.icon-square-left4:before {
  content: "\f5ed";
}
.icon-square-minus4:before {
  content: "\f6da";
}
.icon-square-pen4:before {
  content: "\f5ee";
}
.icon-square-phone4:before {
  content: "\f5ef";
}
.icon-square-plus4:before {
  content: "\f6db";
}
.icon-square-quote4:before {
  content: "\f5f0";
}
.icon-square-up-left4:before {
  content: "\f5f1";
}
.icon-square-up-right4:before {
  content: "\f5f2";
}
.icon-square-up3:before {
  content: "\f5f3";
}
.icon-star-christmas4:before {
  content: "\f5f4";
}
.icon-star-half4:before {
  content: "\f5f5";
}
.icon-star-sharp4:before {
  content: "\f6dc";
}
.icon-star-shooting4:before {
  content: "\f5f6";
}
.icon-star4:before {
  content: "\f6dd";
}
.icon-stars4:before {
  content: "\f5f7";
}
.icon-stopwatch4:before {
  content: "\f5f8";
}
.icon-suitcase-medical4:before {
  content: "\f5f9";
}
.icon-suitcase-rolling4:before {
  content: "\f5fa";
}
.icon-suitcase4:before {
  content: "\f5fb";
}
.icon-tag4:before {
  content: "\f5fc";
}
.icon-tags4:before {
  content: "\f5fd";
}
.icon-tank-water4:before {
  content: "\f5fe";
}
.icon-taxi4:before {
  content: "\f5ff";
}
.icon-temperature-arrow-down4:before {
  content: "\f600";
}
.icon-temperature-arrow-up4:before {
  content: "\f601";
}
.icon-temperature-empty4:before {
  content: "\f602";
}
.icon-temperature-full4:before {
  content: "\f603";
}
.icon-temperature-half4:before {
  content: "\f604";
}
.icon-temperature-high4:before {
  content: "\f605";
}
.icon-temperature-list4:before {
  content: "\f606";
}
.icon-temperature-low4:before {
  content: "\f607";
}
.icon-temperature-quarter4:before {
  content: "\f608";
}
.icon-temperature-snow4:before {
  content: "\f609";
}
.icon-temperature-sun4:before {
  content: "\f60a";
}
.icon-temperature-three-quarters4:before {
  content: "\f60b";
}
.icon-thumbs-down4:before {
  content: "\f60c";
}
.icon-thumbs-up4:before {
  content: "\f60d";
}
.icon-ticket4:before {
  content: "\f6e5";
}
.icon-timer4:before {
  content: "\f60e";
}
.icon-toilet4:before {
  content: "\f60f";
}
.icon-toolbox4:before {
  content: "\f610";
}
.icon-tooth2:before {
  content: "\f6de";
}
.icon-train-track4:before {
  content: "\f611";
}
.icon-trash-can-check4:before {
  content: "\f612";
}
.icon-trash-check4:before {
  content: "\f613";
}
.icon-trash-plus4:before {
  content: "\f614";
}
.icon-treasure-chest4:before {
  content: "\f615";
}
.icon-triangle-exclamation4:before {
  content: "\f616";
}
.icon-triangle-person-digging4:before {
  content: "\f617";
}
.icon-trophy-star4:before {
  content: "\f618";
}
.icon-trophy4:before {
  content: "\f619";
}
.icon-truck-clock4:before {
  content: "\f61a";
}
.icon-truck-fast4:before {
  content: "\f61b";
}
.icon-truck-field4:before {
  content: "\f61c";
}
.icon-truck-flatbed4:before {
  content: "\f61d";
}
.icon-truck-front4:before {
  content: "\f61e";
}
.icon-truck-medical4:before {
  content: "\f61f";
}
.icon-truck-moving4:before {
  content: "\f620";
}
.icon-truck-plow4:before {
  content: "\f621";
}
.icon-truck-ramp-box4:before {
  content: "\f622";
}
.icon-truck-ramp4:before {
  content: "\f623";
}
.icon-truck4:before {
  content: "\f624";
}
.icon-tty-answer4:before {
  content: "\f625";
}
.icon-turn-down-left4:before {
  content: "\f626";
}
.icon-turn-down-right4:before {
  content: "\f627";
}
.icon-turn-down4:before {
  content: "\f628";
}
.icon-turn-up4:before {
  content: "\f629";
}
.icon-umbrella-beach3:before {
  content: "\f6fb";
}
.icon-up-left4:before {
  content: "\f62a";
}
.icon-up-right-from-square4:before {
  content: "\f62b";
}
.icon-up-right4:before {
  content: "\f62c";
}
.icon-up-to-line4:before {
  content: "\f62d";
}
.icon-user-check4:before {
  content: "\f62e";
}
.icon-user-clock4:before {
  content: "\f62f";
}
.icon-user-doctor-message4:before {
  content: "\f630";
}
.icon-user-doctor4:before {
  content: "\f631";
}
.icon-user-group4:before {
  content: "\f632";
}
.icon-user-hair-long4:before {
  content: "\f633";
}
.icon-user-headset1:before {
  content: "\f750";
}
.icon-user-helmet-safety4:before {
  content: "\f634";
}
.icon-user-large4:before {
  content: "\f635";
}
.icon-user-pen4:before {
  content: "\f636";
}
.icon-user-plus4:before {
  content: "\f637";
}
.icon-user-robot-xmarks4:before {
  content: "\f638";
}
.icon-user-robot4:before {
  content: "\f639";
}
.icon-user-shield4:before {
  content: "\f63a";
}
.icon-user-tag4:before {
  content: "\f63b";
}
.icon-user-tie4:before {
  content: "\f63c";
}
.icon-user4:before {
  content: "\f63d";
}
.icon-users-between-lines4:before {
  content: "\f63e";
}
.icon-users4:before {
  content: "\f63f";
}
.icon-van-shuttle4:before {
  content: "\f640";
}
.icon-video4:before {
  content: "\f641";
}
.icon-viruses4:before {
  content: "\f642";
}
.icon-volume-high4:before {
  content: "\f643";
}
.icon-volume-low4:before {
  content: "\f644";
}
.icon-volume-off4:before {
  content: "\f645";
}
.icon-volume-slash4:before {
  content: "\f646";
}
.icon-volume-xmark4:before {
  content: "\f647";
}
.icon-volume4:before {
  content: "\f648";
}
.icon-wallet4:before {
  content: "\f649";
}
.icon-warehouse-full4:before {
  content: "\f64a";
}
.icon-warehouse4:before {
  content: "\f64b";
}
.icon-water1:before {
  content: "\f72f";
}
.icon-wheelchair-move4:before {
  content: "\f64c";
}
.icon-wind-warning4:before {
  content: "\f64d";
}
.icon-wind4:before {
  content: "\f64e";
}
.icon-window-flip4:before {
  content: "\f64f";
}
.icon-window-frame-open4:before {
  content: "\f650";
}
.icon-window-frame4:before {
  content: "\f651";
}
.icon-window-minimize4:before {
  content: "\f6df";
}
.icon-window4:before {
  content: "\f652";
}
.icon-wrench-simple3:before {
  content: "\f653";
}
.icon-wrench4:before {
  content: "\f654";
}
.icon-x3:before {
  content: "\f655";
}
.icon-xmark-large3:before {
  content: "\f656";
}
.icon-xmark3:before {
  content: "\f660";
}
.icon-address-card2:before {
  content: "\efba";
}
.icon-air-conditioner2:before {
  content: "\efbb";
}
.icon-alarm-clock2:before {
  content: "\efbc";
}
.icon-alarm-exclamation2:before {
  content: "\efbd";
}
.icon-angle-down2:before {
  content: "\efbe";
}
.icon-angle-left2:before {
  content: "\efbf";
}
.icon-angle-right2:before {
  content: "\efc0";
}
.icon-angle-up2:before {
  content: "\efc1";
}
.icon-angles-down2:before {
  content: "\efc2";
}
.icon-angles-left2:before {
  content: "\efc3";
}
.icon-angles-right2:before {
  content: "\efc4";
}
.icon-angles-up2:before {
  content: "\efc5";
}
.icon-apple-whole2:before {
  content: "\efc6";
}
.icon-archway2:before {
  content: "\efc7";
}
.icon-arrow-down-arrow-up2:before {
  content: "\efc8";
}
.icon-arrow-down-from-line2:before {
  content: "\efc9";
}
.icon-arrow-down-left-and-arrow-up-right-to-center2:before {
  content: "\efca";
}
.icon-arrow-down-left2:before {
  content: "\efcb";
}
.icon-arrow-down-long2:before {
  content: "\efcc";
}
.icon-arrow-down-right2:before {
  content: "\efcd";
}
.icon-arrow-down-to-bracket2:before {
  content: "\efce";
}
.icon-arrow-down-to-line2:before {
  content: "\efcf";
}
.icon-arrow-down-to-square2:before {
  content: "\efd0";
}
.icon-arrow-down2:before {
  content: "\efd1";
}
.icon-arrow-left-from-line2:before {
  content: "\efd2";
}
.icon-arrow-left-long-to-line2:before {
  content: "\efd3";
}
.icon-arrow-left-long2:before {
  content: "\efd4";
}
.icon-arrow-left-to-line2:before {
  content: "\efd5";
}
.icon-arrow-left2:before {
  content: "\efd6";
}
.icon-arrow-right-arrow-left2:before {
  content: "\efd7";
}
.icon-arrow-right-from-line2:before {
  content: "\efd8";
}
.icon-arrow-right-long-to-line2:before {
  content: "\efd9";
}
.icon-arrow-right-long2:before {
  content: "\efda";
}
.icon-arrow-right-to-line2:before {
  content: "\efdb";
}
.icon-arrow-right2:before {
  content: "\efdc";
}
.icon-arrow-turn-down-left2:before {
  content: "\efdd";
}
.icon-arrow-turn-down-right2:before {
  content: "\efde";
}
.icon-arrow-turn-down2:before {
  content: "\efdf";
}
.icon-arrow-turn-up2:before {
  content: "\efe0";
}
.icon-arrow-up-from-line2:before {
  content: "\efe1";
}
.icon-arrow-up-left2:before {
  content: "\efe2";
}
.icon-arrow-up-long2:before {
  content: "\efe3";
}
.icon-arrow-up-right2:before {
  content: "\efe4";
}
.icon-arrow-up-to-line2:before {
  content: "\efe5";
}
.icon-arrow-up2:before {
  content: "\efe6";
}
.icon-arrows-left-right2:before {
  content: "\efe7";
}
.icon-arrows-maximize2:before {
  content: "\efe8";
}
.icon-arrows-repeat2:before {
  content: "\efe9";
}
.icon-arrows-retweet2:before {
  content: "\efea";
}
.icon-audio-description2:before {
  content: "\efeb";
}
.icon-award-simple2:before {
  content: "\efec";
}
.icon-award2:before {
  content: "\efed";
}
.icon-badge-check2:before {
  content: "\efee";
}
.icon-badge-dollar2:before {
  content: "\efef";
}
.icon-badge-percent2:before {
  content: "\eff0";
}
.icon-badge2:before {
  content: "\eff1";
}
.icon-ballot-check2:before {
  content: "\eff2";
}
.icon-ballot2:before {
  content: "\eff3";
}
.icon-bars2:before {
  content: "\eff4";
}
.icon-bed-pulse3:before {
  content: "\f6ab";
}
.icon-bell-on2:before {
  content: "\eff5";
}
.icon-bell2:before {
  content: "\eff6";
}
.icon-blanket2:before {
  content: "\eff7";
}
.icon-blender-phone:before {
  content: "\f6ac";
}
.icon-bolt-lightning2:before {
  content: "\f719";
}
.icon-bolt2:before {
  content: "\f71a";
}
.icon-book-blank2:before {
  content: "\eff8";
}
.icon-book-open-cover2:before {
  content: "\eff9";
}
.icon-book-open-reader2:before {
  content: "\effa";
}
.icon-books2:before {
  content: "\effb";
}
.icon-box-check2:before {
  content: "\effc";
}
.icon-box-dollar2:before {
  content: "\effd";
}
.icon-box-heart2:before {
  content: "\effe";
}
.icon-box-open2:before {
  content: "\efff";
}
.icon-box-taped2:before {
  content: "\f000";
}
.icon-box2:before {
  content: "\f001";
}
.icon-brain2:before {
  content: "\f002";
}
.icon-brake-warning2:before {
  content: "\f003";
}
.icon-briefcase-blank2:before {
  content: "\f004";
}
.icon-briefcase-medical2:before {
  content: "\f005";
}
.icon-briefcase2:before {
  content: "\f006";
}
.icon-brightness2:before {
  content: "\f007";
}
.icon-broom-ball2:before {
  content: "\f008";
}
.icon-broom2:before {
  content: "\f009";
}
.icon-browser2:before {
  content: "\f00a";
}
.icon-browsers2:before {
  content: "\f00b";
}
.icon-building-circle-check2:before {
  content: "\f00c";
}
.icon-building-columns2:before {
  content: "\f6f0";
}
.icon-building-shield2:before {
  content: "\f00d";
}
.icon-building-user2:before {
  content: "\f00e";
}
.icon-building2:before {
  content: "\f00f";
}
.icon-buildings2:before {
  content: "\f010";
}
.icon-bullhorn2:before {
  content: "\f763";
}
.icon-business-time2:before {
  content: "\f011";
}
.icon-cabinet-filing2:before {
  content: "\f012";
}
.icon-calculator-simple2:before {
  content: "\f013";
}
.icon-calendar-check2:before {
  content: "\f014";
}
.icon-calendar-circle-exclamation2:before {
  content: "\f015";
}
.icon-calendar-circle-user2:before {
  content: "\f016";
}
.icon-calendar-clock2:before {
  content: "\f017";
}
.icon-calendar-days2:before {
  content: "\f018";
}
.icon-calendar-lines-pen2:before {
  content: "\f019";
}
.icon-calendar-pen2:before {
  content: "\f01a";
}
.icon-calendar-plus2:before {
  content: "\f708";
}
.icon-calendar-star2:before {
  content: "\f01b";
}
.icon-calendar-xmark2:before {
  content: "\f01c";
}
.icon-calendar2:before {
  content: "\f01d";
}
.icon-caret-down2:before {
  content: "\f01e";
}
.icon-caret-left2:before {
  content: "\f01f";
}
.icon-caret-right2:before {
  content: "\f020";
}
.icon-caret-up2:before {
  content: "\f021";
}
.icon-cart-arrow-down2:before {
  content: "\f022";
}
.icon-cart-arrow-up2:before {
  content: "\f023";
}
.icon-cart-circle-arrow-down2:before {
  content: "\f024";
}
.icon-cart-circle-check2:before {
  content: "\f025";
}
.icon-cart-circle-plus2:before {
  content: "\f026";
}
.icon-cart-flatbed-boxes2:before {
  content: "\f027";
}
.icon-cart-flatbed-empty2:before {
  content: "\f028";
}
.icon-cart-flatbed-suitcase2:before {
  content: "\f029";
}
.icon-cart-flatbed2:before {
  content: "\f02a";
}
.icon-cart-shopping-fast2:before {
  content: "\f02b";
}
.icon-cart-shopping2:before {
  content: "\f02c";
}
.icon-chalkboard-user2:before {
  content: "\f02d";
}
.icon-chart-column2:before {
  content: "\f02e";
}
.icon-chart-line-down2:before {
  content: "\f02f";
}
.icon-chart-line-up2:before {
  content: "\f030";
}
.icon-chart-line2:before {
  content: "\f031";
}
.icon-check:before {
  content: "\f032";
}
.icon-chevron-down2:before {
  content: "\f033";
}
.icon-chevron-left2:before {
  content: "\f034";
}
.icon-chevron-right2:before {
  content: "\f035";
}
.icon-chevron-up2:before {
  content: "\f036";
}
.icon-chevrons-down2:before {
  content: "\f037";
}
.icon-chevrons-left2:before {
  content: "\f038";
}
.icon-chevrons-right2:before {
  content: "\f039";
}
.icon-chevrons-up2:before {
  content: "\f03a";
}
.icon-circle-arrow-down-left2:before {
  content: "\f03b";
}
.icon-circle-arrow-down-right2:before {
  content: "\f03c";
}
.icon-circle-arrow-down2:before {
  content: "\f03d";
}
.icon-circle-arrow-left2:before {
  content: "\f03e";
}
.icon-circle-arrow-right2:before {
  content: "\f03f";
}
.icon-circle-arrow-up-left2:before {
  content: "\f040";
}
.icon-circle-arrow-up-right2:before {
  content: "\f041";
}
.icon-circle-arrow-up2:before {
  content: "\f042";
}
.icon-circle-caret-down2:before {
  content: "\f043";
}
.icon-circle-caret-left2:before {
  content: "\f044";
}
.icon-circle-caret-right2:before {
  content: "\f045";
}
.icon-circle-caret-up2:before {
  content: "\f046";
}
.icon-circle-check2:before {
  content: "\f047";
}
.icon-circle-chevron-down2:before {
  content: "\f048";
}
.icon-circle-chevron-left2:before {
  content: "\f049";
}
.icon-circle-chevron-right2:before {
  content: "\f04a";
}
.icon-circle-chevron-up2:before {
  content: "\f04b";
}
.icon-circle-dollar2:before {
  content: "\f04c";
}
.icon-circle-down-left2:before {
  content: "\f04d";
}
.icon-circle-down-right2:before {
  content: "\f04e";
}
.icon-circle-down2:before {
  content: "\f04f";
}
.icon-circle-envelope2:before {
  content: "\f050";
}
.icon-circle-exclamation2:before {
  content: "\f051";
}
.icon-circle-left1:before {
  content: "\f052";
}
.icon-circle-location-arrow2:before {
  content: "\f053";
}
.icon-circle-minus2:before {
  content: "\f6f1";
}
.icon-circle-pause2:before {
  content: "\f054";
}
.icon-circle-phone2:before {
  content: "\f055";
}
.icon-circle-plus2:before {
  content: "\f056";
}
.icon-circle-right2:before {
  content: "\f057";
}
.icon-circle-star2:before {
  content: "\f058";
}
.icon-circle-up-left2:before {
  content: "\f059";
}
.icon-circle-up-right2:before {
  content: "\f05a";
}
.icon-circle-up2:before {
  content: "\f05b";
}
.icon-circle-user2:before {
  content: "\f7a4";
}
.icon-circle-video2:before {
  content: "\f05c";
}
.icon-clapperboard-play1:before {
  content: "\f05d";
}
.icon-clipboard-check1:before {
  content: "\f05e";
}
.icon-clipboard-list-check2:before {
  content: "\f05f";
}
.icon-clipboard-list2:before {
  content: "\f060";
}
.icon-clipboard-medical2:before {
  content: "\f061";
}
.icon-clipboard-prescription4:before {
  content: "\f6ad";
}
.icon-clipboard-user2:before {
  content: "\f062";
}
.icon-clock-desk2:before {
  content: "\f063";
}
.icon-clock-rotate-left2:before {
  content: "\f064";
}
.icon-clock2:before {
  content: "\f065";
}
.icon-cloud-arrow-down2:before {
  content: "\f066";
}
.icon-cloud-bolt2:before {
  content: "\f067";
}
.icon-cloud-check2:before {
  content: "\f068";
}
.icon-coins2:before {
  content: "\f069";
}
.icon-comment-check2:before {
  content: "\f06a";
}
.icon-comment-dollar2:before {
  content: "\f06b";
}
.icon-comment-quote2:before {
  content: "\f06c";
}
.icon-comment-smile2:before {
  content: "\f06d";
}
.icon-comment-sms2:before {
  content: "\f06e";
}
.icon-comment2:before {
  content: "\f06f";
}
.icon-comments-dollar2:before {
  content: "\f070";
}
.icon-comments-question2:before {
  content: "\f77b";
}
.icon-comments2:before {
  content: "\f071";
}
.icon-conveyor-belt2:before {
  content: "\f072";
}
.icon-copyright2:before {
  content: "\f073";
}
.icon-credit-card-blank2:before {
  content: "\f074";
}
.icon-credit-card-front2:before {
  content: "\f075";
}
.icon-credit-card2:before {
  content: "\f076";
}
.icon-dash:before {
  content: "\f665";
}
.icon-diploma2:before {
  content: "\f077";
}
.icon-dollar-sign2:before {
  content: "\f75a";
}
.icon-dolly-empty2:before {
  content: "\f078";
}
.icon-dolly2:before {
  content: "\f079";
}
.icon-door-open2:before {
  content: "\f07a";
}
.icon-down-from-line2:before {
  content: "\f07b";
}
.icon-down-left-and-up-right-to-center2:before {
  content: "\f07c";
}
.icon-down-left2:before {
  content: "\f07d";
}
.icon-down-long2:before {
  content: "\f07e";
}
.icon-down-right2:before {
  content: "\f07f";
}
.icon-down-to-bracket2:before {
  content: "\f080";
}
.icon-down-to-line2:before {
  content: "\f081";
}
.icon-down2:before {
  content: "\f082";
}
.icon-droplet-degree2:before {
  content: "\f730";
}
.icon-droplet2:before {
  content: "\f731";
}
.icon-earth-americas2:before {
  content: "\f083";
}
.icon-envelope-circle-check2:before {
  content: "\f084";
}
.icon-envelope-open-dollar2:before {
  content: "\f085";
}
.icon-envelope-open-text2:before {
  content: "\f086";
}
.icon-envelope2:before {
  content: "\f087";
}
.icon-eye2:before {
  content: "\f088";
}
.icon-face-grin-wide2:before {
  content: "\f089";
}
.icon-face-relieved2:before {
  content: "\f08a";
}
.icon-face-smile-plus2:before {
  content: "\f08b";
}
.icon-face-smile2:before {
  content: "\f08c";
}
.icon-family-dress2:before {
  content: "\f08d";
}
.icon-family-pants2:before {
  content: "\f08e";
}
.icon-family2:before {
  content: "\f08f";
}
.icon-fan-table2:before {
  content: "\f090";
}
.icon-fan2:before {
  content: "\f091";
}
.icon-faucet-drip2:before {
  content: "\f092";
}
.icon-faucet2:before {
  content: "\f093";
}
.icon-fax2:before {
  content: "\f094";
}
.icon-feather-pointed2:before {
  content: "\f095";
}
.icon-file-arrow-down2:before {
  content: "\f096";
}
.icon-file-certificate2:before {
  content: "\f097";
}
.icon-file-check2:before {
  content: "\f098";
}
.icon-file-circle-check2:before {
  content: "\f099";
}
.icon-file-circle-plus2:before {
  content: "\f09a";
}
.icon-file-exclamation2:before {
  content: "\f09b";
}
.icon-file-invoice-dollar2:before {
  content: "\f09c";
}
.icon-file-pdf2:before {
  content: "\f09d";
}
.icon-file-user2:before {
  content: "\f09e";
}
.icon-files-medical2:before {
  content: "\f09f";
}
.icon-filter-circle-dollar2:before {
  content: "\f0a0";
}
.icon-fire-extinguisher2:before {
  content: "\f0a1";
}
.icon-fire-flame-curved2:before {
  content: "\f0a2";
}
.icon-fire-flame-simple2:before {
  content: "\f0a3";
}
.icon-fire-flame2:before {
  content: "\f0a4";
}
.icon-fire-hydrant2:before {
  content: "\f0a5";
}
.icon-fire-smoke2:before {
  content: "\f0a6";
}
.icon-fire2:before {
  content: "\f0a7";
}
.icon-fireplace2:before {
  content: "\f0a8";
}
.icon-flag-usa2:before {
  content: "\f0a9";
}
.icon-floppy-disk2:before {
  content: "\f0aa";
}
.icon-folder-arrow-down2:before {
  content: "\f0ab";
}
.icon-folder-medical2:before {
  content: "\f0ac";
}
.icon-folder-plus2:before {
  content: "\f0ad";
}
.icon-font-awesome2:before {
  content: "\f0ae";
}
.icon-forward-fast2:before {
  content: "\f0af";
}
.icon-forward-step2:before {
  content: "\f0b0";
}
.icon-forward2:before {
  content: "\f0b1";
}
.icon-gear2:before {
  content: "\f77c";
}
.icon-gift-card2:before {
  content: "\ed8a";
}
.icon-gift2:before {
  content: "\f0b2";
}
.icon-globe2:before {
  content: "\f0b3";
}
.icon-graduation-cap2:before {
  content: "\f786";
}
.icon-grate-droplet2:before {
  content: "\f0b4";
}
.icon-grate2:before {
  content: "\f0b5";
}
.icon-hammer-crash2:before {
  content: "\f0b6";
}
.icon-hand-back-fist2:before {
  content: "\f0b7";
}
.icon-hand-fingers-crossed2:before {
  content: "\f0b8";
}
.icon-hand-fist2:before {
  content: "\f0b9";
}
.icon-hand-holding-box2:before {
  content: "\f0ba";
}
.icon-hand-holding-dollar2:before {
  content: "\f0bb";
}
.icon-hand-holding-droplet2:before {
  content: "\f0bc";
}
.icon-hand-holding-hand1:before {
  content: "\f0bd";
}
.icon-hand-holding-heart2:before {
  content: "\f0be";
}
.icon-hand-holding-medical2:before {
  content: "\f0bf";
}
.icon-hand-sparkles2:before {
  content: "\f0c0";
}
.icon-hands-bubbles2:before {
  content: "\f0c1";
}
.icon-hands-holding-dollar2:before {
  content: "\f0c2";
}
.icon-hands-holding-heart2:before {
  content: "\f0c3";
}
.icon-hands-holding2:before {
  content: "\f0c4";
}
.icon-hands-praying2:before {
  content: "\f0c5";
}
.icon-hands2:before {
  content: "\f0c6";
}
.icon-handshake-angle2:before {
  content: "\f0c7";
}
.icon-handshake-simple2:before {
  content: "\f0c8";
}
.icon-handshake2:before {
  content: "\f0c9";
}
.icon-head-side-brain2:before {
  content: "\f0ca";
}
.icon-head-side-cough2:before {
  content: "\f0cb";
}
.icon-head-side-medical2:before {
  content: "\f0cc";
}
.icon-head-side-virus2:before {
  content: "\f0cd";
}
.icon-head-side2:before {
  content: "\f0ce";
}
.icon-heart-pulse3:before {
  content: "\f6f2";
}
.icon-heart2:before {
  content: "\f0cf";
}
.icon-helmet-safety2:before {
  content: "\f0d0";
}
.icon-hexagon-plus1:before {
  content: "\f0d1";
}
.icon-high-definition2:before {
  content: "\f0d2";
}
.icon-hospital2:before {
  content: "\f0d3";
}
.icon-hospitals2:before {
  content: "\f0d4";
}
.icon-hourglass-clock2:before {
  content: "\f0d5";
}
.icon-house-building:before {
  content: "\f0d6";
}
.icon-house-chimney-blank2:before {
  content: "\f0d7";
}
.icon-house-chimney-heart2:before {
  content: "\f0d8";
}
.icon-house-chimney-user2:before {
  content: "\f0d9";
}
.icon-house-chimney-window2:before {
  content: "\f0da";
}
.icon-house-chimney2:before {
  content: "\f0db";
}
.icon-house-circle-check2:before {
  content: "\f787";
}
.icon-house-heart2:before {
  content: "\f0dc";
}
.icon-house-window2:before {
  content: "\f0dd";
}
.icon-house2:before {
  content: "\f0de";
}
.icon-hundred-points4:before {
  content: "\f71b";
}
.icon-id-card2:before {
  content: "\f0df";
}
.icon-image-portrait2:before {
  content: "\f0e0";
}
.icon-images2:before {
  content: "\f0e1";
}
.icon-inbox-in2:before {
  content: "\f0e2";
}
.icon-infinity2:before {
  content: "\f788";
}
.icon-key-skeleton2:before {
  content: "\f0e3";
}
.icon-key1:before {
  content: "\f0e4";
}
.icon-kit-medical2:before {
  content: "\f0e5";
}
.icon-laptop-arrow-down2:before {
  content: "\f0e6";
}
.icon-leaf-heart2:before {
  content: "\f0e7";
}
.icon-leaf-maple2:before {
  content: "\f0e8";
}
.icon-leaf-oak2:before {
  content: "\f0e9";
}
.icon-leaf2:before {
  content: "\f0ea";
}
.icon-left-from-line2:before {
  content: "\f0eb";
}
.icon-left-long-to-line2:before {
  content: "\f0ec";
}
.icon-left-long2:before {
  content: "\f0ed";
}
.icon-left-to-line2:before {
  content: "\f0ee";
}
.icon-left2:before {
  content: "\f0ef";
}
.icon-lightbulb-dollar2:before {
  content: "\f0f0";
}
.icon-lightbulb-exclamation-on2:before {
  content: "\f0f1";
}
.icon-lightbulb-exclamation2:before {
  content: "\f0f2";
}
.icon-lightbulb-on2:before {
  content: "\f0f3";
}
.icon-lightbulb2:before {
  content: "\f0f4";
}
.icon-list-ul2:before {
  content: "\f0f5";
}
.icon-location-arrow2:before {
  content: "\f0f6";
}
.icon-location-check2:before {
  content: "\f0f7";
}
.icon-location-dot2:before {
  content: "\f666";
}
.icon-location-exclamation2:before {
  content: "\f667";
}
.icon-location-pin2:before {
  content: "\f668";
}
.icon-magnifying-glass-dollar2:before {
  content: "\f0f8";
}
.icon-magnifying-glass-location2:before {
  content: "\f0f9";
}
.icon-magnifying-glass-minus3:before {
  content: "\f67a";
}
.icon-magnifying-glass-plus4:before {
  content: "\f67b";
}
.icon-magnifying-glass1:before {
  content: "\f0fa";
}
.icon-map-location-dot2:before {
  content: "\f0fb";
}
.icon-map-location2:before {
  content: "\f0fc";
}
.icon-map-pin2:before {
  content: "\f0fd";
}
.icon-map2:before {
  content: "\f0fe";
}
.icon-medal2:before {
  content: "\f76c";
}
.icon-megaphone2:before {
  content: "\f764";
}
.icon-message-arrow-down2:before {
  content: "\f0ff";
}
.icon-message-arrow-up-right2:before {
  content: "\f100";
}
.icon-message-arrow-up2:before {
  content: "\f101";
}
.icon-message-check2:before {
  content: "\f102";
}
.icon-message-dollar2:before {
  content: "\f103";
}
.icon-message-dots2:before {
  content: "\f104";
}
.icon-message-lines1:before {
  content: "\f6ae";
}
.icon-message-medical2:before {
  content: "\f105";
}
.icon-message-plus2:before {
  content: "\f106";
}
.icon-message-quote2:before {
  content: "\f107";
}
.icon-message-smile2:before {
  content: "\f108";
}
.icon-messages-dollar2:before {
  content: "\f109";
}
.icon-messages2:before {
  content: "\f10a";
}
.icon-meter-bolt2:before {
  content: "\f10b";
}
.icon-meter-droplet2:before {
  content: "\f10c";
}
.icon-meter-fire2:before {
  content: "\f10d";
}
.icon-meter2:before {
  content: "\f10e";
}
.icon-microchip:before {
  content: "\f6af";
}
.icon-microphone2:before {
  content: "\f10f";
}
.icon-minus2:before {
  content: "\f6b0";
}
.icon-mobile-button2:before {
  content: "\f742";
}
.icon-mobile-screen-button2:before {
  content: "\f743";
}
.icon-mobile-screen2:before {
  content: "\f744";
}
.icon-mobile2:before {
  content: "\f110";
}
.icon-money-bill-1-wave2:before {
  content: "\f111";
}
.icon-money-bill-12:before {
  content: "\f112";
}
.icon-money-bill-simple-wave2:before {
  content: "\f113";
}
.icon-money-bill-simple2:before {
  content: "\f114";
}
.icon-money-bill-wave2:before {
  content: "\f115";
}
.icon-money-bill2:before {
  content: "\f116";
}
.icon-money-check-dollar-pen2:before {
  content: "\f117";
}
.icon-money-check-dollar2:before {
  content: "\f118";
}
.icon-money-check-pen2:before {
  content: "\f119";
}
.icon-money-check2:before {
  content: "\f11a";
}
.icon-octagon-check2:before {
  content: "\f11b";
}
.icon-octagon-plus2:before {
  content: "\f11c";
}
.icon-page-caret-down2:before {
  content: "\f11d";
}
.icon-page-caret-up2:before {
  content: "\f11e";
}
.icon-pallet-box2:before {
  content: "\f11f";
}
.icon-pallet-boxes2:before {
  content: "\f120";
}
.icon-panel-ews2:before {
  content: "\f121";
}
.icon-panel-fire2:before {
  content: "\f122";
}
.icon-paper-plane2:before {
  content: "\f123";
}
.icon-pen-circle2:before {
  content: "\f124";
}
.icon-pen-field2:before {
  content: "\f7a9";
}
.icon-pen-line2:before {
  content: "\f125";
}
.icon-pen-nib2:before {
  content: "\f126";
}
.icon-pen-paintbrush2:before {
  content: "\f127";
}
.icon-pen-ruler2:before {
  content: "\f128";
}
.icon-pen-to-square2:before {
  content: "\f129";
}
.icon-pen2:before {
  content: "\f12a";
}
.icon-pencil2:before {
  content: "\f12b";
}
.icon-people-arrows-left-right2:before {
  content: "\f12c";
}
.icon-people-carry-box2:before {
  content: "\f12d";
}
.icon-people-group2:before {
  content: "\f12e";
}
.icon-people-roof1:before {
  content: "\f772";
}
.icon-people-simple2:before {
  content: "\f12f";
}
.icon-person-carry-box2:before {
  content: "\f130";
}
.icon-person-circle-check2:before {
  content: "\f131";
}
.icon-person-digging2:before {
  content: "\f132";
}
.icon-person-dolly-empty2:before {
  content: "\f133";
}
.icon-person-dolly2:before {
  content: "\f134";
}
.icon-person-dress-simple2:before {
  content: "\f135";
}
.icon-person-running2:before {
  content: "\f136";
}
.icon-person-to-door2:before {
  content: "\f137";
}
.icon-person-walking2:before {
  content: "\f138";
}
.icon-phone-arrow-up-right2:before {
  content: "\f139";
}
.icon-phone-flip2:before {
  content: "\f6b1";
}
.icon-phone-plus2:before {
  content: "\f13a";
}
.icon-phone-rotary2:before {
  content: "\f797";
}
.icon-phone-volume2:before {
  content: "\f709";
}
.icon-phone3:before {
  content: "\f6b2";
}
.icon-piggy-bank3:before {
  content: "\f6b3";
}
.icon-pipe-circle-check2:before {
  content: "\f13b";
}
.icon-pipe-collar2:before {
  content: "\f13c";
}
.icon-pipe-section2:before {
  content: "\f13d";
}
.icon-pipe-valve2:before {
  content: "\f13e";
}
.icon-plug-circle-bolt2:before {
  content: "\f13f";
}
.icon-plug-circle-xmark2:before {
  content: "\f140";
}
.icon-plug2:before {
  content: "\f141";
}
.icon-plus-large2:before {
  content: "\f6b4";
}
.icon-plus-minus2:before {
  content: "\f142";
}
.icon-plus2:before {
  content: "\f6b5";
}
.icon-pump2:before {
  content: "\f143";
}
.icon-question2:before {
  content: "\f144";
}
.icon-quote-left2:before {
  content: "\f145";
}
.icon-quote-right2:before {
  content: "\f146";
}
.icon-quotes2:before {
  content: "\f147";
}
.icon-raindrops2:before {
  content: "\f789";
}
.icon-rectangle-ad2:before {
  content: "\f148";
}
.icon-rectangle-list2:before {
  content: "\f751";
}
.icon-right-from-line2:before {
  content: "\f149";
}
.icon-right-long-to-line2:before {
  content: "\f14a";
}
.icon-right-to-bracket2:before {
  content: "\f14b";
}
.icon-right-to-line2:before {
  content: "\f14c";
}
.icon-right4:before {
  content: "\f71c";
}
.icon-route2:before {
  content: "\f14d";
}
.icon-sack-dollar2:before {
  content: "\f14e";
}
.icon-sack2:before {
  content: "\f14f";
}
.icon-scale-balanced2:before {
  content: "\f150";
}
.icon-scale-unbalanced-flip2:before {
  content: "\f151";
}
.icon-scale-unbalanced2:before {
  content: "\f152";
}
.icon-school-flag2:before {
  content: "\f153";
}
.icon-school2:before {
  content: "\f154";
}
.icon-screwdriver-wrench2:before {
  content: "\f155";
}
.icon-screwdriver2:before {
  content: "\f156";
}
.icon-seal-exclamation2:before {
  content: "\f157";
}
.icon-sensor-cloud2:before {
  content: "\f158";
}
.icon-sensor-fire2:before {
  content: "\f159";
}
.icon-sensor-on2:before {
  content: "\f15a";
}
.icon-sensor-triangle-exclamation2:before {
  content: "\f15b";
}
.icon-sensor2:before {
  content: "\f15c";
}
.icon-share-from-square2:before {
  content: "\f15d";
}
.icon-shield-check2:before {
  content: "\f15e";
}
.icon-shield-halved2:before {
  content: "\f15f";
}
.icon-shield-plus2:before {
  content: "\f160";
}
.icon-shield-quartered2:before {
  content: "\f161";
}
.icon-shield-virus2:before {
  content: "\f162";
}
.icon-shield-xmark2:before {
  content: "\f163";
}
.icon-shield2:before {
  content: "\f164";
}
.icon-shovel2:before {
  content: "\f165";
}
.icon-shower-down2:before {
  content: "\f166";
}
.icon-shower2:before {
  content: "\f167";
}
.icon-sink2:before {
  content: "\f168";
}
.icon-snow-blowing2:before {
  content: "\f169";
}
.icon-snowflake2:before {
  content: "\f16a";
}
.icon-snowflakes2:before {
  content: "\f16b";
}
.icon-snowplow2:before {
  content: "\f16c";
}
.icon-soap2:before {
  content: "\f16d";
}
.icon-sort-down1:before {
  content: "\f71d";
}
.icon-sparkles:before {
  content: "\f16e";
}
.icon-splotch2:before {
  content: "\f16f";
}
.icon-square-arrow-down-left2:before {
  content: "\f170";
}
.icon-square-arrow-down-right2:before {
  content: "\f171";
}
.icon-square-arrow-down2:before {
  content: "\f172";
}
.icon-square-arrow-left2:before {
  content: "\f173";
}
.icon-square-arrow-right2:before {
  content: "\f174";
}
.icon-square-arrow-up-left2:before {
  content: "\f175";
}
.icon-square-arrow-up-right2:before {
  content: "\f176";
}
.icon-square-arrow-up2:before {
  content: "\f177";
}
.icon-square-caret-down2:before {
  content: "\f178";
}
.icon-square-caret-left2:before {
  content: "\f179";
}
.icon-square-caret-right2:before {
  content: "\f17a";
}
.icon-square-caret-up2:before {
  content: "\f17b";
}
.icon-square-check2:before {
  content: "\f17c";
}
.icon-square-chevron-down2:before {
  content: "\f17d";
}
.icon-square-chevron-left2:before {
  content: "\f17e";
}
.icon-square-chevron-right2:before {
  content: "\f17f";
}
.icon-square-chevron-up2:before {
  content: "\f180";
}
.icon-square-dollar2:before {
  content: "\f181";
}
.icon-square-down-left2:before {
  content: "\f182";
}
.icon-square-down-right2:before {
  content: "\f183";
}
.icon-square-down2:before {
  content: "\f184";
}
.icon-square-envelope2:before {
  content: "\f185";
}
.icon-square-exclamation2:before {
  content: "\f186";
}
.icon-square-left2:before {
  content: "\f187";
}
.icon-square-minus2:before {
  content: "\f6b6";
}
.icon-square-pen2:before {
  content: "\f188";
}
.icon-square-phone2:before {
  content: "\f189";
}
.icon-square-plus1:before {
  content: "\f6b7";
}
.icon-square-quote2:before {
  content: "\f18a";
}
.icon-square-up-left2:before {
  content: "\f18b";
}
.icon-square-up-right2:before {
  content: "\f18c";
}
.icon-square-up1:before {
  content: "\f18d";
}
.icon-star-christmas2:before {
  content: "\f18e";
}
.icon-star-half2:before {
  content: "\f18f";
}
.icon-star-sharp2:before {
  content: "\f6b8";
}
.icon-star-shooting2:before {
  content: "\f190";
}
.icon-star2:before {
  content: "\f6b9";
}
.icon-stars2:before {
  content: "\f191";
}
.icon-stopwatch2:before {
  content: "\f192";
}
.icon-suitcase-medical2:before {
  content: "\f193";
}
.icon-suitcase-rolling2:before {
  content: "\f194";
}
.icon-suitcase2:before {
  content: "\f195";
}
.icon-tag2:before {
  content: "\f196";
}
.icon-tags2:before {
  content: "\f197";
}
.icon-tank-water2:before {
  content: "\f198";
}
.icon-taxi2:before {
  content: "\f199";
}
.icon-temperature-arrow-down2:before {
  content: "\f19a";
}
.icon-temperature-arrow-up2:before {
  content: "\f19b";
}
.icon-temperature-empty2:before {
  content: "\f19c";
}
.icon-temperature-full2:before {
  content: "\f19d";
}
.icon-temperature-half2:before {
  content: "\f19e";
}
.icon-temperature-high2:before {
  content: "\f19f";
}
.icon-temperature-list2:before {
  content: "\f1a0";
}
.icon-temperature-low2:before {
  content: "\f1a1";
}
.icon-temperature-quarter2:before {
  content: "\f1a2";
}
.icon-temperature-snow2:before {
  content: "\f1a3";
}
.icon-temperature-sun2:before {
  content: "\f1a4";
}
.icon-temperature-three-quarters2:before {
  content: "\f1a5";
}
.icon-thumbs-down2:before {
  content: "\f1a6";
}
.icon-thumbs-up2:before {
  content: "\f1a7";
}
.icon-ticket:before {
  content: "\f6e3";
}
.icon-timer2:before {
  content: "\f1a8";
}
.icon-toilet2:before {
  content: "\f1a9";
}
.icon-toolbox2:before {
  content: "\f1aa";
}
.icon-tooth4:before {
  content: "\f6ba";
}
.icon-train-track2:before {
  content: "\f1ab";
}
.icon-trash-can-check2:before {
  content: "\f1ac";
}
.icon-trash-check2:before {
  content: "\f1ad";
}
.icon-trash-plus2:before {
  content: "\f1ae";
}
.icon-treasure-chest2:before {
  content: "\f1af";
}
.icon-triangle-exclamation2:before {
  content: "\f1b0";
}
.icon-triangle-person-digging2:before {
  content: "\f1b1";
}
.icon-trophy-star2:before {
  content: "\f1b2";
}
.icon-trophy2:before {
  content: "\f1b3";
}
.icon-truck-clock2:before {
  content: "\f1b4";
}
.icon-truck-fast2:before {
  content: "\f1b5";
}
.icon-truck-field2:before {
  content: "\f1b6";
}
.icon-truck-flatbed2:before {
  content: "\f1b7";
}
.icon-truck-front2:before {
  content: "\f1b8";
}
.icon-truck-medical2:before {
  content: "\f1b9";
}
.icon-truck-moving2:before {
  content: "\f1ba";
}
.icon-truck-plow2:before {
  content: "\f1bb";
}
.icon-truck-ramp-box2:before {
  content: "\f1bc";
}
.icon-truck-ramp2:before {
  content: "\f1bd";
}
.icon-truck2:before {
  content: "\f1be";
}
.icon-tty-answer2:before {
  content: "\f1bf";
}
.icon-turn-down-left2:before {
  content: "\f1c0";
}
.icon-turn-down-right2:before {
  content: "\f1c1";
}
.icon-turn-down2:before {
  content: "\f1c2";
}
.icon-turn-up2:before {
  content: "\f1c3";
}
.icon-umbrella-beach:before {
  content: "\f6f3";
}
.icon-up-left2:before {
  content: "\f1c4";
}
.icon-up-right-from-square2:before {
  content: "\f1c5";
}
.icon-up-right2:before {
  content: "\f1c6";
}
.icon-up-to-line2:before {
  content: "\f1c7";
}
.icon-user-check2:before {
  content: "\f1c8";
}
.icon-user-clock2:before {
  content: "\f1c9";
}
.icon-user-doctor-message2:before {
  content: "\f1ca";
}
.icon-user-doctor2:before {
  content: "\f1cb";
}
.icon-user-group2:before {
  content: "\f1cc";
}
.icon-user-hair-long2:before {
  content: "\f1cd";
}
.icon-user-headset2:before {
  content: "\f752";
}
.icon-user-helmet-safety2:before {
  content: "\f1ce";
}
.icon-user-large2:before {
  content: "\f1cf";
}
.icon-user-pen2:before {
  content: "\f1d0";
}
.icon-user-plus2:before {
  content: "\f1d1";
}
.icon-user-robot-xmarks2:before {
  content: "\f1d2";
}
.icon-user-robot2:before {
  content: "\f1d3";
}
.icon-user-shield2:before {
  content: "\f1d4";
}
.icon-user-tag2:before {
  content: "\f1d5";
}
.icon-user-tie2:before {
  content: "\f1d6";
}
.icon-user2:before {
  content: "\f1d7";
}
.icon-users-between-lines2:before {
  content: "\f1d8";
}
.icon-users2:before {
  content: "\f1d9";
}
.icon-van-shuttle2:before {
  content: "\f1da";
}
.icon-video2:before {
  content: "\f1db";
}
.icon-viruses2:before {
  content: "\f1dc";
}
.icon-volume-high2:before {
  content: "\f1dd";
}
.icon-volume-low2:before {
  content: "\f1de";
}
.icon-volume-off2:before {
  content: "\f1df";
}
.icon-volume-slash2:before {
  content: "\f1e0";
}
.icon-volume-xmark2:before {
  content: "\f1e1";
}
.icon-volume2:before {
  content: "\f1e2";
}
.icon-wallet2:before {
  content: "\f1e3";
}
.icon-warehouse-full2:before {
  content: "\f1e4";
}
.icon-warehouse2:before {
  content: "\f1e5";
}
.icon-water2:before {
  content: "\f732";
}
.icon-wheelchair-move2:before {
  content: "\f1e6";
}
.icon-wind-warning2:before {
  content: "\f1e7";
}
.icon-wind2:before {
  content: "\f1e8";
}
.icon-window-flip2:before {
  content: "\f1e9";
}
.icon-window-frame-open2:before {
  content: "\f1ea";
}
.icon-window-frame2:before {
  content: "\f1eb";
}
.icon-window-minimize2:before {
  content: "\f6bb";
}
.icon-window2:before {
  content: "\f1ec";
}
.icon-wrench-simple1:before {
  content: "\f1ed";
}
.icon-wrench2:before {
  content: "\f1ee";
}
.icon-x1:before {
  content: "\f1ef";
}
.icon-xmark-large1:before {
  content: "\f1f0";
}
.icon-xmark1:before {
  content: "\f1f1";
}
.icon-address-card1:before {
  content: "\ed8b";
}
.icon-air-conditioner1:before {
  content: "\ed8c";
}
.icon-alarm-clock1:before {
  content: "\ed8d";
}
.icon-alarm-exclamation1:before {
  content: "\ed8e";
}
.icon-angle-down1:before {
  content: "\ed8f";
}
.icon-angle-left1:before {
  content: "\ed90";
}
.icon-angle-right1:before {
  content: "\ed91";
}
.icon-angle-up1:before {
  content: "\ed92";
}
.icon-angles-down1:before {
  content: "\ed93";
}
.icon-angles-left1:before {
  content: "\ed94";
}
.icon-angles-right1:before {
  content: "\ed95";
}
.icon-angles-up1:before {
  content: "\ed96";
}
.icon-apple-whole1:before {
  content: "\ed97";
}
.icon-archway1:before {
  content: "\ed98";
}
.icon-arrow-down-arrow-up1:before {
  content: "\ed99";
}
.icon-arrow-down-from-line1:before {
  content: "\ed9a";
}
.icon-arrow-down-left-and-arrow-up-right-to-center1:before {
  content: "\ed9b";
}
.icon-arrow-down-left1:before {
  content: "\ed9c";
}
.icon-arrow-down-long1:before {
  content: "\ed9d";
}
.icon-arrow-down-right1:before {
  content: "\ed9e";
}
.icon-arrow-down-to-bracket1:before {
  content: "\ed9f";
}
.icon-arrow-down-to-line1:before {
  content: "\eda0";
}
.icon-arrow-down-to-square1:before {
  content: "\eda1";
}
.icon-arrow-down1:before {
  content: "\eda2";
}
.icon-arrow-left-from-line1:before {
  content: "\eda3";
}
.icon-arrow-left-long-to-line1:before {
  content: "\eda4";
}
.icon-arrow-left-long1:before {
  content: "\eda5";
}
.icon-arrow-left-to-line1:before {
  content: "\eda6";
}
.icon-arrow-left1:before {
  content: "\eda7";
}
.icon-arrow-right-arrow-left1:before {
  content: "\eda8";
}
.icon-arrow-right-from-line1:before {
  content: "\eda9";
}
.icon-arrow-right-long-to-line1:before {
  content: "\edaa";
}
.icon-arrow-right-long1:before {
  content: "\edab";
}
.icon-arrow-right-to-line1:before {
  content: "\edac";
}
.icon-arrow-right1:before {
  content: "\edad";
}
.icon-arrow-turn-down-left1:before {
  content: "\edae";
}
.icon-arrow-turn-down-right1:before {
  content: "\edaf";
}
.icon-arrow-turn-down1:before {
  content: "\edb0";
}
.icon-arrow-turn-up1:before {
  content: "\edb1";
}
.icon-arrow-up-from-line1:before {
  content: "\edb2";
}
.icon-arrow-up-left1:before {
  content: "\edb3";
}
.icon-arrow-up-long1:before {
  content: "\edb4";
}
.icon-arrow-up-right1:before {
  content: "\edb5";
}
.icon-arrow-up-to-line1:before {
  content: "\edb6";
}
.icon-arrow-up1:before {
  content: "\edb7";
}
.icon-arrows-left-right1:before {
  content: "\edb8";
}
.icon-arrows-maximize1:before {
  content: "\edb9";
}
.icon-arrows-repeat1:before {
  content: "\edba";
}
.icon-arrows-retweet1:before {
  content: "\edbb";
}
.icon-audio-description1:before {
  content: "\edbc";
}
.icon-award-simple1:before {
  content: "\edbd";
}
.icon-award1:before {
  content: "\edbe";
}
.icon-badge-check1:before {
  content: "\edbf";
}
.icon-badge-dollar1:before {
  content: "\edc0";
}
.icon-badge-percent1:before {
  content: "\edc1";
}
.icon-badge1:before {
  content: "\edc2";
}
.icon-ballot-check1:before {
  content: "\edc3";
}
.icon-ballot1:before {
  content: "\edc4";
}
.icon-bars1:before {
  content: "\edc5";
}
.icon-bed-pulse4:before {
  content: "\f69c";
}
.icon-bell-on1:before {
  content: "\edc6";
}
.icon-bell1:before {
  content: "\edc7";
}
.icon-blanket1:before {
  content: "\edc8";
}
.icon-bolt-lightning3:before {
  content: "\f71e";
}
.icon-bolt3:before {
  content: "\f71f";
}
.icon-book-blank1:before {
  content: "\edc9";
}
.icon-book-open-cover1:before {
  content: "\edca";
}
.icon-book-open-reader1:before {
  content: "\edcb";
}
.icon-books1:before {
  content: "\edcc";
}
.icon-box-check1:before {
  content: "\edcd";
}
.icon-box-dollar1:before {
  content: "\edce";
}
.icon-box-heart1:before {
  content: "\edcf";
}
.icon-box-open1:before {
  content: "\edd0";
}
.icon-box-taped1:before {
  content: "\edd1";
}
.icon-box1:before {
  content: "\edd2";
}
.icon-brain1:before {
  content: "\edd3";
}
.icon-brake-warning1:before {
  content: "\edd4";
}
.icon-briefcase-blank1:before {
  content: "\edd5";
}
.icon-briefcase-medical1:before {
  content: "\edd6";
}
.icon-briefcase1:before {
  content: "\edd7";
}
.icon-brightness1:before {
  content: "\edd8";
}
.icon-broom-ball1:before {
  content: "\edd9";
}
.icon-broom1:before {
  content: "\edda";
}
.icon-browser1:before {
  content: "\eddb";
}
.icon-browsers1:before {
  content: "\eddc";
}
.icon-building-circle-check1:before {
  content: "\eddd";
}
.icon-building-columns1:before {
  content: "\f6ec";
}
.icon-building-shield1:before {
  content: "\edde";
}
.icon-building-user1:before {
  content: "\eddf";
}
.icon-building1:before {
  content: "\ede0";
}
.icon-buildings1:before {
  content: "\ede1";
}
.icon-bullhorn3:before {
  content: "\f765";
}
.icon-business-time1:before {
  content: "\ede2";
}
.icon-cabinet-filing1:before {
  content: "\ede3";
}
.icon-calculator-simple1:before {
  content: "\ede4";
}
.icon-calendar-check1:before {
  content: "\ede5";
}
.icon-calendar-circle-exclamation1:before {
  content: "\ede6";
}
.icon-calendar-circle-user1:before {
  content: "\ede7";
}
.icon-calendar-clock1:before {
  content: "\ede8";
}
.icon-calendar-days1:before {
  content: "\ede9";
}
.icon-calendar-lines-pen1:before {
  content: "\edea";
}
.icon-calendar-pen1:before {
  content: "\edeb";
}
.icon-calendar-plus3:before {
  content: "\f70a";
}
.icon-calendar-star1:before {
  content: "\edec";
}
.icon-calendar-xmark1:before {
  content: "\eded";
}
.icon-calendar1:before {
  content: "\edee";
}
.icon-caret-down1:before {
  content: "\edef";
}
.icon-caret-left1:before {
  content: "\edf0";
}
.icon-caret-right1:before {
  content: "\edf1";
}
.icon-caret-up1:before {
  content: "\edf2";
}
.icon-cart-arrow-down1:before {
  content: "\edf3";
}
.icon-cart-arrow-up1:before {
  content: "\edf4";
}
.icon-cart-circle-arrow-down1:before {
  content: "\edf5";
}
.icon-cart-circle-check1:before {
  content: "\edf6";
}
.icon-cart-circle-plus1:before {
  content: "\edf7";
}
.icon-cart-flatbed-boxes1:before {
  content: "\edf8";
}
.icon-cart-flatbed-empty1:before {
  content: "\edf9";
}
.icon-cart-flatbed-suitcase1:before {
  content: "\edfa";
}
.icon-cart-flatbed1:before {
  content: "\edfb";
}
.icon-cart-shopping-fast1:before {
  content: "\edfc";
}
.icon-cart-shopping1:before {
  content: "\edfd";
}
.icon-chalkboard-user1:before {
  content: "\edfe";
}
.icon-chart-column1:before {
  content: "\edff";
}
.icon-chart-line-down1:before {
  content: "\ee00";
}
.icon-chart-line-up1:before {
  content: "\ee01";
}
.icon-chart-line1:before {
  content: "\ee02";
}
.icon-check2:before {
  content: "\ee03";
}
.icon-chevron-down1:before {
  content: "\ee04";
}
.icon-chevron-left1:before {
  content: "\ee05";
}
.icon-chevron-right1:before {
  content: "\ee06";
}
.icon-chevron-up1:before {
  content: "\ee07";
}
.icon-chevrons-down1:before {
  content: "\ee08";
}
.icon-chevrons-left1:before {
  content: "\ee09";
}
.icon-chevrons-right1:before {
  content: "\ee0a";
}
.icon-chevrons-up1:before {
  content: "\ee0b";
}
.icon-circle-arrow-down-left1:before {
  content: "\ee0c";
}
.icon-circle-arrow-down-right1:before {
  content: "\ee0d";
}
.icon-circle-arrow-down1:before {
  content: "\ee0e";
}
.icon-circle-arrow-left1:before {
  content: "\ee0f";
}
.icon-circle-arrow-right1:before {
  content: "\ee10";
}
.icon-circle-arrow-up-left1:before {
  content: "\ee11";
}
.icon-circle-arrow-up-right1:before {
  content: "\ee12";
}
.icon-circle-arrow-up1:before {
  content: "\ee13";
}
.icon-circle-caret-down1:before {
  content: "\ee14";
}
.icon-circle-caret-left1:before {
  content: "\ee15";
}
.icon-circle-caret-right1:before {
  content: "\ee16";
}
.icon-circle-caret-up1:before {
  content: "\ee17";
}
.icon-circle-check1:before {
  content: "\ee18";
}
.icon-circle-chevron-down1:before {
  content: "\ee19";
}
.icon-circle-chevron-left1:before {
  content: "\ee1a";
}
.icon-circle-chevron-right1:before {
  content: "\ee1b";
}
.icon-circle-chevron-up1:before {
  content: "\ee1c";
}
.icon-circle-dollar1:before {
  content: "\ee1d";
}
.icon-circle-down-left1:before {
  content: "\ee1e";
}
.icon-circle-down-right1:before {
  content: "\ee1f";
}
.icon-circle-down1:before {
  content: "\ee20";
}
.icon-circle-envelope1:before {
  content: "\ee21";
}
.icon-circle-exclamation1:before {
  content: "\ee22";
}
.icon-circle-g:before {
  content: "\f78a";
}
.icon-circle-location-arrow1:before {
  content: "\ee23";
}
.icon-circle-minus1:before {
  content: "\f6ed";
}
.icon-circle-pause1:before {
  content: "\ee24";
}
.icon-circle-phone1:before {
  content: "\ee25";
}
.icon-circle-plus1:before {
  content: "\ee26";
}
.icon-circle-right1:before {
  content: "\ee27";
}
.icon-circle-star1:before {
  content: "\ee28";
}
.icon-circle-up-left1:before {
  content: "\ee29";
}
.icon-circle-up-right1:before {
  content: "\ee2a";
}
.icon-circle-up1:before {
  content: "\ee2b";
}
.icon-circle-user3:before {
  content: "\f7a5";
}
.icon-circle-video1:before {
  content: "\ee2c";
}
.icon-clipboard-list-check1:before {
  content: "\ee2d";
}
.icon-clipboard-list1:before {
  content: "\ee2e";
}
.icon-clipboard-medical1:before {
  content: "\ee2f";
}
.icon-clipboard-prescription1:before {
  content: "\f69d";
}
.icon-clipboard-user1:before {
  content: "\ee30";
}
.icon-clock-desk1:before {
  content: "\ee31";
}
.icon-clock-rotate-left1:before {
  content: "\ee32";
}
.icon-clock1:before {
  content: "\ee33";
}
.icon-cloud-arrow-down1:before {
  content: "\ee34";
}
.icon-cloud-bolt1:before {
  content: "\ee35";
}
.icon-cloud-check1:before {
  content: "\ee36";
}
.icon-coins1:before {
  content: "\ee37";
}
.icon-comment-check1:before {
  content: "\ee38";
}
.icon-comment-dollar1:before {
  content: "\ee39";
}
.icon-comment-quote1:before {
  content: "\ee3a";
}
.icon-comment-smile1:before {
  content: "\ee3b";
}
.icon-comment-sms1:before {
  content: "\ee3c";
}
.icon-comment1:before {
  content: "\ee3d";
}
.icon-comments-dollar1:before {
  content: "\ee3e";
}
.icon-comments-question3:before {
  content: "\f77d";
}
.icon-comments1:before {
  content: "\ee3f";
}
.icon-conveyor-belt1:before {
  content: "\ee40";
}
.icon-copyright1:before {
  content: "\ee41";
}
.icon-credit-card-blank1:before {
  content: "\ee42";
}
.icon-credit-card-front1:before {
  content: "\ee43";
}
.icon-credit-card1:before {
  content: "\ee44";
}
.icon-dash2:before {
  content: "\f661";
}
.icon-diploma1:before {
  content: "\ee45";
}
.icon-dollar-sign3:before {
  content: "\f75b";
}
.icon-dolly-empty1:before {
  content: "\ee46";
}
.icon-dolly1:before {
  content: "\ee47";
}
.icon-door-open1:before {
  content: "\ee48";
}
.icon-down-from-dotted-line:before {
  content: "\ee49";
}
.icon-down-from-line1:before {
  content: "\ee4a";
}
.icon-down-left-and-up-right-to-center1:before {
  content: "\ee4b";
}
.icon-down-left1:before {
  content: "\ee4c";
}
.icon-down-long1:before {
  content: "\ee4d";
}
.icon-down-right1:before {
  content: "\ee4e";
}
.icon-down-to-bracket1:before {
  content: "\ee4f";
}
.icon-down-to-line1:before {
  content: "\ee50";
}
.icon-down1:before {
  content: "\ee51";
}
.icon-droplet-degree3:before {
  content: "\f733";
}
.icon-droplet3:before {
  content: "\f734";
}
.icon-earth-americas1:before {
  content: "\ee52";
}
.icon-envelope-circle-check1:before {
  content: "\ee53";
}
.icon-envelope-open-dollar1:before {
  content: "\ee54";
}
.icon-envelope-open-text1:before {
  content: "\ee55";
}
.icon-envelope1:before {
  content: "\ee56";
}
.icon-eye1:before {
  content: "\ee57";
}
.icon-face-grin-wide1:before {
  content: "\ee58";
}
.icon-face-relieved1:before {
  content: "\ee59";
}
.icon-face-smile-plus1:before {
  content: "\ee5a";
}
.icon-face-smile1:before {
  content: "\ee5b";
}
.icon-family-dress1:before {
  content: "\ee5c";
}
.icon-family-pants1:before {
  content: "\ee5d";
}
.icon-family1:before {
  content: "\ee5e";
}
.icon-fan-table1:before {
  content: "\ee5f";
}
.icon-fan1:before {
  content: "\ee60";
}
.icon-faucet-drip1:before {
  content: "\ee61";
}
.icon-faucet1:before {
  content: "\ee62";
}
.icon-fax1:before {
  content: "\ee63";
}
.icon-feather-pointed1:before {
  content: "\ee64";
}
.icon-file-arrow-down1:before {
  content: "\ee65";
}
.icon-file-certificate1:before {
  content: "\ee66";
}
.icon-file-check1:before {
  content: "\ee67";
}
.icon-file-circle-check1:before {
  content: "\ee68";
}
.icon-file-circle-plus1:before {
  content: "\ee69";
}
.icon-file-exclamation1:before {
  content: "\ee6a";
}
.icon-file-invoice-dollar1:before {
  content: "\ee6b";
}
.icon-file-pdf1:before {
  content: "\ee6c";
}
.icon-file-user1:before {
  content: "\ee6d";
}
.icon-files-medical1:before {
  content: "\ee6e";
}
.icon-filter-circle-dollar1:before {
  content: "\ee6f";
}
.icon-fire-extinguisher1:before {
  content: "\ee70";
}
.icon-fire-flame-curved1:before {
  content: "\ee71";
}
.icon-fire-flame-simple1:before {
  content: "\ee72";
}
.icon-fire-flame1:before {
  content: "\ee73";
}
.icon-fire-hydrant1:before {
  content: "\ee74";
}
.icon-fire-smoke1:before {
  content: "\ee75";
}
.icon-fire1:before {
  content: "\ee76";
}
.icon-fireplace1:before {
  content: "\ee77";
}
.icon-flag-usa1:before {
  content: "\ee78";
}
.icon-floppy-disk1:before {
  content: "\ee79";
}
.icon-folder-arrow-down1:before {
  content: "\ee7a";
}
.icon-folder-medical1:before {
  content: "\ee7b";
}
.icon-folder-plus1:before {
  content: "\ee7c";
}
.icon-font-awesome1:before {
  content: "\ee7d";
}
.icon-forward-fast1:before {
  content: "\ee7e";
}
.icon-forward-step1:before {
  content: "\ee7f";
}
.icon-forward1:before {
  content: "\ee80";
}
.icon-gear3:before {
  content: "\f77e";
}
.icon-gift-card3:before {
  content: "\ee81";
}
.icon-gift1:before {
  content: "\ee82";
}
.icon-globe1:before {
  content: "\ee83";
}
.icon-graduation-cap3:before {
  content: "\f78b";
}
.icon-grate-droplet1:before {
  content: "\ee84";
}
.icon-grate1:before {
  content: "\ee85";
}
.icon-hammer-crash1:before {
  content: "\ee86";
}
.icon-hand-back-fist1:before {
  content: "\ee87";
}
.icon-hand-fingers-crossed1:before {
  content: "\ee88";
}
.icon-hand-fist1:before {
  content: "\ee89";
}
.icon-hand-holding-box1:before {
  content: "\ee8a";
}
.icon-hand-holding-dollar1:before {
  content: "\ee8b";
}
.icon-hand-holding-droplet1:before {
  content: "\ee8c";
}
.icon-hand-holding-heart1:before {
  content: "\ee8d";
}
.icon-hand-holding-medical1:before {
  content: "\ee8e";
}
.icon-hand-sparkles1:before {
  content: "\ee8f";
}
.icon-hands-bubbles1:before {
  content: "\ee90";
}
.icon-hands-holding-dollar1:before {
  content: "\ee91";
}
.icon-hands-holding-heart1:before {
  content: "\ee92";
}
.icon-hands-holding1:before {
  content: "\ee93";
}
.icon-hands-praying1:before {
  content: "\ee94";
}
.icon-hands1:before {
  content: "\ee95";
}
.icon-handshake-angle1:before {
  content: "\ee96";
}
.icon-handshake-simple1:before {
  content: "\ee97";
}
.icon-handshake1:before {
  content: "\ee98";
}
.icon-head-side-brain1:before {
  content: "\ee99";
}
.icon-head-side-cough1:before {
  content: "\ee9a";
}
.icon-head-side-medical1:before {
  content: "\ee9b";
}
.icon-head-side-virus1:before {
  content: "\ee9c";
}
.icon-head-side1:before {
  content: "\ee9d";
}
.icon-heart-pulse1:before {
  content: "\f6ee";
}
.icon-heart1:before {
  content: "\ee9e";
}
.icon-helmet-safety1:before {
  content: "\ee9f";
}
.icon-high-definition1:before {
  content: "\eea0";
}
.icon-hospital1:before {
  content: "\eea1";
}
.icon-hospitals1:before {
  content: "\eea2";
}
.icon-hourglass-clock1:before {
  content: "\eea3";
}
.icon-house-building1:before {
  content: "\eea4";
}
.icon-house-chimney-blank1:before {
  content: "\eea5";
}
.icon-house-chimney-heart1:before {
  content: "\eea6";
}
.icon-house-chimney-user1:before {
  content: "\eea7";
}
.icon-house-chimney-window1:before {
  content: "\eea8";
}
.icon-house-chimney1:before {
  content: "\eea9";
}
.icon-house-circle-check3:before {
  content: "\f78c";
}
.icon-house-fire1:before {
  content: "\eeaa";
}
.icon-house-heart1:before {
  content: "\eeab";
}
.icon-house-window1:before {
  content: "\eeac";
}
.icon-house1:before {
  content: "\eead";
}
.icon-hundred-points3:before {
  content: "\f720";
}
.icon-id-card1:before {
  content: "\eeae";
}
.icon-image-portrait1:before {
  content: "\eeaf";
}
.icon-images1:before {
  content: "\eeb0";
}
.icon-inbox-in1:before {
  content: "\eeb1";
}
.icon-infinity3:before {
  content: "\f78d";
}
.icon-key-skeleton-left-right:before {
  content: "\eeb2";
}
.icon-key-skeleton1:before {
  content: "\eeb3";
}
.icon-kit-medical1:before {
  content: "\eeb4";
}
.icon-laptop-arrow-down1:before {
  content: "\eeb5";
}
.icon-leaf-heart1:before {
  content: "\eeb6";
}
.icon-leaf-maple1:before {
  content: "\eeb7";
}
.icon-leaf-oak1:before {
  content: "\eeb8";
}
.icon-leaf1:before {
  content: "\eeb9";
}
.icon-left-from-line1:before {
  content: "\eeba";
}
.icon-left-long-to-line1:before {
  content: "\eebb";
}
.icon-left-long1:before {
  content: "\eebc";
}
.icon-left-to-line1:before {
  content: "\eebd";
}
.icon-left1:before {
  content: "\eebe";
}
.icon-lightbulb-dollar1:before {
  content: "\eebf";
}
.icon-lightbulb-exclamation-on1:before {
  content: "\eec0";
}
.icon-lightbulb-exclamation1:before {
  content: "\eec1";
}
.icon-lightbulb-on1:before {
  content: "\eec2";
}
.icon-lightbulb1:before {
  content: "\eec3";
}
.icon-list-ul1:before {
  content: "\eec4";
}
.icon-location-arrow1:before {
  content: "\eec5";
}
.icon-location-check1:before {
  content: "\eec6";
}
.icon-location-dot1:before {
  content: "\f662";
}
.icon-location-exclamation1:before {
  content: "\f663";
}
.icon-location-pin1:before {
  content: "\f664";
}
.icon-magnifying-glass-dollar1:before {
  content: "\eec7";
}
.icon-magnifying-glass-location1:before {
  content: "\eec8";
}
.icon-magnifying-glass-minus:before {
  content: "\f677";
}
.icon-magnifying-glass-plus1:before {
  content: "\f678";
}
.icon-magnifying-glass2:before {
  content: "\f679";
}
.icon-map-location-dot1:before {
  content: "\eec9";
}
.icon-map-location1:before {
  content: "\eeca";
}
.icon-map-pin1:before {
  content: "\eecb";
}
.icon-map1:before {
  content: "\eecc";
}
.icon-medal3:before {
  content: "\f76d";
}
.icon-megaphone3:before {
  content: "\f766";
}
.icon-message-arrow-down1:before {
  content: "\eecd";
}
.icon-message-arrow-up-right1:before {
  content: "\eece";
}
.icon-message-arrow-up1:before {
  content: "\eecf";
}
.icon-message-check1:before {
  content: "\eed0";
}
.icon-message-dollar1:before {
  content: "\eed1";
}
.icon-message-dots1:before {
  content: "\eed2";
}
.icon-message-lines2:before {
  content: "\f69e";
}
.icon-message-medical1:before {
  content: "\eed3";
}
.icon-message-plus1:before {
  content: "\eed4";
}
.icon-message-quote1:before {
  content: "\eed5";
}
.icon-message-smile1:before {
  content: "\eed6";
}
.icon-messages-dollar1:before {
  content: "\eed7";
}
.icon-messages1:before {
  content: "\eed8";
}
.icon-meter-bolt1:before {
  content: "\eed9";
}
.icon-meter-droplet1:before {
  content: "\eeda";
}
.icon-meter-fire1:before {
  content: "\eedb";
}
.icon-meter1:before {
  content: "\eedc";
}
.icon-microphone1:before {
  content: "\eedd";
}
.icon-minus1:before {
  content: "\f69f";
}
.icon-mobile-button3:before {
  content: "\f745";
}
.icon-mobile-screen-button3:before {
  content: "\f746";
}
.icon-mobile-screen3:before {
  content: "\f747";
}
.icon-mobile1:before {
  content: "\eede";
}
.icon-money-bill-1-wave1:before {
  content: "\eedf";
}
.icon-money-bill-11:before {
  content: "\eee0";
}
.icon-money-bill-simple-wave1:before {
  content: "\eee1";
}
.icon-money-bill-simple1:before {
  content: "\eee2";
}
.icon-money-bill-wave1:before {
  content: "\eee3";
}
.icon-money-bill1:before {
  content: "\eee4";
}
.icon-money-check-dollar-pen1:before {
  content: "\eee5";
}
.icon-money-check-dollar1:before {
  content: "\eee6";
}
.icon-money-check-pen1:before {
  content: "\eee7";
}
.icon-money-check1:before {
  content: "\eee8";
}
.icon-octagon-check1:before {
  content: "\eee9";
}
.icon-octagon-plus1:before {
  content: "\eeea";
}
.icon-page-caret-down1:before {
  content: "\eeeb";
}
.icon-page-caret-up1:before {
  content: "\eeec";
}
.icon-pallet-box1:before {
  content: "\eeed";
}
.icon-pallet-boxes1:before {
  content: "\eeee";
}
.icon-panel-ews1:before {
  content: "\eeef";
}
.icon-panel-fire1:before {
  content: "\eef0";
}
.icon-paper-plane1:before {
  content: "\eef1";
}
.icon-pen-circle1:before {
  content: "\eef2";
}
.icon-pen-field3:before {
  content: "\f7aa";
}
.icon-pen-line1:before {
  content: "\eef3";
}
.icon-pen-nib1:before {
  content: "\eef4";
}
.icon-pen-paintbrush1:before {
  content: "\eef5";
}
.icon-pen-ruler1:before {
  content: "\eef6";
}
.icon-pen-to-square1:before {
  content: "\eef7";
}
.icon-pen1:before {
  content: "\eef8";
}
.icon-pencil1:before {
  content: "\eef9";
}
.icon-people-arrows-left-right1:before {
  content: "\eefa";
}
.icon-people-carry-box1:before {
  content: "\eefb";
}
.icon-people-group1:before {
  content: "\eefc";
}
.icon-people-roof3:before {
  content: "\f773";
}
.icon-people-simple1:before {
  content: "\eefd";
}
.icon-person-carry-box1:before {
  content: "\eefe";
}
.icon-person-circle-check1:before {
  content: "\eeff";
}
.icon-person-digging1:before {
  content: "\ef00";
}
.icon-person-dolly-empty1:before {
  content: "\ef01";
}
.icon-person-dolly1:before {
  content: "\ef02";
}
.icon-person-dress-simple1:before {
  content: "\ef03";
}
.icon-person-running1:before {
  content: "\ef04";
}
.icon-person-to-door1:before {
  content: "\ef05";
}
.icon-person-walking1:before {
  content: "\ef06";
}
.icon-phone-arrow-up-right1:before {
  content: "\ef07";
}
.icon-phone-flip:before {
  content: "\f6a0";
}
.icon-phone-plus1:before {
  content: "\ef08";
}
.icon-phone-rotary3:before {
  content: "\f798";
}
.icon-phone-volume3:before {
  content: "\f70b";
}
.icon-phone:before {
  content: "\f6a1";
}
.icon-piggy-bank1:before {
  content: "\f6a2";
}
.icon-pipe-circle-check1:before {
  content: "\ef09";
}
.icon-pipe-collar1:before {
  content: "\ef0a";
}
.icon-pipe-section1:before {
  content: "\ef0b";
}
.icon-pipe-valve1:before {
  content: "\ef0c";
}
.icon-plug-circle-bolt1:before {
  content: "\ef0d";
}
.icon-plug-circle-xmark1:before {
  content: "\ef0e";
}
.icon-plug1:before {
  content: "\ef0f";
}
.icon-plus-large:before {
  content: "\f6a3";
}
.icon-plus-minus1:before {
  content: "\ef10";
}
.icon-plus1:before {
  content: "\f6a4";
}
.icon-pump1:before {
  content: "\ef11";
}
.icon-question1:before {
  content: "\ef12";
}
.icon-quote-left1:before {
  content: "\ef13";
}
.icon-quote-right1:before {
  content: "\ef14";
}
.icon-quotes1:before {
  content: "\ef15";
}
.icon-raindrops1:before {
  content: "\f78e";
}
.icon-rectangle-ad1:before {
  content: "\ef16";
}
.icon-rectangle-list3:before {
  content: "\f753";
}
.icon-right-from-line1:before {
  content: "\ef17";
}
.icon-right-long-to-line1:before {
  content: "\ef18";
}
.icon-right-to-bracket1:before {
  content: "\ef19";
}
.icon-right-to-line1:before {
  content: "\ef1a";
}
.icon-right2:before {
  content: "\f721";
}
.icon-route1:before {
  content: "\ef1b";
}
.icon-sack-dollar1:before {
  content: "\ef1c";
}
.icon-sack1:before {
  content: "\ef1d";
}
.icon-scale-balanced1:before {
  content: "\ef1e";
}
.icon-scale-unbalanced-flip1:before {
  content: "\ef1f";
}
.icon-scale-unbalanced1:before {
  content: "\ef20";
}
.icon-school-flag1:before {
  content: "\ef21";
}
.icon-school1:before {
  content: "\ef22";
}
.icon-screwdriver-wrench1:before {
  content: "\ef23";
}
.icon-screwdriver1:before {
  content: "\ef24";
}
.icon-seal-exclamation1:before {
  content: "\ef25";
}
.icon-sensor-cloud1:before {
  content: "\ef26";
}
.icon-sensor-fire1:before {
  content: "\ef27";
}
.icon-sensor-on1:before {
  content: "\ef28";
}
.icon-sensor-triangle-exclamation1:before {
  content: "\ef29";
}
.icon-sensor1:before {
  content: "\ef2a";
}
.icon-share-from-square1:before {
  content: "\ef2b";
}
.icon-shield-check1:before {
  content: "\ef2c";
}
.icon-shield-halved1:before {
  content: "\ef2d";
}
.icon-shield-plus1:before {
  content: "\ef2e";
}
.icon-shield-quartered1:before {
  content: "\ef2f";
}
.icon-shield-virus1:before {
  content: "\ef30";
}
.icon-shield-xmark1:before {
  content: "\ef31";
}
.icon-shield1:before {
  content: "\ef32";
}
.icon-shovel1:before {
  content: "\ef33";
}
.icon-shower-down1:before {
  content: "\ef34";
}
.icon-shower1:before {
  content: "\ef35";
}
.icon-sink1:before {
  content: "\ef36";
}
.icon-snow-blowing1:before {
  content: "\ef37";
}
.icon-snowflake1:before {
  content: "\ef38";
}
.icon-snowflakes1:before {
  content: "\ef39";
}
.icon-snowplow1:before {
  content: "\ef3a";
}
.icon-soap1:before {
  content: "\ef3b";
}
.icon-sort-down2:before {
  content: "\f722";
}
.icon-sparkles2:before {
  content: "\f669";
}
.icon-splotch1:before {
  content: "\ef3c";
}
.icon-square-arrow-down-left1:before {
  content: "\ef3d";
}
.icon-square-arrow-down-right1:before {
  content: "\ef3e";
}
.icon-square-arrow-down1:before {
  content: "\ef3f";
}
.icon-square-arrow-left1:before {
  content: "\ef40";
}
.icon-square-arrow-right1:before {
  content: "\ef41";
}
.icon-square-arrow-up-left1:before {
  content: "\ef42";
}
.icon-square-arrow-up-right1:before {
  content: "\ef43";
}
.icon-square-arrow-up1:before {
  content: "\ef44";
}
.icon-square-caret-down1:before {
  content: "\ef45";
}
.icon-square-caret-left1:before {
  content: "\ef46";
}
.icon-square-caret-right1:before {
  content: "\ef47";
}
.icon-square-caret-up1:before {
  content: "\ef48";
}
.icon-square-check1:before {
  content: "\ef49";
}
.icon-square-chevron-down1:before {
  content: "\ef4a";
}
.icon-square-chevron-left1:before {
  content: "\ef4b";
}
.icon-square-chevron-right1:before {
  content: "\ef4c";
}
.icon-square-chevron-up1:before {
  content: "\ef4d";
}
.icon-square-dollar1:before {
  content: "\ef4e";
}
.icon-square-down-left1:before {
  content: "\ef4f";
}
.icon-square-down-right1:before {
  content: "\ef50";
}
.icon-square-down1:before {
  content: "\ef51";
}
.icon-square-envelope1:before {
  content: "\ef52";
}
.icon-square-exclamation1:before {
  content: "\ef53";
}
.icon-square-left1:before {
  content: "\ef54";
}
.icon-square-minus1:before {
  content: "\f6a5";
}
.icon-square-pen1:before {
  content: "\ef55";
}
.icon-square-phone1:before {
  content: "\ef56";
}
.icon-square-plus3:before {
  content: "\f6a6";
}
.icon-square-quote1:before {
  content: "\ef57";
}
.icon-square-up-left1:before {
  content: "\ef58";
}
.icon-square-up-right1:before {
  content: "\ef59";
}
.icon-square-up:before {
  content: "\ef5a";
}
.icon-star-christmas1:before {
  content: "\ef5b";
}
.icon-star-half1:before {
  content: "\ef5c";
}
.icon-star-sharp1:before {
  content: "\f6a7";
}
.icon-star-shooting1:before {
  content: "\ef5d";
}
.icon-star1:before {
  content: "\f6a8";
}
.icon-stars1:before {
  content: "\ef5e";
}
.icon-stopwatch1:before {
  content: "\ef5f";
}
.icon-suitcase-medical1:before {
  content: "\ef60";
}
.icon-suitcase-rolling1:before {
  content: "\ef61";
}
.icon-suitcase1:before {
  content: "\ef62";
}
.icon-tag1:before {
  content: "\ef63";
}
.icon-tags1:before {
  content: "\ef64";
}
.icon-tank-water1:before {
  content: "\ef65";
}
.icon-taxi1:before {
  content: "\ef66";
}
.icon-temperature-arrow-down1:before {
  content: "\ef67";
}
.icon-temperature-arrow-up1:before {
  content: "\ef68";
}
.icon-temperature-empty1:before {
  content: "\ef69";
}
.icon-temperature-full1:before {
  content: "\ef6a";
}
.icon-temperature-half1:before {
  content: "\ef6b";
}
.icon-temperature-high1:before {
  content: "\ef6c";
}
.icon-temperature-list1:before {
  content: "\ef6d";
}
.icon-temperature-low1:before {
  content: "\ef6e";
}
.icon-temperature-quarter1:before {
  content: "\ef6f";
}
.icon-temperature-snow1:before {
  content: "\ef70";
}
.icon-temperature-sun1:before {
  content: "\ef71";
}
.icon-temperature-three-quarters1:before {
  content: "\ef72";
}
.icon-thumbs-down1:before {
  content: "\ef73";
}
.icon-thumbs-up1:before {
  content: "\ef74";
}
.icon-ticket2:before {
  content: "\f6e2";
}
.icon-timer1:before {
  content: "\ef75";
}
.icon-toilet1:before {
  content: "\ef76";
}
.icon-toolbox1:before {
  content: "\ef77";
}
.icon-tooth1:before {
  content: "\f6a9";
}
.icon-train-track1:before {
  content: "\ef78";
}
.icon-trash-can-check1:before {
  content: "\ef79";
}
.icon-trash-check1:before {
  content: "\ef7a";
}
.icon-trash-plus1:before {
  content: "\ef7b";
}
.icon-treasure-chest1:before {
  content: "\ef7c";
}
.icon-triangle-exclamation1:before {
  content: "\ef7d";
}
.icon-triangle-person-digging1:before {
  content: "\ef7e";
}
.icon-trophy-star1:before {
  content: "\ef7f";
}
.icon-trophy1:before {
  content: "\ef80";
}
.icon-truck-clock1:before {
  content: "\ef81";
}
.icon-truck-fast1:before {
  content: "\ef82";
}
.icon-truck-field1:before {
  content: "\ef83";
}
.icon-truck-flatbed1:before {
  content: "\ef84";
}
.icon-truck-front1:before {
  content: "\ef85";
}
.icon-truck-medical1:before {
  content: "\ef86";
}
.icon-truck-moving1:before {
  content: "\ef87";
}
.icon-truck-plow1:before {
  content: "\ef88";
}
.icon-truck-ramp-box1:before {
  content: "\ef89";
}
.icon-truck-ramp1:before {
  content: "\ef8a";
}
.icon-truck1:before {
  content: "\ef8b";
}
.icon-tty-answer1:before {
  content: "\ef8c";
}
.icon-turn-down-left1:before {
  content: "\ef8d";
}
.icon-turn-down-right1:before {
  content: "\ef8e";
}
.icon-turn-down1:before {
  content: "\ef8f";
}
.icon-turn-up1:before {
  content: "\ef90";
}
.icon-umbrella-beach1:before {
  content: "\f6ef";
}
.icon-up-left1:before {
  content: "\ef91";
}
.icon-up-right-from-square1:before {
  content: "\ef92";
}
.icon-up-right1:before {
  content: "\ef93";
}
.icon-up-to-line1:before {
  content: "\ef94";
}
.icon-user-check1:before {
  content: "\ef95";
}
.icon-user-clock1:before {
  content: "\ef96";
}
.icon-user-doctor-message1:before {
  content: "\ef97";
}
.icon-user-doctor1:before {
  content: "\ef98";
}
.icon-user-group1:before {
  content: "\ef99";
}
.icon-user-hair-long1:before {
  content: "\ef9a";
}
.icon-user-headset3:before {
  content: "\f754";
}
.icon-user-helmet-safety1:before {
  content: "\ef9b";
}
.icon-user-large1:before {
  content: "\ef9c";
}
.icon-user-pen1:before {
  content: "\ef9d";
}
.icon-user-plus1:before {
  content: "\ef9e";
}
.icon-user-robot-xmarks1:before {
  content: "\ef9f";
}
.icon-user-robot1:before {
  content: "\efa0";
}
.icon-user-shield1:before {
  content: "\efa1";
}
.icon-user-tag1:before {
  content: "\efa2";
}
.icon-user-tie1:before {
  content: "\efa3";
}
.icon-user1:before {
  content: "\efa4";
}
.icon-users-between-lines1:before {
  content: "\efa5";
}
.icon-users1:before {
  content: "\efa6";
}
.icon-van-shuttle1:before {
  content: "\efa7";
}
.icon-video1:before {
  content: "\efa8";
}
.icon-viruses1:before {
  content: "\efa9";
}
.icon-volume-high1:before {
  content: "\efaa";
}
.icon-volume-low1:before {
  content: "\efab";
}
.icon-volume-off1:before {
  content: "\efac";
}
.icon-volume-slash1:before {
  content: "\efad";
}
.icon-volume-xmark1:before {
  content: "\efae";
}
.icon-volume1:before {
  content: "\efaf";
}
.icon-wallet1:before {
  content: "\efb0";
}
.icon-warehouse-full1:before {
  content: "\efb1";
}
.icon-warehouse1:before {
  content: "\efb2";
}
.icon-water3:before {
  content: "\f735";
}
.icon-wheelchair-move1:before {
  content: "\efb3";
}
.icon-wind-warning1:before {
  content: "\efb4";
}
.icon-wind1:before {
  content: "\efb5";
}
.icon-window-flip1:before {
  content: "\efb6";
}
.icon-window-frame-open1:before {
  content: "\efb7";
}
.icon-window-frame1:before {
  content: "\efb8";
}
.icon-window-minimize1:before {
  content: "\f6aa";
}
.icon-window1:before {
  content: "\efb9";
}
.icon-wrench-simple:before {
  content: "\f1f2";
}
.icon-wrench1:before {
  content: "\f1f3";
}
.icon-x:before {
  content: "\f1f4";
}
.icon-xmark-large:before {
  content: "\f1f5";
}
.icon-xmark:before {
  content: "\f1f6";
}
.icon-address-card3:before {
  content: "\f1f7";
}
.icon-air-conditioner3:before {
  content: "\f1f8";
}
.icon-alarm-clock3:before {
  content: "\f1f9";
}
.icon-alarm-exclamation3:before {
  content: "\f1fa";
}
.icon-angle-down3:before {
  content: "\f1fb";
}
.icon-angle-left3:before {
  content: "\f1fc";
}
.icon-angle-right3:before {
  content: "\f1fd";
}
.icon-angle-up3:before {
  content: "\f1fe";
}
.icon-angles-down3:before {
  content: "\f1ff";
}
.icon-angles-left3:before {
  content: "\f200";
}
.icon-angles-right3:before {
  content: "\f201";
}
.icon-angles-up3:before {
  content: "\f202";
}
.icon-apple-whole3:before {
  content: "\f203";
}
.icon-archway3:before {
  content: "\f204";
}
.icon-arrow-down-arrow-up3:before {
  content: "\f205";
}
.icon-arrow-down-from-line3:before {
  content: "\f206";
}
.icon-arrow-down-left-and-arrow-up-right-to-center3:before {
  content: "\f207";
}
.icon-arrow-down-left3:before {
  content: "\f208";
}
.icon-arrow-down-long3:before {
  content: "\f209";
}
.icon-arrow-down-right3:before {
  content: "\f20a";
}
.icon-arrow-down-to-bracket3:before {
  content: "\f20b";
}
.icon-arrow-down-to-line3:before {
  content: "\f20c";
}
.icon-arrow-down-to-square3:before {
  content: "\f20d";
}
.icon-arrow-down3:before {
  content: "\f20e";
}
.icon-arrow-left-from-line3:before {
  content: "\f20f";
}
.icon-arrow-left-long-to-line3:before {
  content: "\f210";
}
.icon-arrow-left-long3:before {
  content: "\f211";
}
.icon-arrow-left-to-line3:before {
  content: "\f212";
}
.icon-arrow-left3:before {
  content: "\f213";
}
.icon-arrow-right-arrow-left3:before {
  content: "\f214";
}
.icon-arrow-right-from-line3:before {
  content: "\f215";
}
.icon-arrow-right-long-to-line3:before {
  content: "\f216";
}
.icon-arrow-right-long3:before {
  content: "\f217";
}
.icon-arrow-right-to-line3:before {
  content: "\f218";
}
.icon-arrow-right3:before {
  content: "\f219";
}
.icon-arrow-turn-down-left3:before {
  content: "\f21a";
}
.icon-arrow-turn-down-right3:before {
  content: "\f21b";
}
.icon-arrow-turn-down3:before {
  content: "\f21c";
}
.icon-arrow-turn-up3:before {
  content: "\f21d";
}
.icon-arrow-up-from-line3:before {
  content: "\f21e";
}
.icon-arrow-up-left3:before {
  content: "\f21f";
}
.icon-arrow-up-long3:before {
  content: "\f220";
}
.icon-arrow-up-right-dots:before {
  content: "\f723";
}
.icon-arrow-up-right3:before {
  content: "\f221";
}
.icon-arrow-up-to-line3:before {
  content: "\f222";
}
.icon-arrow-up3:before {
  content: "\f223";
}
.icon-arrows-left-right3:before {
  content: "\f224";
}
.icon-arrows-maximize3:before {
  content: "\f225";
}
.icon-arrows-repeat3:before {
  content: "\f226";
}
.icon-arrows-retweet3:before {
  content: "\f227";
}
.icon-audio-description3:before {
  content: "\f228";
}
.icon-award-simple3:before {
  content: "\f229";
}
.icon-award3:before {
  content: "\f22a";
}
.icon-badge-check3:before {
  content: "\f22b";
}
.icon-badge-dollar3:before {
  content: "\f22c";
}
.icon-badge-percent3:before {
  content: "\f22d";
}
.icon-badge3:before {
  content: "\f22e";
}
.icon-ballot-check3:before {
  content: "\f22f";
}
.icon-ballot3:before {
  content: "\f230";
}
.icon-bars3:before {
  content: "\f231";
}
.icon-bed-pulse1:before {
  content: "\f6bc";
}
.icon-bell-on3:before {
  content: "\f232";
}
.icon-bell3:before {
  content: "\f233";
}
.icon-blanket3:before {
  content: "\f234";
}
.icon-book-blank3:before {
  content: "\f235";
}
.icon-book-open-cover3:before {
  content: "\f236";
}
.icon-book-open-reader3:before {
  content: "\f237";
}
.icon-books3:before {
  content: "\f238";
}
.icon-box-check3:before {
  content: "\f239";
}
.icon-box-dollar3:before {
  content: "\f23a";
}
.icon-box-heart3:before {
  content: "\f23b";
}
.icon-box-open3:before {
  content: "\f23c";
}
.icon-box-taped3:before {
  content: "\f23d";
}
.icon-box3:before {
  content: "\f23e";
}
.icon-brain3:before {
  content: "\f23f";
}
.icon-brake-warning3:before {
  content: "\f240";
}
.icon-briefcase-blank3:before {
  content: "\f241";
}
.icon-briefcase-medical3:before {
  content: "\f242";
}
.icon-briefcase3:before {
  content: "\f243";
}
.icon-brightness3:before {
  content: "\f244";
}
.icon-broom-ball3:before {
  content: "\f245";
}
.icon-broom3:before {
  content: "\f246";
}
.icon-browser3:before {
  content: "\f247";
}
.icon-browsers3:before {
  content: "\f248";
}
.icon-building-circle-check3:before {
  content: "\f249";
}
.icon-building-columns3:before {
  content: "\f6f4";
}
.icon-building-shield3:before {
  content: "\f24a";
}
.icon-building-user3:before {
  content: "\f24b";
}
.icon-building3:before {
  content: "\f24c";
}
.icon-buildings3:before {
  content: "\f24d";
}
.icon-bullhorn4:before {
  content: "\f767";
}
.icon-business-time3:before {
  content: "\f24e";
}
.icon-cabinet-filing3:before {
  content: "\f24f";
}
.icon-calculator-simple3:before {
  content: "\f250";
}
.icon-calendar-check3:before {
  content: "\f251";
}
.icon-calendar-circle-exclamation3:before {
  content: "\f252";
}
.icon-calendar-circle-user3:before {
  content: "\f253";
}
.icon-calendar-clock3:before {
  content: "\f254";
}
.icon-calendar-days3:before {
  content: "\f255";
}
.icon-calendar-lines-pen3:before {
  content: "\f256";
}
.icon-calendar-pen3:before {
  content: "\f257";
}
.icon-calendar-plus4:before {
  content: "\f70c";
}
.icon-calendar-star3:before {
  content: "\f258";
}
.icon-calendar-xmark3:before {
  content: "\f259";
}
.icon-calendar3:before {
  content: "\f25a";
}
.icon-caret-down3:before {
  content: "\f25b";
}
.icon-caret-left3:before {
  content: "\f25c";
}
.icon-caret-right3:before {
  content: "\f25d";
}
.icon-caret-up3:before {
  content: "\f25e";
}
.icon-cart-arrow-down3:before {
  content: "\f25f";
}
.icon-cart-arrow-up3:before {
  content: "\f260";
}
.icon-cart-circle-arrow-down3:before {
  content: "\f261";
}
.icon-cart-circle-check3:before {
  content: "\f262";
}
.icon-cart-circle-plus3:before {
  content: "\f263";
}
.icon-cart-flatbed-boxes3:before {
  content: "\f264";
}
.icon-cart-flatbed-empty3:before {
  content: "\f265";
}
.icon-cart-flatbed-suitcase3:before {
  content: "\f266";
}
.icon-cart-flatbed3:before {
  content: "\f267";
}
.icon-cart-shopping-fast3:before {
  content: "\f268";
}
.icon-cart-shopping3:before {
  content: "\f269";
}
.icon-chalkboard-user3:before {
  content: "\f26a";
}
.icon-chart-column3:before {
  content: "\f26b";
}
.icon-chart-line-down3:before {
  content: "\f26c";
}
.icon-chart-line-up3:before {
  content: "\f26d";
}
.icon-chart-line3:before {
  content: "\f26e";
}
.icon-check3:before {
  content: "\f26f";
}
.icon-chevron-down3:before {
  content: "\f270";
}
.icon-chevron-left3:before {
  content: "\f271";
}
.icon-chevron-right3:before {
  content: "\f272";
}
.icon-chevron-up3:before {
  content: "\f273";
}
.icon-chevrons-down3:before {
  content: "\f274";
}
.icon-chevrons-left3:before {
  content: "\f275";
}
.icon-chevrons-right3:before {
  content: "\f276";
}
.icon-chevrons-up3:before {
  content: "\f277";
}
.icon-circle-arrow-down-left3:before {
  content: "\f278";
}
.icon-circle-arrow-down-right3:before {
  content: "\f279";
}
.icon-circle-arrow-down3:before {
  content: "\f27a";
}
.icon-circle-arrow-left3:before {
  content: "\f27b";
}
.icon-circle-arrow-right3:before {
  content: "\f27c";
}
.icon-circle-arrow-up-left3:before {
  content: "\f27d";
}
.icon-circle-arrow-up-right3:before {
  content: "\f27e";
}
.icon-circle-arrow-up3:before {
  content: "\f27f";
}
.icon-circle-caret-down3:before {
  content: "\f280";
}
.icon-circle-caret-left3:before {
  content: "\f281";
}
.icon-circle-caret-right3:before {
  content: "\f282";
}
.icon-circle-caret-up3:before {
  content: "\f283";
}
.icon-circle-check3:before {
  content: "\f284";
}
.icon-circle-chevron-down3:before {
  content: "\f285";
}
.icon-circle-chevron-left3:before {
  content: "\f286";
}
.icon-circle-chevron-right3:before {
  content: "\f287";
}
.icon-circle-chevron-up3:before {
  content: "\f288";
}
.icon-circle-dollar3:before {
  content: "\f289";
}
.icon-circle-down-left3:before {
  content: "\f28a";
}
.icon-circle-down-right3:before {
  content: "\f28b";
}
.icon-circle-down3:before {
  content: "\f28c";
}
.icon-circle-envelope3:before {
  content: "\f28d";
}
.icon-circle-exclamation3:before {
  content: "\f28e";
}
.icon-circle-location-arrow3:before {
  content: "\f28f";
}
.icon-circle-minus3:before {
  content: "\f6f5";
}
.icon-circle-pause3:before {
  content: "\f290";
}
.icon-circle-phone3:before {
  content: "\f291";
}
.icon-circle-plus3:before {
  content: "\f292";
}
.icon-circle-right3:before {
  content: "\f293";
}
.icon-circle-star3:before {
  content: "\f294";
}
.icon-circle-up-left3:before {
  content: "\f295";
}
.icon-circle-up-right3:before {
  content: "\f296";
}
.icon-circle-up3:before {
  content: "\f297";
}
.icon-circle-user4:before {
  content: "\f7a6";
}
.icon-circle-video3:before {
  content: "\f298";
}
.icon-clipboard-list-check3:before {
  content: "\f299";
}
.icon-clipboard-list3:before {
  content: "\f29a";
}
.icon-clipboard-medical3:before {
  content: "\f29b";
}
.icon-clipboard-prescription2:before {
  content: "\f6bd";
}
.icon-clipboard-user3:before {
  content: "\f29c";
}
.icon-clock-desk3:before {
  content: "\f29d";
}
.icon-clock-rotate-left3:before {
  content: "\f29e";
}
.icon-clock3:before {
  content: "\f29f";
}
.icon-cloud-arrow-down3:before {
  content: "\f2a0";
}
.icon-cloud-bolt3:before {
  content: "\f2a1";
}
.icon-cloud-check3:before {
  content: "\f2a2";
}
.icon-coins3:before {
  content: "\f2a3";
}
.icon-comment-check3:before {
  content: "\f2a4";
}
.icon-comment-dollar3:before {
  content: "\f2a5";
}
.icon-comment-quote3:before {
  content: "\f2a6";
}
.icon-comment-smile3:before {
  content: "\f2a7";
}
.icon-comment-sms3:before {
  content: "\f2a8";
}
.icon-comment3:before {
  content: "\f2a9";
}
.icon-comments-dollar3:before {
  content: "\f2aa";
}
.icon-comments-question4:before {
  content: "\f77f";
}
.icon-comments3:before {
  content: "\f2ab";
}
.icon-conveyor-belt3:before {
  content: "\f2ac";
}
.icon-copyright3:before {
  content: "\f2ad";
}
.icon-credit-card-blank3:before {
  content: "\f2ae";
}
.icon-credit-card-front3:before {
  content: "\f2af";
}
.icon-credit-card3:before {
  content: "\f2b0";
}
.icon-dash1:before {
  content: "\f66a";
}
.icon-diploma3:before {
  content: "\f2b1";
}
.icon-dollar-sign4:before {
  content: "\f75c";
}
.icon-dolly-empty3:before {
  content: "\f2b2";
}
.icon-dolly3:before {
  content: "\f2b3";
}
.icon-door-open3:before {
  content: "\f2b4";
}
.icon-down-from-dotted-line1:before {
  content: "\f2b5";
}
.icon-down-from-line3:before {
  content: "\f2b6";
}
.icon-down-left-and-up-right-to-center3:before {
  content: "\f2b7";
}
.icon-down-left3:before {
  content: "\f2b8";
}
.icon-down-long3:before {
  content: "\f2b9";
}
.icon-down-right3:before {
  content: "\f2ba";
}
.icon-down-to-bracket3:before {
  content: "\f2bb";
}
.icon-down-to-line3:before {
  content: "\f2bc";
}
.icon-down3:before {
  content: "\f2bd";
}
.icon-droplet-degree4:before {
  content: "\f736";
}
.icon-droplet4:before {
  content: "\f737";
}
.icon-earth-americas3:before {
  content: "\f2be";
}
.icon-envelope-circle-check3:before {
  content: "\f2bf";
}
.icon-envelope-open-dollar3:before {
  content: "\f2c0";
}
.icon-envelope-open-text3:before {
  content: "\f2c1";
}
.icon-envelope3:before {
  content: "\f2c2";
}
.icon-eye3:before {
  content: "\f2c3";
}
.icon-face-grin-wide3:before {
  content: "\f2c4";
}
.icon-face-relieved3:before {
  content: "\f2c5";
}
.icon-face-smile-plus3:before {
  content: "\f2c6";
}
.icon-face-smile3:before {
  content: "\f2c7";
}
.icon-family-dress3:before {
  content: "\f2c8";
}
.icon-family-pants3:before {
  content: "\f2c9";
}
.icon-family3:before {
  content: "\f2ca";
}
.icon-fan-table3:before {
  content: "\f2cb";
}
.icon-fan3:before {
  content: "\f2cc";
}
.icon-faucet-drip3:before {
  content: "\f2cd";
}
.icon-faucet3:before {
  content: "\f2ce";
}
.icon-fax3:before {
  content: "\f2cf";
}
.icon-feather-pointed3:before {
  content: "\f2d0";
}
.icon-file-arrow-down3:before {
  content: "\f2d1";
}
.icon-file-certificate3:before {
  content: "\f2d2";
}
.icon-file-check3:before {
  content: "\f2d3";
}
.icon-file-circle-check3:before {
  content: "\f2d4";
}
.icon-file-circle-plus3:before {
  content: "\f2d5";
}
.icon-file-exclamation3:before {
  content: "\f2d6";
}
.icon-file-invoice-dollar3:before {
  content: "\f2d7";
}
.icon-file-pdf3:before {
  content: "\f2d8";
}
.icon-file-user3:before {
  content: "\f2d9";
}
.icon-files-medical3:before {
  content: "\f2da";
}
.icon-filter-circle-dollar3:before {
  content: "\f2db";
}
.icon-fire-extinguisher3:before {
  content: "\f2dc";
}
.icon-fire-flame-curved3:before {
  content: "\f2dd";
}
.icon-fire-flame-simple3:before {
  content: "\f2de";
}
.icon-fire-flame3:before {
  content: "\f2df";
}
.icon-fire-hydrant3:before {
  content: "\f2e0";
}
.icon-fire-smoke3:before {
  content: "\f2e1";
}
.icon-fire3:before {
  content: "\f2e2";
}
.icon-fireplace3:before {
  content: "\f2e3";
}
.icon-flag-usa3:before {
  content: "\f2e4";
}
.icon-floppy-disk3:before {
  content: "\f2e5";
}
.icon-folder-arrow-down3:before {
  content: "\f2e6";
}
.icon-folder-medical3:before {
  content: "\f2e7";
}
.icon-folder-plus3:before {
  content: "\f2e8";
}
.icon-font-awesome3:before {
  content: "\f2e9";
}
.icon-forward-fast3:before {
  content: "\f2ea";
}
.icon-forward-step3:before {
  content: "\f2eb";
}
.icon-forward3:before {
  content: "\f2ec";
}
.icon-gear4:before {
  content: "\f780";
}
.icon-gift-card4:before {
  content: "\f2ed";
}
.icon-gift3:before {
  content: "\f2ee";
}
.icon-globe3:before {
  content: "\f2ef";
}
.icon-graduation-cap4:before {
  content: "\f78f";
}
.icon-grate-droplet3:before {
  content: "\f2f0";
}
.icon-grate3:before {
  content: "\f2f1";
}
.icon-hammer-crash3:before {
  content: "\f2f2";
}
.icon-hand-back-fist3:before {
  content: "\f2f3";
}
.icon-hand-fingers-crossed3:before {
  content: "\f2f4";
}
.icon-hand-fist3:before {
  content: "\f2f5";
}
.icon-hand-holding-box3:before {
  content: "\f2f6";
}
.icon-hand-holding-dollar3:before {
  content: "\f2f7";
}
.icon-hand-holding-droplet3:before {
  content: "\f2f8";
}
.icon-hand-holding-heart3:before {
  content: "\f2f9";
}
.icon-hand-holding-medical3:before {
  content: "\f2fa";
}
.icon-hand-sparkles3:before {
  content: "\f2fb";
}
.icon-hands-bubbles3:before {
  content: "\f2fc";
}
.icon-hands-holding-dollar3:before {
  content: "\f2fd";
}
.icon-hands-holding-heart3:before {
  content: "\f2fe";
}
.icon-hands-holding3:before {
  content: "\f2ff";
}
.icon-hands-praying3:before {
  content: "\f300";
}
.icon-hands3:before {
  content: "\f301";
}
.icon-handshake-angle3:before {
  content: "\f302";
}
.icon-handshake-simple3:before {
  content: "\f303";
}
.icon-handshake3:before {
  content: "\f304";
}
.icon-head-side-brain3:before {
  content: "\f305";
}
.icon-head-side-cough3:before {
  content: "\f306";
}
.icon-head-side-medical3:before {
  content: "\f307";
}
.icon-head-side-virus3:before {
  content: "\f308";
}
.icon-head-side3:before {
  content: "\f309";
}
.icon-heart-pulse2:before {
  content: "\f6f6";
}
.icon-heart3:before {
  content: "\f30a";
}
.icon-helmet-safety3:before {
  content: "\f30b";
}
.icon-high-definition3:before {
  content: "\f30c";
}
.icon-hospital3:before {
  content: "\f30d";
}
.icon-hospitals3:before {
  content: "\f30e";
}
.icon-hourglass-clock3:before {
  content: "\f30f";
}
.icon-house-building3:before {
  content: "\f310";
}
.icon-house-chimney-blank3:before {
  content: "\f311";
}
.icon-house-chimney-heart3:before {
  content: "\f312";
}
.icon-house-chimney-user3:before {
  content: "\f313";
}
.icon-house-chimney-window3:before {
  content: "\f314";
}
.icon-house-chimney3:before {
  content: "\f315";
}
.icon-house-circle-check4:before {
  content: "\f790";
}
.icon-house-fire2:before {
  content: "\f316";
}
.icon-house-heart3:before {
  content: "\f317";
}
.icon-house-window3:before {
  content: "\f318";
}
.icon-house3:before {
  content: "\f319";
}
.icon-hundred-points2:before {
  content: "\f724";
}
.icon-id-card3:before {
  content: "\f31a";
}
.icon-image-portrait3:before {
  content: "\f31b";
}
.icon-images3:before {
  content: "\f31c";
}
.icon-inbox-in3:before {
  content: "\f31d";
}
.icon-infinity4:before {
  content: "\f791";
}
.icon-key-skeleton-left-right1:before {
  content: "\f31e";
}
.icon-key-skeleton3:before {
  content: "\f31f";
}
.icon-kit-medical3:before {
  content: "\f320";
}
.icon-laptop-arrow-down3:before {
  content: "\f321";
}
.icon-leaf-heart3:before {
  content: "\f322";
}
.icon-leaf-maple3:before {
  content: "\f323";
}
.icon-leaf-oak3:before {
  content: "\f324";
}
.icon-leaf3:before {
  content: "\f325";
}
.icon-left-from-line3:before {
  content: "\f326";
}
.icon-left-long-to-line3:before {
  content: "\f327";
}
.icon-left-long3:before {
  content: "\f328";
}
.icon-left-to-line3:before {
  content: "\f329";
}
.icon-left3:before {
  content: "\f32a";
}
.icon-lightbulb-dollar3:before {
  content: "\f32b";
}
.icon-lightbulb-exclamation-on3:before {
  content: "\f32c";
}
.icon-lightbulb-exclamation3:before {
  content: "\f32d";
}
.icon-lightbulb-on3:before {
  content: "\f32e";
}
.icon-lightbulb3:before {
  content: "\f32f";
}
.icon-list-ul3:before {
  content: "\f330";
}
.icon-location-arrow3:before {
  content: "\f331";
}
.icon-location-check3:before {
  content: "\f332";
}
.icon-location-dot3:before {
  content: "\f66b";
}
.icon-location-exclamation3:before {
  content: "\f66c";
}
.icon-location-pin3:before {
  content: "\f66d";
}
.icon-magnifying-glass-dollar3:before {
  content: "\f333";
}
.icon-magnifying-glass-location3:before {
  content: "\f334";
}
.icon-magnifying-glass-minus4:before {
  content: "\f67c";
}
.icon-magnifying-glass-plus:before {
  content: "\f67d";
}
.icon-magnifying-glass3:before {
  content: "\f67e";
}
.icon-map-location-dot3:before {
  content: "\f335";
}
.icon-map-location3:before {
  content: "\f336";
}
.icon-map-pin3:before {
  content: "\f337";
}
.icon-map3:before {
  content: "\f338";
}
.icon-medal4:before {
  content: "\f76e";
}
.icon-megaphone4:before {
  content: "\f768";
}
.icon-message-arrow-down3:before {
  content: "\f339";
}
.icon-message-arrow-up-right3:before {
  content: "\f33a";
}
.icon-message-arrow-up3:before {
  content: "\f33b";
}
.icon-message-check3:before {
  content: "\f33c";
}
.icon-message-dollar3:before {
  content: "\f33d";
}
.icon-message-dots3:before {
  content: "\f33e";
}
.icon-message-lines3:before {
  content: "\f6be";
}
.icon-message-medical3:before {
  content: "\f33f";
}
.icon-message-plus3:before {
  content: "\f340";
}
.icon-message-quote3:before {
  content: "\f341";
}
.icon-message-smile3:before {
  content: "\f342";
}
.icon-messages-dollar3:before {
  content: "\f343";
}
.icon-messages3:before {
  content: "\f344";
}
.icon-meter-bolt3:before {
  content: "\f345";
}
.icon-meter-droplet3:before {
  content: "\f346";
}
.icon-meter-fire3:before {
  content: "\f347";
}
.icon-meter3:before {
  content: "\f348";
}
.icon-microchip1:before {
  content: "\f6bf";
}
.icon-microphone3:before {
  content: "\f349";
}
.icon-minus3:before {
  content: "\f6c0";
}
.icon-mobile-button4:before {
  content: "\f748";
}
.icon-mobile-screen-button4:before {
  content: "\f749";
}
.icon-mobile-screen4:before {
  content: "\f74a";
}
.icon-mobile3:before {
  content: "\f34a";
}
.icon-money-bill-1-wave3:before {
  content: "\f34b";
}
.icon-money-bill-13:before {
  content: "\f34c";
}
.icon-money-bill-simple-wave3:before {
  content: "\f34d";
}
.icon-money-bill-simple3:before {
  content: "\f34e";
}
.icon-money-bill-wave3:before {
  content: "\f34f";
}
.icon-money-bill3:before {
  content: "\f350";
}
.icon-money-check-dollar-pen3:before {
  content: "\f351";
}
.icon-money-check-dollar3:before {
  content: "\f352";
}
.icon-money-check-pen3:before {
  content: "\f353";
}
.icon-money-check3:before {
  content: "\f354";
}
.icon-octagon-check3:before {
  content: "\f355";
}
.icon-octagon-plus3:before {
  content: "\f356";
}
.icon-page-caret-down3:before {
  content: "\f357";
}
.icon-page-caret-up3:before {
  content: "\f358";
}
.icon-pallet-box3:before {
  content: "\f359";
}
.icon-pallet-boxes3:before {
  content: "\f35a";
}
.icon-panel-ews3:before {
  content: "\f35b";
}
.icon-panel-fire3:before {
  content: "\f35c";
}
.icon-paper-plane3:before {
  content: "\f35d";
}
.icon-pen-circle3:before {
  content: "\f35e";
}
.icon-pen-field4:before {
  content: "\f7ab";
}
.icon-pen-line3:before {
  content: "\f35f";
}
.icon-pen-nib3:before {
  content: "\f360";
}
.icon-pen-paintbrush3:before {
  content: "\f361";
}
.icon-pen-ruler3:before {
  content: "\f362";
}
.icon-pen-to-square3:before {
  content: "\f363";
}
.icon-pen3:before {
  content: "\f364";
}
.icon-pencil3:before {
  content: "\f365";
}
.icon-people-arrows-left-right3:before {
  content: "\f366";
}
.icon-people-carry-box3:before {
  content: "\f367";
}
.icon-people-group3:before {
  content: "\f368";
}
.icon-people-roof4:before {
  content: "\f774";
}
.icon-people-simple3:before {
  content: "\f369";
}
.icon-person-carry-box3:before {
  content: "\f36a";
}
.icon-person-circle-check3:before {
  content: "\f36b";
}
.icon-person-digging3:before {
  content: "\f36c";
}
.icon-person-dolly-empty3:before {
  content: "\f36d";
}
.icon-person-dolly3:before {
  content: "\f36e";
}
.icon-person-dress-simple3:before {
  content: "\f36f";
}
.icon-person-running3:before {
  content: "\f370";
}
.icon-person-to-door3:before {
  content: "\f371";
}
.icon-person-walking3:before {
  content: "\f372";
}
.icon-phone-arrow-up-right3:before {
  content: "\f373";
}
.icon-phone-flip4:before {
  content: "\f6c1";
}
.icon-phone-plus3:before {
  content: "\f374";
}
.icon-phone-rotary4:before {
  content: "\f799";
}
.icon-phone-volume4:before {
  content: "\f70d";
}
.icon-phone1:before {
  content: "\f6c2";
}
.icon-piggy-bank4:before {
  content: "\f6c3";
}
.icon-pipe-circle-check3:before {
  content: "\f375";
}
.icon-pipe-collar3:before {
  content: "\f376";
}
.icon-pipe-section3:before {
  content: "\f377";
}
.icon-pipe-valve3:before {
  content: "\f378";
}
.icon-plug-circle-bolt3:before {
  content: "\f379";
}
.icon-plug-circle-xmark3:before {
  content: "\f37a";
}
.icon-plug3:before {
  content: "\f37b";
}
.icon-plus-large3:before {
  content: "\f6c4";
}
.icon-plus-minus3:before {
  content: "\f37c";
}
.icon-plus3:before {
  content: "\f6c5";
}
.icon-pump3:before {
  content: "\f37d";
}
.icon-question3:before {
  content: "\f37e";
}
.icon-quote-left3:before {
  content: "\f37f";
}
.icon-quote-right3:before {
  content: "\f380";
}
.icon-quotes3:before {
  content: "\f381";
}
.icon-raindrops:before {
  content: "\f792";
}
.icon-rectangle-list4:before {
  content: "\f755";
}
.icon-right-from-line3:before {
  content: "\f382";
}
.icon-right-long-to-line3:before {
  content: "\f383";
}
.icon-right-to-bracket3:before {
  content: "\f384";
}
.icon-right-to-line3:before {
  content: "\f385";
}
.icon-right3:before {
  content: "\f725";
}
.icon-route3:before {
  content: "\f386";
}
.icon-sack-dollar3:before {
  content: "\f387";
}
.icon-sack3:before {
  content: "\f388";
}
.icon-scale-balanced3:before {
  content: "\f389";
}
.icon-scale-unbalanced-flip3:before {
  content: "\f38a";
}
.icon-scale-unbalanced3:before {
  content: "\f38b";
}
.icon-school-flag3:before {
  content: "\f38c";
}
.icon-school3:before {
  content: "\f38d";
}
.icon-screwdriver-wrench3:before {
  content: "\f38e";
}
.icon-screwdriver3:before {
  content: "\f38f";
}
.icon-seal-exclamation3:before {
  content: "\f390";
}
.icon-sensor-cloud3:before {
  content: "\f391";
}
.icon-sensor-fire3:before {
  content: "\f392";
}
.icon-sensor-on3:before {
  content: "\f393";
}
.icon-sensor-triangle-exclamation3:before {
  content: "\f394";
}
.icon-sensor3:before {
  content: "\f395";
}
.icon-share-from-square3:before {
  content: "\f396";
}
.icon-shield-check3:before {
  content: "\f397";
}
.icon-shield-halved3:before {
  content: "\f398";
}
.icon-shield-plus3:before {
  content: "\f399";
}
.icon-shield-quartered3:before {
  content: "\f39a";
}
.icon-shield-virus3:before {
  content: "\f39b";
}
.icon-shield-xmark3:before {
  content: "\f39c";
}
.icon-shield3:before {
  content: "\f39d";
}
.icon-shovel3:before {
  content: "\f39e";
}
.icon-shower-down3:before {
  content: "\f39f";
}
.icon-shower3:before {
  content: "\f3a0";
}
.icon-sink3:before {
  content: "\f3a1";
}
.icon-snow-blowing3:before {
  content: "\f3a2";
}
.icon-snowflake3:before {
  content: "\f3a3";
}
.icon-snowflakes3:before {
  content: "\f3a4";
}
.icon-snowplow3:before {
  content: "\f3a5";
}
.icon-soap3:before {
  content: "\f3a6";
}
.icon-sort-down3:before {
  content: "\f726";
}
.icon-sparkles3:before {
  content: "\f682";
}
.icon-splotch3:before {
  content: "\f3a7";
}
.icon-square-arrow-down-left3:before {
  content: "\f3a8";
}
.icon-square-arrow-down-right3:before {
  content: "\f3a9";
}
.icon-square-arrow-down3:before {
  content: "\f3aa";
}
.icon-square-arrow-left3:before {
  content: "\f3ab";
}
.icon-square-arrow-right3:before {
  content: "\f3ac";
}
.icon-square-arrow-up-left3:before {
  content: "\f3ad";
}
.icon-square-arrow-up-right3:before {
  content: "\f3ae";
}
.icon-square-arrow-up3:before {
  content: "\f3af";
}
.icon-square-caret-down3:before {
  content: "\f3b0";
}
.icon-square-caret-left3:before {
  content: "\f3b1";
}
.icon-square-caret-right3:before {
  content: "\f3b2";
}
.icon-square-caret-up3:before {
  content: "\f3b3";
}
.icon-square-check3:before {
  content: "\f3b4";
}
.icon-square-chevron-down3:before {
  content: "\f3b5";
}
.icon-square-chevron-left3:before {
  content: "\f3b6";
}
.icon-square-chevron-right3:before {
  content: "\f3b7";
}
.icon-square-chevron-up3:before {
  content: "\f3b8";
}
.icon-square-dollar3:before {
  content: "\f3b9";
}
.icon-square-down-left3:before {
  content: "\f3ba";
}
.icon-square-down-right3:before {
  content: "\f3bb";
}
.icon-square-down3:before {
  content: "\f3bc";
}
.icon-square-envelope3:before {
  content: "\f3bd";
}
.icon-square-exclamation3:before {
  content: "\f3be";
}
.icon-square-left3:before {
  content: "\f3bf";
}
.icon-square-minus3:before {
  content: "\f6c6";
}
.icon-square-pen3:before {
  content: "\f3c0";
}
.icon-square-phone3:before {
  content: "\f3c1";
}
.icon-square-plus:before {
  content: "\f6c7";
}
.icon-square-quote3:before {
  content: "\f3c2";
}
.icon-square-up-left3:before {
  content: "\f3c3";
}
.icon-square-up-right3:before {
  content: "\f3c4";
}
.icon-square-up2:before {
  content: "\f3c5";
}
.icon-star-christmas3:before {
  content: "\f3c6";
}
.icon-star-half3:before {
  content: "\f3c7";
}
.icon-star-sharp3:before {
  content: "\f6c8";
}
.icon-star-shooting3:before {
  content: "\f3c8";
}
.icon-star3:before {
  content: "\f6c9";
}
.icon-stars3:before {
  content: "\f3c9";
}
.icon-stopwatch3:before {
  content: "\f3ca";
}
.icon-suitcase-medical3:before {
  content: "\f3cb";
}
.icon-suitcase-rolling3:before {
  content: "\f3cc";
}
.icon-suitcase3:before {
  content: "\f3cd";
}
.icon-tag3:before {
  content: "\f3ce";
}
.icon-tags3:before {
  content: "\f3cf";
}
.icon-tank-water3:before {
  content: "\f3d0";
}
.icon-taxi3:before {
  content: "\f3d1";
}
.icon-temperature-arrow-down3:before {
  content: "\f3d2";
}
.icon-temperature-arrow-up3:before {
  content: "\f3d3";
}
.icon-temperature-empty3:before {
  content: "\f3d4";
}
.icon-temperature-full3:before {
  content: "\f3d5";
}
.icon-temperature-half3:before {
  content: "\f3d6";
}
.icon-temperature-high3:before {
  content: "\f3d7";
}
.icon-temperature-list3:before {
  content: "\f3d8";
}
.icon-temperature-low3:before {
  content: "\f3d9";
}
.icon-temperature-quarter3:before {
  content: "\f3da";
}
.icon-temperature-snow3:before {
  content: "\f3db";
}
.icon-temperature-sun3:before {
  content: "\f3dc";
}
.icon-temperature-three-quarters3:before {
  content: "\f3dd";
}
.icon-thumbs-down3:before {
  content: "\f3de";
}
.icon-thumbs-up3:before {
  content: "\f3df";
}
.icon-ticket1:before {
  content: "\f6e4";
}
.icon-timer3:before {
  content: "\f3e0";
}
.icon-toilet3:before {
  content: "\f3e1";
}
.icon-toolbox3:before {
  content: "\f3e2";
}
.icon-tooth3:before {
  content: "\f6ca";
}
.icon-train-track3:before {
  content: "\f3e3";
}
.icon-trash-can-check3:before {
  content: "\f3e4";
}
.icon-trash-check3:before {
  content: "\f3e5";
}
.icon-trash-plus3:before {
  content: "\f3e6";
}
.icon-treasure-chest3:before {
  content: "\f3e7";
}
.icon-triangle-exclamation3:before {
  content: "\f3e8";
}
.icon-triangle-person-digging3:before {
  content: "\f3e9";
}
.icon-trophy-star3:before {
  content: "\f3ea";
}
.icon-trophy3:before {
  content: "\f3eb";
}
.icon-truck-clock3:before {
  content: "\f3ec";
}
.icon-truck-fast3:before {
  content: "\f3ed";
}
.icon-truck-field3:before {
  content: "\f3ee";
}
.icon-truck-flatbed3:before {
  content: "\f3ef";
}
.icon-truck-front3:before {
  content: "\f3f0";
}
.icon-truck-medical3:before {
  content: "\f3f1";
}
.icon-truck-moving3:before {
  content: "\f3f2";
}
.icon-truck-plow3:before {
  content: "\f3f3";
}
.icon-truck-ramp-box3:before {
  content: "\f3f4";
}
.icon-truck-ramp3:before {
  content: "\f3f5";
}
.icon-truck3:before {
  content: "\f3f6";
}
.icon-tty-answer3:before {
  content: "\f3f7";
}
.icon-turn-down-left3:before {
  content: "\f3f8";
}
.icon-turn-down-right3:before {
  content: "\f3f9";
}
.icon-turn-down3:before {
  content: "\f3fa";
}
.icon-turn-up3:before {
  content: "\f3fb";
}
.icon-umbrella-beach2:before {
  content: "\f6f7";
}
.icon-up-left3:before {
  content: "\f3fc";
}
.icon-up-right-from-square3:before {
  content: "\f3fd";
}
.icon-up-right3:before {
  content: "\f3fe";
}
.icon-up-to-line3:before {
  content: "\f3ff";
}
.icon-user-check3:before {
  content: "\f400";
}
.icon-user-clock3:before {
  content: "\f401";
}
.icon-user-doctor-message3:before {
  content: "\f402";
}
.icon-user-doctor3:before {
  content: "\f403";
}
.icon-user-group3:before {
  content: "\f404";
}
.icon-user-hair-long3:before {
  content: "\f405";
}
.icon-user-headset4:before {
  content: "\f756";
}
.icon-user-helmet-safety3:before {
  content: "\f406";
}
.icon-user-large3:before {
  content: "\f407";
}
.icon-user-pen3:before {
  content: "\f408";
}
.icon-user-plus3:before {
  content: "\f409";
}
.icon-user-robot-xmarks3:before {
  content: "\f40a";
}
.icon-user-robot3:before {
  content: "\f40b";
}
.icon-user-shield3:before {
  content: "\f40c";
}
.icon-user-tag3:before {
  content: "\f40d";
}
.icon-user-tie3:before {
  content: "\f40e";
}
.icon-user3:before {
  content: "\f40f";
}
.icon-users-between-lines3:before {
  content: "\f410";
}
.icon-users3:before {
  content: "\f411";
}
.icon-van-shuttle3:before {
  content: "\f412";
}
.icon-video3:before {
  content: "\f413";
}
.icon-viruses3:before {
  content: "\f414";
}
.icon-volume-high3:before {
  content: "\f415";
}
.icon-volume-low3:before {
  content: "\f416";
}
.icon-volume-off3:before {
  content: "\f417";
}
.icon-volume-slash3:before {
  content: "\f418";
}
.icon-volume-xmark3:before {
  content: "\f419";
}
.icon-volume3:before {
  content: "\f41a";
}
.icon-wallet3:before {
  content: "\f41b";
}
.icon-warehouse-full3:before {
  content: "\f41c";
}
.icon-warehouse3:before {
  content: "\f41d";
}
.icon-water4:before {
  content: "\f738";
}
.icon-wheelchair-move3:before {
  content: "\f41e";
}
.icon-wind-warning3:before {
  content: "\f41f";
}
.icon-wind3:before {
  content: "\f420";
}
.icon-window-flip3:before {
  content: "\f421";
}
.icon-window-frame-open3:before {
  content: "\f422";
}
.icon-window-frame3:before {
  content: "\f423";
}
.icon-window-minimize3:before {
  content: "\f6cb";
}
.icon-window3:before {
  content: "\f6fc";
}
.icon-wrench-simple2:before {
  content: "\f6fd";
}
.icon-wrench3:before {
  content: "\f6fe";
}
.icon-x2:before {
  content: "\f6ff";
}
.icon-xmark-large2:before {
  content: "\f700";
}
.icon-xmark2:before {
  content: "\f701";
}